import React from 'react';
import { Form } from 'semantic-ui-react';

import ProductComponentTypeFilter from '../components/type-filters';

export default class ProductTypePickerAgGrid extends React.Component {
    state = {
        type: '',
    };

    componentWillMount () {
        this.setType(this.props.value);
    };

    getValue() {
        return this.state.type.toUpperCase();
    };

    isPopup() {
        return true;
    };

    setType = (type) => {
        this.setState({ type });
    };

    handleChange = (type) => {
        this.setState({ type }, () => this.props.api.stopEditing());
    };

    render() {
        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <Form.Field>
                        <label>Select Product Type</label>
                        <ProductComponentTypeFilter 
                            selection
                            hideAll={true}
                            onSelect={this.handleChange}
                            currentFilter={{ product_type: this.state.type }}
                        />
                    </Form.Field>
                </Form>
            </div>
        );
    }
}