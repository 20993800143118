import { keyBy, isEmpty } from 'lodash';
import format from 'date-fns/format';

export const allRecentGridColumns = keyBy([
    { 
        field: 'time', 
        headerName: 'Time', 
        valueGetter: ({ data }) => {
            return format(data.searches[0].create_date, "HH:mm, DD MMM");
        } ,
    },
    { 
        field: 'name', 
        headerName: 'Name',
        sortable: true,
        filter: 'agTextColumnFilter',
        valueGetter: '"#"+ data.id +" "+ data.name +" ("+ data.company +")"' 
    },
    { 
        field: 'search', 
        headerName: 'Search',
        valueGetter: 'data.searches[0].search_name +" ("+ data.searches[0].search_type +")"'
    },
    { 
        field: 'geo', 
        headerName: 'Geo',
        valueGetter: ({data}) => {
            if (!data || isEmpty(data.searches))
                return '';
            
            const lastSearch = data.searches[0];
            if (lastSearch.search_geo) {
                return `${lastSearch.search_geo} (${lastSearch.search_geo_type})`;
            } else {
                return lastSearch.search_geo_type;
            }
        }
    },
    { 
        field: 'loc', 
        headerName: 'Loc',
        valueGetter: ({data}) => {
            if (!data || isEmpty(data.searches))
                return '';
            
            const lastSearch = data.searches[0];
            if (lastSearch.search_loc) {
                return `${lastSearch.search_loc} (${lastSearch.search_loc_type})`;
            } else {
                return lastSearch.search_loc_type;
            }
        }
    },
    { 
        field: 'agent', 
        headerName: 'Agent',
        cellRenderer: "browserGridCell",
        valueGetter: 'data.searches[0].browser.string',
        tooltipValueGetter: ({ data }) => {
            return data.searches[0].browser["string"];
        }
    },
    {
        field: 'searches',
        suppressMenu: true,
        filter: false,
        headerName: 'Searches',
        valueGetter: 'data.searches.length',
    },
], 'field');
