import React from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';

import { loadRecentGridData } from './actions';
import { selectClientFromGrid } from '../client-grid/actions';

import BrowserGridCell from '../components/browser-grid-cell';
import RecentGridControl from './grid-control';

class UserContainerRecentGrid extends React.Component {
    grid = null;
    refresher = null;

    componentWillMount() {
        this.props.loadRecentGridData(this.props.minutes);
        this.refresher = setInterval(() => {
            this.props.loadRecentGridData(this.props.minutes);
        }, 30*1000);
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
        clearInterval(this.refresher);
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        if (this.grid) {
            this.grid.api.sizeColumnsToFit();
        }
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    selectClient = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectClientFromGrid(selectedRows[0]);
        } else {
            this.props.selectClientFromGrid(null);
        }
    };

    componentDidUpdate (prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.grid.api.sizeColumnsToFit();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid && this.grid.api.showLoadingOverlay();
        }
    };

    render () {
        return (
            <div>
                <RecentGridControl />

                <div className="ag-theme-balham">
                    <AgGridReact
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowData={this.props.clients}
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        onSelectionChanged={this.selectClient}
                        frameworkComponents={{
                            browserGridCell: BrowserGridCell 
                        }}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ recentGrid }) => ({
    minutes: recentGrid.minutes,
    clients: recentGrid.clients,
    isLoading: recentGrid.isLoading,
    columnDefs: recentGrid.columnDefs,
    shownColumns: recentGrid.shownColumns,
    hiddenColumns: recentGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadRecentGridData,
    selectClientFromGrid: (client) => dispatch(selectClientFromGrid(client)),
}, dispatch);

export default connect(
    mapStateToProps, 
    mapDispatchToProps,
    null,
    {withRef: true}
)(UserContainerRecentGrid);