import {
    BOT_DATA, BOT_LOADING, BOT_ERROR,
    BOT_DETAIL_SHOW, BOT_DETAIL_HIDE, 
    BOT_LOG_LOADING, BOT_LOG_CONTENT, BOT_LOG_CONTENT_ERROR,
    BOT_LOG_TOGGLE_VIEWER,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { getLogFilePath } from '../services/helpers';
import { makePrivateApiCall } from '../../common/api';

export const loadBots = () => dispatch => {
    dispatch({
        type: BOT_LOADING
    });

    makePrivateApiCall({
        url: '/bot',
        method: 'get',
    }).then(res => {
        if (res.data) {
            const { rows: bots } = res.data;

            dispatch({
                type: BOT_DATA,
                payload: bots
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: BOT_ERROR,
            payload: err.message
        });
    });
};

export const showBotDetails = (bot) => dispatch => {
    dispatch({
        type: BOT_DETAIL_SHOW,
        payload: bot,
    });
};

export const hideBotDetails = (bot) => dispatch => {
    dispatch({
        type: BOT_DETAIL_HIDE,
        payload: bot,
    });
};

export const loadLogPage = (bot, filename, page=0) => dispatch => {
    const fileKey = getLogFilePath(bot, filename);
    
    dispatch({
        type: BOT_LOG_LOADING,
        payload: fileKey
    });

    makePrivateApiCall({
        url: `/bot/content`, method: 'get', 
        params: { page, name: bot.name, filename }
    }).then(res => {
        if (res.data) {            
            dispatch({
                type: BOT_LOG_CONTENT,
                payload: {...res.data, fileKey}
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: BOT_LOG_CONTENT_ERROR,
            payload: { message: err.message, fileKey }
        });
    });
};

export const toggleLogViewer = (bot, filename) => dispatch => {
    dispatch({
        type: BOT_LOG_TOGGLE_VIEWER,
        payload: getLogFilePath(bot, filename),
    });
};

export const downloadFile = (bot, filename) => dispatch => {
    makePrivateApiCall({
        method: 'get',
        url: '/bot/download',
        params: {name: bot.name, filename}
    }).then(res => {
        window.open(res.data.url, '_blank');
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: BOT_ERROR,
            payload: `Download Error: ${err.message}`
        });
    });
};