import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Menu, Message } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import ProductContainerEditor from '../containers/editor';

import { loadProductForEdit, resetEditingProduct } from '../actions';

const ListProductButton = withRouter(({ history }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="glass martini"
                name="All Products"
                onClick={() => history.goBack()}
            />
        </React.Fragment>
    );
});

class ProductEditPage extends React.Component {
    componentWillMount() {
        const { computedMatch } = this.props;
        this.props.loadProductForEdit(computedMatch.params.productId);
    };

    componentWillUnmount() {
        this.props.resetEditingProduct();
    };
    
    render () {
        const { error, computedMatch, } = this.props;
        return (
            <Grid>
                <TopNav
                    headerIcon='glass martini'
                    subheader='Update existing product details'
                    extraButtons={() => <ListProductButton />}
                    headerContent={`Edit Product #${computedMatch.params.productId}`}
                />

                <Grid.Column mobile={16}>
                    {error && (
                        <Message
                            error
                            content={error}
                            header={`Error loading product #${computedMatch.params.productId}`}
                        />
                    )}
                    <ProductContainerEditor />
                </Grid.Column>
            </Grid>
        );
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductForEdit, resetEditingProduct,
}, dispatch);

const mapStateToProps = ({ productEditor }) => ({
    error: productEditor.productError
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditPage);