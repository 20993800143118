import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

import CheckContainerList from '../containers/list';
import TopNav from '../../common/containers/top-nav';

const CreateCheckButton = withRouter(({ history }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="play"
                name="Run All" 
            />
            <Menu.Item
                icon="plus"
                color="green"
                name="New Check" 
                onClick={() => history.push('/check/create')}
            />
        </React.Fragment>
    );
});

const CheckHomePage = () => {
    return (
        <Grid>
            <TopNav 
                headerContent='Checks'
                headerIcon='check circle outline'
                subheader={`Periodic checks to make sure everything's in tip top shape.`}
                extraButtons={() => <CreateCheckButton />}/>

            <Grid.Row>
                <Grid.Column mobile={16} >
                    <CheckContainerList />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CheckHomePage;