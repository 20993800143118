import { get } from 'lodash';

import {
    CLIENT_EDITOR_INPUT, 
    CLIENT_EDITOR_ERROR,
    CLIENT_EDITOR_SAVING, 
    CLIENT_EDITOR_CONFIRMING, 
    CLIENT_EDITOR_SAVED,
    CLIENT_EDITOR_CLIENT_LOADING, 
    CLIENT_EDITOR_CLIENT_DATA, 
    CLIENT_EDITOR_CLIENT_ERROR, 
    CLIENT_EDITOR_CLIENT_RESET,
} from '../constants';
import { COMPANY_LIST_SELECT_OPTION } from '../../company/constants';

import { makePrivateApiCall } from '../../common/api';
import { selectClientFromGrid } from '../client-grid/actions';

export const requestSaveClient = (client={}) => dispatch => {
    return dispatch({
        type: CLIENT_EDITOR_CONFIRMING,
        payload: client,
    });
};

export const saveClient = (client={}, onComplete) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_EDITOR_SAVING,
        payload: true,
    });

    const request = {
        url: `/user/client`, params: { env: moduleEnv.user },
        method: 'post', data: client,
    };

    if (client.id) {
        request.url = `/user/client/${client.id}`;
        request.method = 'put';
    }

    makePrivateApiCall(request).then((res) => {
        if (res.data) {
            dispatch({ type: CLIENT_EDITOR_SAVED });

            if (client.id) {
                dispatch(selectClientFromGrid(res.data.client));
            }

            onComplete(res.data.client);
        }
    }).catch(err => {
        const message = get(err, 'response.data.message', null) || err.message;
        onComplete(null);
        dispatch(setClientEditorConfirming());
        dispatch(setClientEditorError([message]));
    });
};

export const setClientEditorInput = (inputs={}) => dispatch => {
    return dispatch({
        type: CLIENT_EDITOR_INPUT,
        payload: inputs
    });
};

export const setClientEditorConfirming = (confirming=false) => dispatch => {
    return dispatch({
        type: CLIENT_EDITOR_CONFIRMING,
        payload: !!confirming
    });
};

export const setClientEditorError = (error) => dispatch => {
    return dispatch({
        type: CLIENT_EDITOR_ERROR,
        payload: error
    });
};

export const loadClientForEdit = (clientId) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_EDITOR_CLIENT_LOADING,
        payload: true,
    });

    makePrivateApiCall({
        method: 'get', params: { env: moduleEnv.user },
        url: `/user/client/${clientId}`,
    }).then((res) => {
        if (res.data) {
            dispatch({ 
                type: CLIENT_EDITOR_CLIENT_DATA,
                payload: res.data,
            });
            dispatch({
                type: COMPANY_LIST_SELECT_OPTION,
                payload: res.data.company_id,
            });
        }
    }).catch(err => {
        dispatch({
            type: CLIENT_EDITOR_CLIENT_ERROR,
            payload: err.message,
        });
    });
};

export const resetEditingClient = () => dispatch => dispatch({
    type: CLIENT_EDITOR_CLIENT_RESET
});