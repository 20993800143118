import {
    REPORT_LIST_DATA,
    REPORT_LIST_ERROR,
    REPORT_LIST_LOADING,
    REPORT_LIST_DETAIL_HIDE,
    REPORT_LIST_DETAIL_SHOW,
    REPORT_LIST_TOGGLE_SUB_COUNT_FILTER
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    reports: [],
    rawReports: [],
    isLoading: false,
    subCountFilter: true,
    detailsExposedReports: [],
};

const loadData = (state, reports) => ({
    ...state,
    error: null,
    isLoading: false,
    rawReports: reports,
    reports: filterReports(state, reports),
});

const setError = (state, error) => ({ ...state, isLoading: false, error });
const setLoading = state => ({ ...state, isLoading: true });
const showReport = (state, report) => ({
    ...state,
    detailsExposedReports: [...state.detailsExposedReports, report.id]
});
const hideReport = (state, report) => ({
    ...state,
    detailsExposedReports: state.detailsExposedReports.filter(
        id => report.id !== id
    )
});

const filterReports = (state, reports) => {
    if (state.subCountFilter === null)
        return reports;

    return reports.filter(r => {
        if (state.subCountFilter)
            return r.subscribers.length > 0;
            
        return r.subscribers.length < 1;
    });
};

const toggleSubCountFilter = (state) => {
    const newState = {...state};

    if (newState.subCountFilter === true) {
        newState.subCountFilter = false;
    } else if (newState.subCountFilter === false) {
        newState.subCountFilter = null;
    } else {
        newState.subCountFilter = true;
    }

    newState.reports = filterReports(newState, newState.rawReports);

    return newState;
};

const reducers = {
    [REPORT_LIST_TOGGLE_SUB_COUNT_FILTER]: toggleSubCountFilter,
    [REPORT_LIST_DETAIL_SHOW]: showReport,
    [REPORT_LIST_DETAIL_HIDE]: hideReport,
    [REPORT_LIST_LOADING]: setLoading,
    [REPORT_LIST_ERROR]: setError,
    [REPORT_LIST_DATA]: loadData
};

const reducer = generateReducer(reducers, initialState);

export default reducer;
