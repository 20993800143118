import React from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';

import { 
    changeSearchGridState, selectSearchFromGrid,
    loadSearchGridPage, changeSearchGridPage, 
} from './actions';

import BrowserGridCell from '../components/browser-grid-cell';
import UserContainerSearchGridControl from './grid-control';

class UserContainerSearchGrid extends React.Component {
    grid = null;
    gridDataSource = {
        getRows: (params) => {
            this.props.loadSearchGridPage(this.props.client, this.props, params);
        },
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener('sortChanged', () => {
            this.props.changeGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeGridState({ filterModel: this.grid.api.getFilterModel() });
        });
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage)
                return;

            this.props.changeSearchGridPage(page);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    selectSearch = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectSearchFromGrid(selectedRows[0]);
        } else {
            this.props.selectSearchFromGrid(null);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.grid.api.sizeColumnsToFit();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid && this.grid.api.showLoadingOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid && this.grid.api.paginationSetPageSize(this.props.pageSize);
        }
    };

    render() {
        return (
            <div>
                <UserContainerSearchGridControl />
                <div className="ag-theme-balham">
                    <AgGridReact
                        pagination
                        floatingFilter={true}
                        rowSelection="single"
                        rowModelType="infinite"
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        defaultColDef={{ resizable: true }}
                        onPaginationChanged={this.changePage}
                        onSelectionChanged={this.selectSearch}
                        frameworkComponents={{
                            browserGridCell: BrowserGridCell
                        }}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ searchGrid }) => ({
    pageSize: searchGrid.pageSize,
    isLoading: searchGrid.isLoading,
    sortModel: searchGrid.sortModel,
    columnDefs: searchGrid.columnDefs,
    filterModel: searchGrid.filterModel,
    currentPage: searchGrid.currentPage,
    shownColumns: searchGrid.shownColumns,
    hiddenColumns: searchGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadSearchGridPage,
    changeGridState: (state) => dispatch(changeSearchGridState(state)),
    changeSearchGridPage: (page) => dispatch(changeSearchGridPage(page)),
    selectSearchFromGrid: (client) => dispatch(selectSearchFromGrid(client)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerSearchGrid);