import { keyBy } from 'lodash';
import format from 'date-fns/format';

export const searchGridColumns = keyBy([
    {
        headerName: 'Time',
        suppressMenu: true,
        filter: 'agDateColumnFilter',
        sortable: true,
        field: 'create_date',
        valueFormatter: ({ value }) => {
            return value ? format(value, "HH:mm, DD MMM YYYY") : "";
        }
    },
    {
        field: 'search_name',
        headerName: 'Name',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'search_type',
        headerName: 'Prod Type',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'search_geo',
        headerName: 'Geo',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'geo_type',
        headerName: 'Geo Type',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'search_loc',
        headerName: 'Loc',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'search_loc_type',
        headerName: 'Type',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'coverage',
        headerName: 'Coverage',
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'sales_period_type',
        headerName: 'Date Range',
        suppressMenu: true, 
        sortable: true,
        autoHeight: true,
        filter: 'agTextColumnFilter',
        valueFormatter: ({ data, value }) => {
            if (!data)
                return "";

            let type = value;

            if (value === 'Custom') {
                type += ` \n(${format(data.sales_period_start, "DD MMM, YY")} - ${format(data.sales_period_end, "DD MMM, YY")})`;
            }
            
            return type;
        }
    },
    {
        field: 'agent',
        suppressMenu: true,
        headerName: 'Agent',
        filter: false,
        sortable: false,
        cellRenderer: "browserGridCell",
        valueGetter: 'data.browser.string',
        tooltipValueGetter: ({ data }) => {
            return (data && data.browser) ? data.browser["string"] : "";
        }
    },
], 'field');