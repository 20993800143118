import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {  Segment, Grid, Message, Confirm, } from 'semantic-ui-react';

import CheckComponentSingle from '../components/single';
import { loadChecks, deleteCheck, showCheckDetails, hideCheckDetails, setCheckEditorData } from '../actions';

class CheckContainerList extends React.Component {
    state = {
        showDeleteConfirm: false,
        deletingCheck: null,
    };

    componentWillMount() {
        this.props.loadChecks();
    };

    onDeleteConfirm = () => {
        this.props.deleteCheck(this.state.deletingCheck);
        this.setState({ showDeleteConfirm: false, deletingCheck: null });
    };

    render() {
        const { checks, isLoading, detailsExposedChecks, history } = this.props;
        const { showDeleteConfirm, deletingCheck } = this.state;

        return (
            <React.Fragment>
                { !!deletingCheck && (
                    <Confirm
                        size="tiny"
                        cancelButton="Nevermind"
                        confirmButton="Remove It"
                        open={showDeleteConfirm}
                        onConfirm={this.onDeleteConfirm}
                        header={`Sure you want to remove Check #${deletingCheck.check_id}?`}
                        onCancel={() => this.setState({ showDeleteConfirm: false, deletingCheck: null })}
                        content={`${deletingCheck.description} - will be permanently removed. Are you absolutely sure?`}
                    />
                )}

                {isLoading ? (
                    <Segment loading style={{minHeight: "200px"}} />
                ) : (checks.length > 0 ? (
                    <Grid>
                        { checks.map(check => {
                            const showDetails = detailsExposedChecks.find(chk => chk.check_id === check.check_id);
                            return (
                                <Grid.Row key={check.check_id}>
                                    <Grid.Column
                                        mobile={16}
                                        computer={showDetails ? 16 : 10}
                                    >
                                        <CheckComponentSingle
                                            check={check}
                                            showDetails={showDetails}
                                            onHideCheckDetails={this.props.hideCheckDetails}
                                            onShowCheckDetails={this.props.showCheckDetails}
                                            onRunCheck={(check) => {
                                                this.props.setCheckEditorData(check);
                                                history.push(`/check${check.check_id}/edit`);
                                            }}
                                            onEditCheck={(check) => {
                                                this.props.setCheckEditorData(check);
                                                history.push(`/check/${check.check_id}/edit`);
                                            }}
                                            onDeleteCheck={(deletingCheck) => this.setState({deletingCheck, showDeleteConfirm: true})}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        }) }
                    </Grid>
                ) : (
                    <Message error content="Sorry no checks found"/>
                ))}
            </React.Fragment>
        );
    };
};

const mapStateToProps = ({ checkList }) => ({
    error: checkList.error,
    checks: checkList.checks,
    isLoading: checkList.isLoading,
    detailsExposedChecks: checkList.detailsExposedChecks,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadChecks, deleteCheck, showCheckDetails, hideCheckDetails,
    setCheckEditorData,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckContainerList));