export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const getLogFilePath = (bot={}, filename) => {
    let filePath = bot.dir;

    if (bot.hasNestedLogs) {
        filePath += filename;
    } else {
        filePath += bot.filename;
    }

    return filePath;
}