import {
    RECENT_GRID_DATA, RECENT_GRID_LOADING, RECENT_GRID_ERROR,
    RECENT_GRID_COLUMN_TOGGLE, RECENT_GRID_MINUTES,
    RECENT_GRID_CONTROL_TOGGLE,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';

export const loadRecentGridData = (minutes) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    
    dispatch({
        type: RECENT_GRID_LOADING
    });

    makePrivateApiCall({
        url: '/user/client/recent',
        params: { minutes, env: moduleEnv.user },
        method: 'get', 
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: RECENT_GRID_DATA,
                payload: { clients: res.data }
            });
        }
    }).catch(err => {
        dispatch({
            type: RECENT_GRID_ERROR,
            payload: err.message
        });
    });
};

export const hideRecentGridColumn = (columns) => dispatch => {
    dispatch({
        type: RECENT_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const toggleRecentGridControl = () => dispatch => dispatch({
    type: RECENT_GRID_CONTROL_TOGGLE,
});

export const setRecentGridMinutes = (minutes) => dispatch => {
    dispatch({
        type: RECENT_GRID_MINUTES,
        payload: minutes
    });

    dispatch(loadRecentGridData(minutes));
};