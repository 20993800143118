import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { SemanticToastContainer } from 'react-semantic-toasts';

import './styles/app.scss';

import { store, history, persistor } from "./store";

import AuthMainPage from './auth/pages/main';
import AuthLogoutPage from './auth/pages/logout';

import HomePage from './common/pages/home';
import SettingPage from './common/pages/setting';
import NotFoundPage from './common/pages/not-found';

import PrivateRoute from './common/containers/private-route';

import BotHomePage from './bot/pages/home';

import ReportHomePage from './report/pages/home';
import ReportReviewPage from './report/pages/review';

import UserHomePage from './user/pages/home';
import UserEditPage from './user/pages/edit';
import UserCreatePage from './user/pages/create';

import CheckHomePage from './check/pages/home';
import CheckEditPage from './check/pages/edit';
import CheckCreatePage from './check/pages/create';

import ProductHomePage from './product/pages/home';
import ProductEditPage from './product/pages/edit';

import CompanyEditPage from './company/pages/edit';
import CompanyUsagePage from './company/pages/usage';
import CompanyCreatePage from './company/pages/create';
import CompanyRenewalsPage from './company/pages/renewals';
import CompanyAgreementsPage from './company/pages/agreements';

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <div className="app-container">
                            <Switch>
                                <PrivateRoute
                                    exact
                                    path="/"
                                    component={HomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/dashboard"
                                    component={HomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/setting"
                                    component={SettingPage}
                                />
                                
                                <PrivateRoute
                                    exact
                                    path="/report/review"
                                    component={ReportReviewPage}
                                />
                                
                                <PrivateRoute
                                    exact
                                    path="/report"
                                    component={ReportHomePage}
                                />

                                <PrivateRoute
                                    exact
                                    path="/product"
                                    component={ProductHomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/product/:productId"
                                    component={ProductHomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/product/:productId/edit"
                                    component={ProductEditPage}
                                />

                                <PrivateRoute
                                    exact
                                    path="/user"
                                    component={UserHomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/user/create"
                                    component={UserCreatePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/user/:userId/edit"
                                    component={UserEditPage}
                                />

                                <PrivateRoute
                                    exact
                                    path="/company/usage"
                                    component={CompanyUsagePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/company/create"
                                    component={CompanyCreatePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/company/renewals"
                                    component={CompanyRenewalsPage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/company/agreements"
                                    component={CompanyAgreementsPage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/company/:companyId/edit"
                                    component={CompanyEditPage}
                                />

                                <PrivateRoute
                                    exact
                                    path="/check"
                                    component={CheckHomePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/check/create"
                                    component={CheckCreatePage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/check/:checkId/edit"
                                    component={CheckEditPage}
                                />

                                <PrivateRoute
                                    exact
                                    path="/bot"
                                    component={BotHomePage}
                                />

                                <Route
                                    exact
                                    path="/logout"
                                    component={AuthLogoutPage}
                                />
                                <Route
                                    exact
                                    path="/auth/:section"
                                    component={AuthMainPage}
                                />
                                <Route component={NotFoundPage} />
                            </Switch>

                            <SemanticToastContainer position="top-right" />
                        </div>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    };
};

export default App;
