import { toast } from 'react-semantic-toasts';

import {
    PRODUCT_NORMAL_CREATING, PRODUCT_NORMAL_CREATED, PRODUCT_NORMAL_CREATE_ERROR,
    PRODUCT_NORMAL_DATA, PRODUCT_NORMAL_LOADING, PRODUCT_NORMAL_ERROR, 
    PRODUCT_NORMAL_DELETED, PRODUCT_NORMAL_MINIMIZE, PRODUCT_NORMAL_SORT,
} from '../constants';

import { makePrivateApiCall } from '../../common/api';
import { apiCallError } from '../../common/actions';
import { authExpire } from '../../auth/actions';

export const loadNormalsForProduct = (productId) => dispatch => {
    dispatch({
        type: PRODUCT_NORMAL_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/normal`,
        method: 'get',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_NORMAL_DATA,
                payload: res.data
            });
        }
    }).catch(err => {

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }
        
        dispatch({
            type: PRODUCT_NORMAL_ERROR,
            payload: err.message
        });
    });
};

export const deleteNormalForProduct = (productId, normal) => dispatch => {
    dispatch({
        type: PRODUCT_NORMAL_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/normal/${normal}`,
        method: 'delete',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_NORMAL_DELETED,
                payload: normal
            });

            toast({
                time: 3000,
                type: 'success',
                icon: 'trash alternate',
                title: 'NORMAL removed',
                description: `Successfully removed normal ${normal} from product`,
            });
        }
    }).catch(err => {

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_NORMAL_ERROR,
            payload: err.message
        });
    });
};

export const minimizeNormalList = (minimize=true) => dispatch => {
    return dispatch({
        type: PRODUCT_NORMAL_MINIMIZE,
        payload: minimize
    });
};

export const createNormalForProduct = (productId, normalized) => dispatch => {
    dispatch({
        type: PRODUCT_NORMAL_CREATING
    });

    makePrivateApiCall({
        url: `/product/${productId}/normal`,
        method: 'post', 
        data: {
            productId, normalized
        },
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_NORMAL_CREATED,
                payload: res.data
            });
        }
    }).catch(err => {

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        if (err.response && err.response.status === 422) {
            return dispatch({
                type: PRODUCT_NORMAL_CREATE_ERROR,
                payload: err.response.data
            });
        }

        dispatch(apiCallError(err));
    });
};

export const sortNormals = (column, order) => dispatch => dispatch({
    type: PRODUCT_NORMAL_SORT,
    payload: {column, order}
});