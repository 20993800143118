import React from "react";
import { connect } from "react-redux";
import { Grid, Menu } from "semantic-ui-react";
import { bindActionCreators } from "redux";

import { loadReportStats } from '../actions';

import TopNav from "../../common/containers/top-nav";
import ReportContainerStats from "../containers/stats";
import ModuleEnvPicker from "../../common/containers/module-env-picker";

const ReportReviewPage = ({ history, loadReportStats }) => {
    return (
        <Grid>
            <TopNav
                headerIcon="file excel"
                headerContent="Report Review"
                subheader={`Quick overview of email reports`}
                extraButtons={() => (
                    <>
                        <Menu.Item
                            icon="list"
                            name="All Reports"
                            onClick={() => history.push('/report')}
                        />
                        <ModuleEnvPicker
                            moduleName="report"
                            onChange={() => {
                                loadReportStats();
                            }}
                        />
                    </>
                )}
            />

            <Grid.Row>
                <Grid.Column mobile={16}>
                    <ReportContainerStats />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadReportStats }, dispatch);

export default connect(null, mapDispatchToProps)(ReportReviewPage);
