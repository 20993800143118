import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';

import { changeModuleEnv } from '../actions';

const ModuleEnvPicker = ({
    changeModuleEnv, onChange,
    moduleName, menuProps={},
    ...otherProps
}) => {
    const currentEnv = otherProps[moduleName],
        otherEnv = currentEnv === 'production' ? 'development' : 'production';

    return (
        <Menu.Item
            color={currentEnv === 'production' ? 'purple' : 'blue'}
            onClick={() => {
                changeModuleEnv(moduleName, otherEnv);
                if (!!onChange)
                    onChange();
            }}
            icon="code branch"
            name={currentEnv} 
            active
            {...menuProps}
        />
    );
};

ModuleEnvPicker.propTypes = {
    moduleName: PropTypes.oneOf(['company', 'user', 'report']).isRequired,
    menuProps: PropTypes.object,
    onChange: PropTypes.func,
};

const mapStateToProps = ({ moduleEnv }) => moduleEnv;

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModuleEnv
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEnvPicker);