import { keyBy } from 'lodash';
import format from 'date-fns/format';

import { dateFilterComparator } from '../../common/helpers';
import { UserSubscriptionStatuses } from '../constants';
import { CompanyServiceLevels } from '../../company/constants';

const clientGridColumns = keyBy([
    {
        field: 'id',
        headerName: 'ID',
        suppressMenu: true,
        sortable: true,
        filter: 'agNumberColumnFilter',
    },
    { 
        field: 'name', 
        headerName: 'Name', 
        suppressMenu: true, 
        sortable: true,
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agTextCellEditor',
    },
    { 
        field: 'email', 
        headerName: 'Email',
        cellRenderer: 'cellWithCopyButton',
        cellStyle: { "white-space": "normal" },
        suppressMenu: true, 
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agTextCellEditor',
    },
    { 
        field: 'company', 
        sortable: true,
        headerName: 'Company', 
        filter: 'agTextColumnFilter',
        suppressMenu: true, 
    },
    { 
        headerName: 'Pwr',
        suppressMenu: true,
        field: 'power_user',
        filter: false,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: [0, 1] },
        valueGetter: ({ data }) => {
            return data && data.power_user > 0 ? 'Yes' : 'No';
        }
    },
    {
        headerName: 'Services',
        field: 'service_product_type',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        width: 200,
        cellRenderer: 'companyServicesGridCell',
        cellEditor: 'agGridServiceTypePicker',
        editable: true,
    },
    {
        headerName: 'Premises',
        field: 'service_premises',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        width: 160,
        cellRenderer: 'companyServicesGridCell',
        cellEditor: 'agGridPremisePicker',
        editable: true,
    },
    { 
        filter: 'agTextColumnFilter',
        field: 'service_level', 
        headerName: 'Level', 
        suppressMenu: true,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: Object.values(CompanyServiceLevels) },
    },
    { 
        filter: 'agTextColumnFilter',
        field: 'service_status', 
        headerName: 'Status', 
        suppressMenu: true,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: Object.values(UserSubscriptionStatuses) },
    },
    { 
        field: 'service_start', 
        headerName: 'Start',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueGetter: ({ data }) => {
            return data && data.service_start ? format(data.service_start, "MM/DD/YYYY") : null;
        },
        filterParams: { comparator: dateFilterComparator },
        editable: true,
        cellEditor: 'agGridDatePicker',
    },
], 'field');

export default clientGridColumns;