import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'formik-semantic-ui';
import { Icon, Segment, Header, Grid, Message } from 'semantic-ui-react';

import { loadClientSettings, changeClientSetting } from './actions';
import { selectClientFromGrid } from '../client-grid/actions';
import SettingSalesPeriod from './components/sales-period';
import UserComponentSetting from './components/common';
import SettingGeo from './components/geo';

class UserSettingPanel extends React.Component {
    componentWillMount () {
        const { client } = this.props;
        
        if (!client)
            return;
        
        this.props.loadClientSettings(client.id);
    };

    render () {
        const { settings, client, env, isLoading, error, unselect, changeClientSetting } = this.props;

        if (!client)
            return null;

        return (
            <Segment color="red">
                <Grid>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name='settings' />
                            <Header.Content>
                                Settings
                                <Header.Subheader>
                                    Change settings below to <b>update</b> it in the database.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign="right">
                        {isLoading && (
                            <Icon name="spinner" loading />
                        )}

                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                unselect();
                            }}
                        >
                            <Icon 
                                name="delete"
                                floated="right"
                            />
                        </a>
                    </Grid.Column>
                </Grid>
                
                {!!error && (
                    <Message
                        error
                        content={error}
                        icon="warning sign"
                        header="Settings seem to have broken!" 
                    />
                )}

                <Form loading={isLoading}>
                    <Grid>
                        <Grid.Row>
                            {settings.map((setting) => (
                                <UserComponentSetting
                                    onChange={changeClientSetting}
                                    key={setting.name}
                                    setting={setting}
                                    user={client}
                                />
                            ))}

                            <Grid.Column width={6}>
                                <SettingGeo 
                                    env={env}
                                    user={client}
                                    settings={settings} 
                                    onChange={(items) => items.forEach(({id, value}) => changeClientSetting(id, value))} 
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <SettingSalesPeriod settings={settings} onChange={changeClientSetting} />

            </Segment>
        );
    };
}

const mapStateToProps = ({ clientSetting, moduleEnv }) => ({
    settingsByName: clientSetting.settingsByName,
    isLoading: clientSetting.isLoading,
    settings: clientSetting.settings,
    error: clientSetting.error,

    env: moduleEnv.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    unselect: () => dispatch(selectClientFromGrid(null)),
    loadClientSettings, changeClientSetting,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingPanel);