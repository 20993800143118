import React from 'react';
import { Form } from 'semantic-ui-react';

import ProductCategoryPicker from './container';

export default class ProductCategoryPickerAgGrid extends React.Component {
    state = {
        value: '',
    };

    componentWillMount () {
        this.setValue(this.props.value);
    };

    getValue() {
        return this.state.value;
    };

    isPopup() {
        return true;
    };

    setValue = (value) => {
        this.setState({ value });
    };

    handleChange = (value) => {
        this.setState({ value }, () => this.props.api.stopEditing());
    };

    render() {
        const { colId } = this.props.column;
        const columnName = colId.replace('product_', '');

        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <ProductCategoryPicker 
                        only={columnName}
                        dropdownProps={{ width: 16 }}
                        onChange={(selection) => this.handleChange(selection[columnName])}
                        product={{ [colId]: this.state.value, product_type: this.props.data.product_type }}
                    />
                </Form>
            </div>
        );
    }
}