import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button, Message, Confirm } from 'semantic-ui-react';

import { createNormalForProduct } from './actions';

class ProductContainerNormalCreator extends React.Component {
    state = {
        normalized: '',
        showConfirm: false,
    };

    setConfirm = (showConfirm=true) => this.setState({ showConfirm });

    onConfirm = () => {
        this.props.createNormalForProduct(this.props.product.product_id, this.state.normalized);
        this.setConfirm(false);
    };

    render() {
        const { normalized, showConfirm } = this.state;
        const { isCreating, createError, product } = this.props;

        return (
            <div>
                <Confirm 
                    size="tiny"
                    open={showConfirm} 
                    confirmButton="Add It"
                    cancelButton="Nevermind"
                    onConfirm={this.onConfirm} 
                    onCancel={() => this.setConfirm(false)}
                    content={`Sure you want to add ${normalized} to ${product.product}?`} 
                />

                <Input 
                    fluid 
                    action
                    type='text' 
                    placeholder='Normalized Name...' 
                >
                    <input 
                        value={normalized}
                        onChange={(e) => this.setState({ normalized: e.target.value })}
                    />
                    <Button
                        icon="check"
                        color="green"
                        loading={ isCreating } 
                        onClick={() => this.setConfirm(true)}
                        disabled={ isCreating || normalized.length < 1 }
                    >
                    </Button>
                </Input>

                {createError && (<Message
                    header="Something doesn't look right!"
                    content={createError}
                    error
                />)}
            </div>
        );
    }
};

ProductContainerNormalCreator.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productNormal }) => ({
    isCreating: productNormal.isCreating,
    createError: productNormal.createError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createNormalForProduct
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerNormalCreator);