import { toast } from 'react-semantic-toasts';

import {
    PRODUCT_UPDATE_DATA, PRODUCT_UPDATE_LOADING, PRODUCT_UPDATE_ERROR, PRODUCT_UPDATE_DELETED, PRODUCT_UPDATE_MINIMIZE
} from '../constants';

import { makePrivateApiCall } from '../../common/api';

export const loadUpdatesForProduct = (productId) => dispatch => {
    dispatch({
        type: PRODUCT_UPDATE_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/update`,
        method: 'get',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPDATE_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        dispatch({
            type: PRODUCT_UPDATE_ERROR,
            payload: err.message
        });
    });
};

export const deleteUpdateForProduct = (productId, update) => dispatch => {
    dispatch({
        type: PRODUCT_UPDATE_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/update/${update}`,
        method: 'delete',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPDATE_DELETED,
                payload: update
            });

            toast({
                time: 3000,
                type: 'success',
                icon: 'trash alternate',
                title: 'UPDATE removed',
                description: `Successfully removed update ${update} from product`,
            });
        }
    }).catch(err => {
        dispatch({
            type: PRODUCT_UPDATE_ERROR,
            payload: err.message
        });
    });
};

export const minimizeUpdateList = (minimize=true) => dispatch => {
    return dispatch({
        type: PRODUCT_UPDATE_MINIMIZE,
        payload: minimize
    });
};