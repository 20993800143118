import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-semantic-toasts';
import { Label, Icon } from 'semantic-ui-react';

import { selectClientFromGrid } from './actions';

const SelectedClientBadge = ({ client, unselect, onUnselect }) => {
    if (!client)
        return null;

    return (
        <Label color="violet">
            <Clipboard
                component="span"
                data-clipboard-text={client.id}
                onSuccess={data => {
                    toast({
                        icon: 'copy outline',
                        title: `Copied to clipboard`,
                        description: `User Id ${data.text} has been copied`
                    });
                }}
            >
                #{client.id} {client.name} ({client.company})
            </Clipboard>
            <Icon 
                name='delete'
                onClick={() => {
                    unselect();
                    if (onUnselect)
                        onUnselect(client);
                }}
            />
        </Label>
    );
};

const mapStateToProps = ({ clientGrid }) => ({
    client: clientGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    unselect: () => dispatch(selectClientFromGrid(null)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectedClientBadge);