
import {
    COMPANY_LIST_LOADING, COMPANY_LIST_SET_OPTIONS, COMPANY_LIST_SET_ERROR, COMPANY_LIST_SELECT_OPTION,
} from "../constants";
import { generateReducer } from '../../common/helpers';

const initialState = {
    error: null,
    options: [],
    isLoading: false,
    selectedOption: null,
};

const loadOptions = (state, options) => ({
    ...state, error: null, isLoading: false, options,
});

const setError = (state, error) => ({ ...state, isLoading: false, error, });
const setLoading = (state) => ({ ...state, isLoading: true, });
const selectOption = (state, companyId) => {
    const selectedOption = state.options.find(c => c.id === companyId);
    return { ...state, selectedOption };
};

const reducers = {
    [COMPANY_LIST_LOADING]: setLoading,
    [COMPANY_LIST_SET_ERROR]: setError,
    [COMPANY_LIST_SET_OPTIONS]: loadOptions,
    [COMPANY_LIST_SELECT_OPTION]: selectOption,
};

const reducer = generateReducer(reducers, initialState);

export default reducer;