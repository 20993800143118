import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { Segment, Header, Form, Icon, Button, Grid } from 'semantic-ui-react';

import { 
    hideClientGridColumn, 
    changeClientGridState, 
    toggleClientGridControl, 
    setSearchedWithinFilter, 
} from './actions';
import clientGridColumns from './columns';

import EditClientButton from './edit-client-button';
import CancelClientButton from './cancel-client-button';
import SelectedClientBadge from './selected-client-badge';
import GridColumnPicker from '../../common/components/grid-column-picker';
import GridPageSizePicker from '../../common/components/grid-page-size-picker';


const UserContainerClientGridControl = ({
    pageSize, showControl, hiddenColumns, searchedWithinFilter,
    toggleClientGridControl, changeClientGridState, hideClientGridColumn, setSearchedWithinFilter,
}) => {
    if (!showControl) {
        return (
            <Segment vertical clearing>
                <SelectedClientBadge />
                <EditClientButton buttonProps={{compact: true}} />
                <CancelClientButton buttonProps={{compact: true}} />

                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleClientGridControl}
                >
                    Configure Grid
                </Button>

                <GridPageSizePicker
                    button
                    pointing="top"
                    pageSize={pageSize} 
                    text={`${pageSize}/page`}
                    className="mini right floated" 
                    onChange={changeClientGridState}
                />
            </Segment>
        );
    }
    
    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name="table" />
                            <Header.Content>
                                Clients Grid
                                <Header.Subheader>
                                    Configure The Grid, select a client to see their search, settings etc.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleClientGridControl}
                        >
                            Hide
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Form className="tm-15">
                <Form.Group>
                    <Form.Field width={12}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker
                            columns={clientGridColumns}
                            hiddenColumns={hiddenColumns}
                            onChange={hideClientGridColumn}
                        />
                    </Form.Field>

                    <Form.Field width={4}>
                        <label>Users Per Page</label>
                        <GridPageSizePicker
                            fluid
                            selection
                            pageSize={pageSize} 
                            onChange={changeClientGridState}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field width={5}>
                        <label>Selected User</label>
                        <SelectedClientBadge />
                    </Form.Field>
                    <Form.Field width={5}>
                        <label>Client Account Actions</label>
                        <EditClientButton />
                        <CancelClientButton />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label>Active Within</label>
                        <DatesRangeInput
                            clearable
                            name="datesRange"
                            iconPosition="left"
                            dateFormat="MM-DD-YYYY"
                            placeholder="From - To"
                            closeOnMouseLeave={false}
                            value={searchedWithinFilter}
                            onChange={(e, data) => setSearchedWithinFilter(data.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ clientGrid }) => ({
    pageSize: clientGrid.pageSize,
    showControl: clientGrid.showControl,
    shownColumns: clientGrid.shownColumns,
    hiddenColumns: clientGrid.hiddenColumns,
    searchedWithinFilter: clientGrid.searchedWithinFilter,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hideClientGridColumn, 
    changeClientGridState,
    toggleClientGridControl, 
    setSearchedWithinFilter,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerClientGridControl);