import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button, Message, Confirm } from 'semantic-ui-react';

import { createUpcForProduct, deleteUpcVariationsForProduct } from './actions';

class ProductContainerUpcCreator extends React.Component {
    state = {
        upc: '',
        showConfirm: false,
        showDeleteConfirm: false,
    };

    setConfirm = (showConfirm=true) => this.setState({ showConfirm });
    setDeleteConfirm = (showDeleteConfirm=true) => this.setState({ showDeleteConfirm });

    onConfirm = () => {
        this.props.createUpcForProduct(this.props.product.product_id, this.state.upc);
        this.setConfirm(false);
    };

    onDeleteConfirm = () => {
        this.props.deleteUpcVariationsForProduct(this.props.product.product_id, this.state.upc);
        this.setDeleteConfirm(false);
    };

    render() {
        const { upc, showConfirm, showDeleteConfirm } = this.state;
        const { isCreating, createError, product, isDeletingUpcVariation } = this.props;

        return (
            <div>
                <Confirm 
                    size="tiny"
                    open={showConfirm} 
                    confirmButton="Add It"
                    cancelButton="Nevermind"
                    onConfirm={this.onConfirm} 
                    onCancel={() => this.setConfirm(false)}
                    content={`Sure you want to add ${upc} to ${product.product}?`} 
                />

                <Confirm 
                    size="tiny"
                    open={showDeleteConfirm} 
                    confirmButton="Remove Variations"
                    cancelButton="Nevermind"
                    onConfirm={this.onDeleteConfirm} 
                    onCancel={() => this.setDeleteConfirm(false)}
                    content={`Sure you want to remove all variations of UPC ${upc} from ${product.product}?`} 
                />

                <Input 
                    fluid 
                    action
                    type='text' 
                    placeholder='Type a UPC...' 
                >
                    <input 
                        value={upc}
                        onChange={(e) => this.setState({ upc: e.target.value })}
                    />
                    <Button
                        color="red"
                        icon="close"
                        loading={ isDeletingUpcVariation } 
                        onClick={() => this.setDeleteConfirm(true)}
                        disabled={ isDeletingUpcVariation || upc.length < 1 }
                    />
                    <Button
                        icon="check"
                        color="green"
                        loading={ isCreating } 
                        onClick={() => this.setConfirm(true)}
                        disabled={ isCreating || upc.length < 1 }
                    />
                </Input>

                {createError && (<Message
                    header="Something doesn't look right!"
                    content={createError}
                    error
                />)}
            </div>
        );
    }
};

ProductContainerUpcCreator.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productUpc }) => ({
    isCreating: productUpc.isCreating,
    createError: productUpc.createError,
    isDeletingUpcVariation: productUpc.isDeletingUpcVariation,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createUpcForProduct, deleteUpcVariationsForProduct
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerUpcCreator);