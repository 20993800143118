import { difference } from 'lodash';

import {
    CLIENT_SEARCH_GRID_SELECT_SEARCH,
    CLIENT_SEARCH_GRID_DATA, CLIENT_SEARCH_GRID_ERROR, CLIENT_SEARCH_GRID_LOADING,
    CLIENT_SEARCH_GRID_COLUMN_TOGGLE, CLIENT_SEARCH_GRID_PAGE_CHANGE, CLIENT_SEARCH_GRID_STATE_CHANGE, CLIENT_SEARCH_GRID_CONTROL_TOGGLE,
} from "../constants";
import { searchGridColumns } from "./columns";
import { generateReducer } from '../../common/helpers';

const initialState = {
    error: null,
    searches: [],
    selected: null,
    isLoading: false,
    hiddenColumns: [],
    currentPage: 1,
    lastRow: null,
    pageSize: 15,
    sortModel: [],
    filterModel: {},
    showControl: false,
    shownColumns: Object.keys(searchGridColumns),
    columnDefs: Object.values(searchGridColumns),
};

const setData = (state, {searches, lastRow}) => ({ ...state, error: null, isLoading: false,  searches, lastRow, });
const setError = (state, error) => ({ ...state, isLoading: false, error });
const setLoading = (state) => ({ ...state, isLoading: true });

const changeState = (state, data) => ({...state, ...data});
const changePage = (state, currentPage) => ({...state, currentPage});
const toggleColumn = (state, hiddenColumns) => {
    const shownColumns = difference(Object.keys(searchGridColumns), hiddenColumns);
    return { ...state, shownColumns, hiddenColumns };
};

const toggleControl = (state) => ({ ...state, showControl: !state.showControl });
const selectSearch = (state, selected) => ({ ...state, selected });

const reducers = {
    [CLIENT_SEARCH_GRID_DATA]: setData,
    [CLIENT_SEARCH_GRID_ERROR]: setError,
    [CLIENT_SEARCH_GRID_LOADING]: setLoading,
    
    [CLIENT_SEARCH_GRID_PAGE_CHANGE]: changePage,
    [CLIENT_SEARCH_GRID_STATE_CHANGE]: changeState,
    [CLIENT_SEARCH_GRID_COLUMN_TOGGLE]: toggleColumn,

    [CLIENT_SEARCH_GRID_CONTROL_TOGGLE]: toggleControl,
    [CLIENT_SEARCH_GRID_SELECT_SEARCH]: selectSearch,
};

export default generateReducer(reducers, initialState);