export const PRODUCT_MATCH_GRID_COLUMN_SAVING =
  "PRODUCT_MATCH_GRID_COLUMN_SAVING";
export const PRODUCT_MATCH_GRID_COLUMN_SAVED =
  "PRODUCT_MATCH_GRID_COLUMN_SAVED";
export const PRODUCT_MATCH_GRID_COLUMN_ERROR =
  "PRODUCT_MATCH_GRID_COLUMN_ERROR";

export const PRODUCT_MATCH_GRID_COLUMN_TOGGLE =
  "PRODUCT_MATCH_GRID_COLUMN_TOGGLE";
export const PRODUCT_MATCH_GRID_SELECT_MATCH =
  "PRODUCT_MATCH_GRID_SELECT_MATCH";
export const PRODUCT_MATCH_GRID_STATE_CHANGE =
  "PRODUCT_MATCH_GRID_STATE_CHANGE";
export const PRODUCT_MATCH_GRID_PAGE_CHANGE = "PRODUCT_MATCH_GRID_PAGE_CHANGE";
export const PRODUCT_MATCH_GRID_LOADING = "PRODUCT_MATCH_GRID_LOADING";
export const PRODUCT_MATCH_GRID_ERROR = "PRODUCT_MATCH_GRID_ERROR";
export const PRODUCT_MATCH_GRID_DATA = "PRODUCT_MATCH_GRID_DATA";

export const PRODUCT_GRID_COLUMN_SAVING = "PRODUCT_GRID_COLUMN_SAVING";
export const PRODUCT_GRID_COLUMN_SAVED = "PRODUCT_GRID_COLUMN_SAVED";
export const PRODUCT_GRID_COLUMN_ERROR = "PRODUCT_GRID_COLUMN_ERROR";

export const PRODUCT_GRID_COLUMN_CHANGE_STATE =
  "PRODUCT_GRID_COLUMN_CHANGE_STATE";
export const PRODUCT_GRID_COLUMN_TOGGLE = "PRODUCT_GRID_COLUMN_TOGGLE";

export const PRODUCT_GRID_CONTROL_TOGGLE = "PRODUCT_GRID_CONTROL_TOGGLE";
export const PRODUCT_GRID_SELECT_PRODUCT = "PRODUCT_GRID_SELECT_PRODUCT";
export const PRODUCT_GRID_STATE_CHANGE = "PRODUCT_GRID_STATE_CHANGE";
export const PRODUCT_GRID_PAGE_CHANGE = "PRODUCT_GRID_PAGE_CHANGE";
export const PRODUCT_GRID_LOADING = "PRODUCT_GRID_LOADING";
export const PRODUCT_GRID_ERROR = "PRODUCT_GRID_ERROR";
export const PRODUCT_GRID_DATA = "PRODUCT_GRID_DATA";

export const PRODUCT_UPC_VARIATIONS_DELETED = "PRODUCT_UPC_VARIATIONS_DELETED";
export const PRODUCT_UPC_DELETING = "PRODUCT_UPC_DELETING";
export const PRODUCT_UPC_MINIMIZE = "PRODUCT_UPC_MINIMIZE";
export const PRODUCT_UPC_LOADING = "PRODUCT_UPC_LOADING";
export const PRODUCT_UPC_DELETED = "PRODUCT_UPC_DELETED";
export const PRODUCT_UPC_ERROR = "PRODUCT_UPC_ERROR";
export const PRODUCT_UPC_DATA = "PRODUCT_UPC_DATA";
export const PRODUCT_UPC_SORT = "PRODUCT_UPC_SORT";

export const PRODUCT_UPC_CREATE_ERROR = "PRODUCT_UPC_CREATE_ERROR";
export const PRODUCT_UPC_CREATING = "PRODUCT_UPC_CREATING";
export const PRODUCT_UPC_CREATED = "PRODUCT_UPC_CREATED";

export const PRODUCT_UPDATE_MINIMIZE = "PRODUCT_UPDATE_MINIMIZE";
export const PRODUCT_UPDATE_LOADING = "PRODUCT_UPDATE_LOADING";
export const PRODUCT_UPDATE_DELETED = "PRODUCT_UPDATE_DELETED";
export const PRODUCT_UPDATE_ERROR = "PRODUCT_UPDATE_ERROR";
export const PRODUCT_UPDATE_DATA = "PRODUCT_UPDATE_DATA";

export const PRODUCT_NORMAL_MINIMIZE = "PRODUCT_NORMAL_MINIMIZED";
export const PRODUCT_NORMAL_LOADING = "PRODUCT_NORMAL_LOADING";
export const PRODUCT_NORMAL_DELETED = "PRODUCT_NORMAL_DELETED";
export const PRODUCT_NORMAL_ERROR = "PRODUCT_NORMAL_ERROR";
export const PRODUCT_NORMAL_DATA = "PRODUCT_NORMAL_DATA";
export const PRODUCT_NORMAL_SORT = "PRODUCT_NORMAL_SORT";

export const PRODUCT_NORMAL_CREATE_ERROR = "PRODUCT_NORMAL_CREATE_ERROR";
export const PRODUCT_NORMAL_CREATING = "PRODUCT_NORMAL_CREATING";
export const PRODUCT_NORMAL_CREATED = "PRODUCT_NORMAL_CREATED";

export const PRODUCT_QUEENIFIER_QUEUE_CONFIRMING =
  "PRODUCT_QUEENIFIER_QUEUE_CONFIRMING";
export const PRODUCT_QUEENIFIER_QUEUE_ERROR = "PRODUCT_QUEENIFIER_QUEUE_ERROR";
export const PRODUCT_QUEENIFIER_SET_QUEEN = "PRODUCT_QUEENIFIER_SET_QUEEN";
export const PRODUCT_QUEENIFIER_SET_PAWN = "PRODUCT_QUEENIFIER_SET_PAWN";
export const PRODUCT_QUEENIFIER_QUEUEING = "PRODUCT_QUEENIFIER_QUEUEING";
export const PRODUCT_QUEENIFIER_MAXIMIZE = "PRODUCT_QUEENIFIER_MAXIMIZE";
export const PRODUCT_QUEENIFIER_MINIMIZE = "PRODUCT_QUEENIFIER_MINIMIZE";
export const PRODUCT_QUEENIFIER_QUEUED = "PRODUCT_QUEENIFIER_QUEUED";

export const PRODUCT_UNTANGLER_CONFIRMING = "PRODUCT_UNTANGLER_CONFIRMING";
export const PRODUCT_UNTANGLER_UNTANGLING = "PRODUCT_UNTANGLER_UNTANGLING";
export const PRODUCT_UNTANGLER_SET_INPUT = "PRODUCT_QUEENIFIER_SET_INPUT";
export const PRODUCT_UNTANGLER_COMPLETE = "PRODUCT_UNTANGLER_COMPLETE";
export const PRODUCT_UNTANGLER_ERROR = "PRODUCT_UNTANGLER_ERROR";

export const PRODUCT_EDITOR_INPUT = "PRODUCT_EDITOR_INPUT";
export const PRODUCT_EDITOR_ERROR = "PRODUCT_EDITOR_ERROR";
export const PRODUCT_EDITOR_SAVING = "PRODUCT_EDITOR_SAVING";
export const PRODUCT_EDITOR_SAVED = "PRODUCT_EDITOR_SAVED";
export const PRODUCT_EDITOR_CONFIRMING = "PRODUCT_EDITOR_CONFIRMING";
export const PRODUCT_EDITOR_PRODUCT_DATA = "PRODUCT_EDITOR_PRODUCT_DATA";
export const PRODUCT_EDITOR_PRODUCT_ERROR = "PRODUCT_EDITOR_PRODUCT_ERROR";
export const PRODUCT_EDITOR_PRODUCT_RESET = "PRODUCT_EDITOR_PRODUCT_RESET";
export const PRODUCT_EDITOR_PRODUCT_LOADING = "PRODUCT_EDITOR_PRODUCT_LOADING";

export const ProductPriceLevels = [
  "Value",
  "Mid",
  "Premium",
  "Super",
  "Luxury",
];
