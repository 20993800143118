import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import ProductGrid from '../containers/product-grid';

import ProductContainerUpcList from '../upc/list';
import ProductContainerMatchGrid from '../match/grid';
import ProductContainerNormalList from '../normal/list';
import ProductContainerUntangler from '../containers/untangler';
import ProductContainerQueenifier from '../queenifier/container';
import ProductContainerUpdateList from '../containers/update-list';

import { applyIdFilter } from '../helpers';
import { selectProductFromGrid, changeProductGridState } from '../actions';
import { maximizeProductQueenifier, minimizeProductQueenifier } from '../queenifier/actions';

class ProductHomePage extends React.Component {
    productGrid = React.createRef();

    componentWillMount() {
        const { computedMatch, changeProductGridState, filterModel, selectProductFromGrid } = this.props,
            productId = parseInt(get(computedMatch, 'params.productId', 0));
        
        if (productId) {
            changeProductGridState(applyIdFilter(filterModel, productId));
            selectProductFromGrid({ product_id: productId });
        }
    };

    render() {
        const {
            selectedProduct, selectedMatch, minimized,
            maximizeProductQueenifier, minimizeProductQueenifier
        } = this.props;

        const hasSelectedProduct = (selectedProduct && selectedProduct.product_id);
        const hasSelectedMatch = (selectedMatch && selectedMatch.product_id);

        return (
            <Grid>
                <TopNav 
                    headerContent='Products'
                    headerIcon='glass martini'
                    subheader='Home to all the products in the ydk Database'/>

                <Grid.Row>
                    <Grid.Column mobile={16}>
                        <ProductGrid ref={r => this.productGrid = r}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} computer={hasSelectedMatch ? 11 : 16}>
                        <Segment>
                            <Header>
                                <Grid>
                                    <Grid.Column width={14}>
                                        <Header.Content>
                                            Merger/Untangler
                                        </Header.Content>
                                    </Grid.Column>

                                    <Grid.Column width={2}>
                                        <Button
                                            compact
                                            size="mini"
                                            color="grey"
                                            floated="right"
                                            content={minimized ? 'Show' : 'Hide'}
                                            onClick={minimized ? maximizeProductQueenifier : minimizeProductQueenifier}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Header>
                            {!minimized && (
                                <Segment.Group horizontal>
                                    <ProductContainerQueenifier
                                        onQueued={() => this.productGrid.wrappedInstance.reloadGridData()}
                                    />
                                    <ProductContainerUntangler /> 
                                </Segment.Group>
                            )}    
                        </Segment>

                        {hasSelectedProduct && <ProductContainerMatchGrid />}
                    </Grid.Column>

                    {hasSelectedMatch && (
                        <Grid.Column mobile={16} computer={5} className="tm-15">
                            <ProductContainerUpcList product={selectedProduct} />
                            <ProductContainerNormalList product={selectedProduct} />
                            <ProductContainerUpdateList product={selectedProduct} />
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        );
    };
 
};

const mapStateToProps = ({ productGrid, productQueenifier, productMatchGrid }) => ({
    filterModel: productGrid.filterModel,
    selectedProduct: productGrid.selected,
    
    minimized: productQueenifier.minimized,

    selectedMatch: productMatchGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    minimizeProductQueenifier, maximizeProductQueenifier,
    selectProductFromGrid, changeProductGridState,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductHomePage);