import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const EditClientButton = ({ client, history, buttonProps={} }) => {
    if (!client)
        return null;

    return (
        <Button
            size="tiny"
            disabled={!client}
            color={!!client ? "teal" : "grey"}
            onClick={() => history.push(`/user/${client.id}/edit`)}
            {...buttonProps}
        >
            Edit Client
        </Button>
    );
};

const mapStateToProps = ({ clientGrid }) => ({
    client: clientGrid.selected,
});

export default connect(mapStateToProps)(withRouter(EditClientButton));