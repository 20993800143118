import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'formik-semantic-ui';
import { bindActionCreators } from 'redux';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router-dom';
import { Segment, Message } from 'semantic-ui-react';

import { validateCompanyCreate, validateCompanyUpdate } from '../services/validators';
import { setCompanyEditorInput, setCompanyEditorError, requestSaveCompany, loadCompanyForEdit } from '../actions';

import CompanyContainerCreateConfirm from './create-confirm';
import CompanyComponentEditorForm from '../components/editor-form';
import { getEnvColor } from '../../common/helpers';

class CompanyContainerEditor extends React.Component {
    formikApi = null;

    handleSubmit = async (companyData, formikApi) => {
        this.formikApi = formikApi;
        const { requestSaveCompany, setCompanyEditorError } = this.props;

        try {
            let company;

            if (companyData.id)
                company = await validateCompanyUpdate(companyData);
            else
                company = await validateCompanyCreate(companyData);

            requestSaveCompany(company);
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                formikApi.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            formikApi.setSubmitting(false);
            setCompanyEditorError(messages);
        }
    };

    handleConfirm = (data) => {
        this.formikApi && this.formikApi.setSubmitting(false);

        if (data !== null) {
            let description = ['Company saved in database.'];

            if (!data.intercom) {
                description.push('Could not save company on intercom.');
            } else {
                description.push('Saved company on intercom.');
            }

            if (!data.roninClient) {
                description.push('Could not save company on ronin.');
            } else {
                description.push('Saved company on ronin.');
            }

            toast({
                description: (<Message.List items={description} />), 
                time: 6000,
                type: 'success',
                icon: 'checkmark',
                title: 'Created Company',
            });

            this.props.history.push('/company/renewals');
        }
    };

    render () {
        const { 
            errors, inputs, isSaving, isLoadingCompany, companyError, env
        } = this.props;
        
        return (
            <Segment 
                loading={isLoadingCompany} 
                disabled={!!companyError}
                color={getEnvColor(env)}
            >
                <CompanyContainerCreateConfirm
                    onClose={this.handleConfirm}
                />

                {!isLoadingCompany ? (
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={(props) => (<CompanyComponentEditorForm {...props} />)}
                    />
                ) : (<div className="my-20"></div>)}

                {errors.length > 0 && (
                    <Message
                        error
                        content={errors}
                        icon="warning sign"
                        header="Error saving company!" 
                    />
                )}
            </Segment>
        );
    };
}

const mapStateToProps = ({ companyEditor, moduleEnv }) => ({
    errors: companyEditor.errors,
    inputs: companyEditor.inputs,
    company: companyEditor.company,
    isSaving: companyEditor.isSaving,
    companyError: companyEditor.companyError,
    isLoadingCompany: companyEditor.isLoadingCompany,

    env: moduleEnv.company,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCompanyEditorInput, requestSaveCompany, loadCompanyForEdit, setCompanyEditorError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyContainerEditor));

