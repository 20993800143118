import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Confirm, Modal, List } from 'semantic-ui-react';

import { requestClientCancel, cancelClient } from './actions';
import { UserSubscriptionStatuses } from '../constants';

const ConfirmContent = ({client={}}) => (
    <Modal.Content>
        Please verify that you want to cancel the following client's account
        <br />

        <List>
            <List.Item>
                <List.Header>Client ID</List.Header>
                #{client.id}
            </List.Item>
            <List.Item>
                <List.Header>Name</List.Header>
                {client.name}
            </List.Item>
            <List.Item>
                <List.Header>Email</List.Header>
                {client.email}
            </List.Item>
        </List>
    </Modal.Content>
);

const CancelClientButton = ({ 
    client, onCancel, isCancelling, isConfirming, 
    requestClientCancel, cancelClient, buttonProps={},
}) => {
    const statusActive = client && client.service_status === UserSubscriptionStatuses.ACTIVE;
    const buttonText = "Cancel Account";

    if (!statusActive)
        return null;

    return (
        <>
            <Confirm
                size="tiny"
                open={isConfirming}
                cancelButton="Nevermind"
                header="Confirm Cancellation"
                confirmButton="Cancel Account"
                onConfirm={() => cancelClient(client.id, onCancel)}
                onCancel={() => requestClientCancel(false)}
                content={(<ConfirmContent client={client} />)}
            />

            { !!client && (
                <Button 
                    color={statusActive ? "red" : "grey"} 
                    onClick={requestClientCancel}
                    disabled={!statusActive}
                    loading={isCancelling}
                    size="tiny"
                    {...buttonProps}
                >
                    { buttonText }
                </Button>
            ) }
        </>
    );
};

const mapStateToProps = ({ clientGrid }) => ({
    client: clientGrid.selected,
    isCancelling: clientGrid.cancellingClient,
    isConfirming: clientGrid.confirmingClientCancel,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    requestClientCancel, cancelClient,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CancelClientButton);