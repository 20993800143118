import React from 'react';
import AceEditor from 'react-ace';
import format from 'date-fns/format';
import { Segment, Header, Button, Label, Divider } from 'semantic-ui-react';

import 'brace/mode/mysql';
import 'brace/theme/twilight';

const serverColors = {
    all: 'yellow',
    development: 'teal',
    production: 'orange',
};

const CheckComponentSingle = ({ 
    check, showDetails,
    onHideCheckDetails, onShowCheckDetails, onEditCheck, onRunCheck, onDeleteCheck,
}) => (
    <React.Fragment>
        <Segment 
            clearing
            attached="top"
        >
            <Header floated="left">
                #{check.check_id} { check.description }

                <Header.Subheader>
                    Created <span className="text-bold">{format(check.create_date, 'DD MMM, YY')}</span>
                    <br/>
                    Last Changed <span className="text-bold">{format(check.last_modified, 'DD MMM, YY')}</span> | 
                    Last Ran <span className="text-bold">{format(check.last_run, 'DD MMM, YY HH:mm')}</span>
                </Header.Subheader>
            </Header>

            <Header floated="right" textAlign="right">
                <Label.Group size="tiny">
                    <Label
                        basic
                        icon="server"
                        content={check.server}
                        color={serverColors[check.server]}
                    />
                    <br/>

                    <Label
                        basic
                        color={check.failed ? 'red' : 'green'}
                        content={check.failed ? 'Failed' : 'Passed'}
                        icon={check.failed ? 'remove circle' : 'check'}
                    />
                </Label.Group>
            </Header>
            <Divider hidden clearing fitted />

            { showDetails && (
                <React.Fragment>
                    <Header icon="sticky note" content="Note" />
                    <p>{ check.note }</p>

                    <Header icon="database" content="Check Query" />
                    <AceEditor
                        mode="mysql"
                        width="100%"
                        height="200px"
                        readOnly={true}
                        theme="twilight"
                        name="check_query"
                        value={check.check_query}
                    />

                    <Header icon="wrench" content="Resolve Instructions" />
                    <AceEditor
                        mode="mysql"
                        width="100%"
                        height="100px"
                        readOnly={true}
                        theme="twilight"
                        name="resolve_instructions"
                        value={check.resolve_instructions}
                    />
                </React.Fragment>
            )}
        </Segment>
        
        <Button.Group attached="bottom">
            <Button
                icon="pencil"
                content="Edit"
                onClick={() => onEditCheck(check)}
            />
            <Button
                icon="eye"
                content={ showDetails ? "Hide Details" : "Show Details" }
                onClick={ () => showDetails ? onHideCheckDetails(check) : onShowCheckDetails(check) }
            />
            <Button
                icon="play"
                content="Run"
                onClick={() => onRunCheck(check)}
            />
            <Button
                icon="trash"
                content="Delete"
                onClick={() => onDeleteCheck(check)}
            />
        </Button.Group>
    </React.Fragment>
);

export default CheckComponentSingle;