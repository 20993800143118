import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Form } from 'formik-semantic-ui';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Segment, Message } from 'semantic-ui-react';

import { loadCompanyListOptions } from '../../company/actions';
import { validateUserCreate, validateUserUpdate } from '../services/validators';
import { setClientEditorInput, setClientEditorError, requestSaveClient, loadClientForEdit } from '../actions';

import UserContainerCreateConfirm from './create-confirm';
import UserComponentEditorForm from '../components/editor-form';

import { getEnvColor } from '../../common/helpers';

class UserContainerEditor extends React.Component {
    formikApi = null;

    componentWillMount () {
        const { companies } = this.props;

        if (isEmpty(companies))
            this.props.loadCompanyListOptions();
    };

    handleSubmit = async (clientData, formikApi) => {
        this.formikApi = formikApi;
        const { requestSaveClient, setClientEditorError } = this.props;

        try {
            let client;

            if (clientData.id)
                client = await validateUserUpdate(clientData);
            else
                client = await validateUserCreate(clientData);

            requestSaveClient(client);
        } catch (err) {
            err.details.forEach(errDet => {
                formikApi.setFieldError(errDet.context.key, errDet.message);
            });

            formikApi.setSubmitting(false);
            setClientEditorError(err);
        }
    };

    render () {
        const { 
            errors, inputs, companies, isSaving, isLoadingClient, clientError, history, env
        } = this.props;
        
        return (
            <Segment 
                loading={isLoadingClient} 
                disabled={!!clientError}
                color={getEnvColor(env)}
            >
                <UserContainerCreateConfirm
                    onClose={(data) => {
                        this.formikApi && this.formikApi.setSubmitting(false);
                        if (!!data)
                            history.push('/user');
                    }}
                />

                {!isLoadingClient ? (
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={(props) => (<UserComponentEditorForm {...props} companies={companies} />)}
                    />
                ) : (<div className="my-20"></div>)}

                {errors.length > 0 && (
                    <Message
                        error
                        content={errors}
                        icon="warning sign"
                        header="Error saving client!" 
                    />
                )}
            </Segment>
        );
    };
}

const mapStateToProps = ({ clientEditor, companyList, moduleEnv }) => ({
    errors: clientEditor.errors,
    inputs: clientEditor.inputs,
    isSaving: clientEditor.isSaving,
    clientError: clientEditor.clientError,
    isLoadingClient: clientEditor.isLoadingClient,

    companies: companyList.options,
    companiesLoading: companyList.isLoading,

    env: moduleEnv.user
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setClientEditorInput, requestSaveClient, loadClientForEdit,
    loadCompanyListOptions, setClientEditorError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserContainerEditor));

