import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Dropdown } from 'semantic-ui-react';

const getSelectedFromFilters = (filters = {}) => {
    // these come from ag-grid column filter
    if (filters.operator) {
        const selected = Object.keys(filters).find(key => {
            if (key.indexOf('condition') !== 0)
                return false;

            const f = filters[key];
            return f.type === 'equals';
        });

        return !!selected ? selected.filter : false;
    } else if (filters.type === 'equals') {
        return filters.filter;
    } else if (filters.product_type) {
        return filters.product_type.toLowerCase();
    }

    return false;
};

const ProductComponentTypeFilter = ({
    onSelect, currentFilter = {}, hideAll = false,
    ...others
}) => {
    const filters = ['all', 'beer', 'wine', 'liquor', 'n/a', 'other'],
        selected = getSelectedFromFilters(currentFilter) || 'all';

    if (hideAll) {
        filters.shift();
    }

    return (
        <Dropdown
            search
            value={selected}
            onChange={(e, { value }) => {
                if (selected === value)
                    return null;

                return onSelect(value);
            }}
            options={filters.map(f => ({
                key: f,
                value: f,
                text: capitalize(f),
                active: selected === f,
            }))}
            {...others}
        />
    );
};

ProductComponentTypeFilter.propTypes = {
    // if ALL types should be an option or not
    hideAll: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    currentFilter: PropTypes.object.isRequired,
};

export default ProductComponentTypeFilter;