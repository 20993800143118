
export const CHECK_DATA = 'CHECK_DATA'; 
export const CHECK_ERROR = 'CHECK_ERROR'; 
export const CHECK_LOADING = 'CHECK_LOADING'; 
export const CHECK_DELETED = 'CHECK_DELETED';
export const CHECK_DETAIL_SHOW = 'CHECK_DETAIL_SHOW';
export const CHECK_DETAIL_HIDE = 'CHECK_DETAIL_HIDE';

export const CHECK_EDITOR_DATA = 'CHECK_EDITOR_DATA';
export const CHECK_EDITOR_ERROR = 'CHECK_EDITOR_ERROR';
export const CHECK_EDITOR_SAVED = 'CHECK_EDITOR_SAVED';
export const CHECK_EDITOR_SAVING = 'CHECK_EDITOR_SAVING';
export const CHECK_EDITOR_LOADING = 'CHECK_EDITOR_LOADING';