import { capitalize } from 'lodash';

export const getCompanyColumnText = (key) => {
    const labels = {
        service_price: 'Price',
        service_start: 'Start Date',
        service_level: 'Subscription',
        service_renewal: 'Next Renewal',
        service_billing_cycle: 'Billing Cycle',
    };

    if (labels[key]) {
        return labels[key];
    } 

    return capitalize(key.replace(/_/ig, ' '));
};

export const getCompanyColumnValue = (key, value) => {
    let output = '';

    switch (key) {
        case 'need_po':
        case 'need_sign':
            output = value === true ? 'Yes' : 'No';
            break;
        case 'ronin_id':
            output = parseInt(value) > 0 ? value : 'Not Set';
            break;
        default:
            output = value;
            break;
    }

    return output;
};