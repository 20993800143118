import {
    PRODUCT_UPC_PICKER_LOADING,
    PRODUCT_UPC_PICKER_SELECT,
    PRODUCT_UPC_PICKER_ERROR,
    PRODUCT_UPC_PICKER_DATA,
} from './constants';

const initialState = {
    isLoading: false,
    selected: null,
    upcs: [],
    error: null,
};

const setLoading = (state) => ({ ...state, isLoading: true });
const setError = (state, error) => ({ ...state, error, isLoading: false });
const setData = (state, upcs) => ({ ...state, isLoading: false, upcs });
const selectData = (state, selected) => ({ ...state, selected });

const reducers = {
    [PRODUCT_UPC_PICKER_LOADING]: setLoading,
    [PRODUCT_UPC_PICKER_SELECT]: selectData,
    [PRODUCT_UPC_PICKER_ERROR]: setError,
    [PRODUCT_UPC_PICKER_DATA]: setData,
};

export default (state = initialState, action) => {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload);
    }

    return state;
};