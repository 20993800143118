import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import {
    PRODUCT_QUEENIFIER_QUEUE_CONFIRMING,
    PRODUCT_QUEENIFIER_SET_QUEEN,
    PRODUCT_QUEENIFIER_SET_PAWN,
    PRODUCT_QUEENIFIER_QUEUEING,
    PRODUCT_QUEENIFIER_QUEUED,
    PRODUCT_QUEENIFIER_QUEUE_ERROR,
    PRODUCT_QUEENIFIER_MINIMIZE,
    PRODUCT_QUEENIFIER_MAXIMIZE,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { apiCallError } from '../../common/actions';
import { makePrivateApiCall } from '../../common/api';

export const maximizeProductQueenifier = () => dispatch => dispatch({
    type: PRODUCT_QUEENIFIER_MAXIMIZE,
});

export const minimizeProductQueenifier = () => dispatch => dispatch({
    type: PRODUCT_QUEENIFIER_MINIMIZE,
});

export const setProductQueenifierPawn = (pawn) => dispatch => {
    dispatch({
        type: PRODUCT_QUEENIFIER_SET_PAWN,
        payload: pawn,
    });
};

export const setProductQueenifierQueen = (queen) => dispatch => {
    dispatch({
        type: PRODUCT_QUEENIFIER_SET_QUEEN,
        payload: queen,
    });
};

export const setProductQueenifierError = (error) => dispatch => {
    return dispatch({
        type: PRODUCT_QUEENIFIER_QUEUE_ERROR,
        payload: error
    });
};

export const setProductQueenifierConfirming = (confirming) => dispatch => {
    return dispatch({
        type: PRODUCT_QUEENIFIER_QUEUE_CONFIRMING,
        payload: !!confirming
    });
};

export const requestProductMerge = (queen, pawn) => dispatch => {
    if (!parseInt(queen))
        return dispatch(setProductQueenifierError("Please insert valid product id for queen."));

    if (!parseInt(pawn))
        return dispatch(setProductQueenifierError("Please insert valid product id for pawn."));

    if (parseInt(queen) === parseInt(pawn))
        return dispatch(setProductQueenifierError("Queen and Pawn can not be same."));

    return dispatch(setProductQueenifierConfirming(true));
};

export const queueProductMerge = (queen, pawn) => dispatch => {
    dispatch({
        type: PRODUCT_QUEENIFIER_QUEUEING,
        payload: true,
    });

    return makePrivateApiCall({
        url: `/product/merge/queue`,
        method: 'post',
        data: { queen, pawn },
    }).then(res => {
        if (res.data) {
            toast({
                time: 3000,
                icon: 'chess',
                type: 'success',
                title: 'Merge queued',
                description: `Product Merge has been added to the queue.`,
            });

            return dispatch({
                type: PRODUCT_QUEENIFIER_QUEUED,
                payload: res.data
            });
        }

        return dispatch(setProductQueenifierError("Could not queue merge on server :("));
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        if (err.response) {
            const message = get(err, 'response.data.message', 'Something went wrong');
            return dispatch(setProductQueenifierError(message));
        }

        return dispatch(apiCallError(err));
    });
};