import {
    REPORT_LIST_LOADING,
    REPORT_LIST_DATA,
    REPORT_LIST_ERROR,
    REPORT_LIST_DETAIL_HIDE,
    REPORT_LIST_DETAIL_SHOW,
    REPORT_LIST_TOGGLE_SUB_COUNT_FILTER,
} from "../constants";
import {
    makePrivateApiCall
} from "../../common/api";

export const loadReportList = () => (dispatch, getState) => {
    const {
        moduleEnv
    } = getState();

    dispatch({
        type: REPORT_LIST_LOADING
    });

    const params = {
        env: moduleEnv.report
    };

    makePrivateApiCall({
            url: "/report",
            method: "get",
            params,
        })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: REPORT_LIST_DATA,
                    payload: res.data
                });
            }
        })
        .catch(err => {
            dispatch({
                type: REPORT_LIST_ERROR,
                payload: err.message
            });
        });
};


export const showReportDetails = report => dispatch => {
    dispatch({
        type: REPORT_LIST_DETAIL_SHOW,
        payload: report
    });
};

export const hideReportDetails = report => dispatch => {
    dispatch({
        type: REPORT_LIST_DETAIL_HIDE,
        payload: report
    });
};

export const toggleSubscriberCountFilter = () => dispatch => {
    dispatch({
        type: REPORT_LIST_TOGGLE_SUB_COUNT_FILTER
    });
};
