export const USAGE_GRID_DATA = 'USAGE_GRID_DATA';
export const USAGE_GRID_ERROR = 'USAGE_GRID_ERROR';
export const USAGE_GRID_LOADING = 'USAGE_GRID_LOADING';
export const USAGE_GRID_STATE_CHANGE = 'USAGE_GRID_STATE_CHANGE';
export const USAGE_GRID_COLUMN_TOGGLE = 'USAGE_GRID_COLUMN_TOGGLE';
export const USAGE_GRID_SERVICE_LEVEL = 'USAGE_GRID_SERVICE_LEVEL';
export const USAGE_GRID_SELECT_COMPANY = 'USAGE_GRID_SELECT_COMPANY';
export const USAGE_GRID_CONTROL_TOGGLE = 'USAGE_GRID_CONTROL_TOGGLE';

export const AGREEMENT_GRID_DATA = 'AGREEMENT_GRID_DATA, ';
export const AGREEMENT_GRID_ERROR = 'AGREEMENT_GRID_ERROR,';
export const AGREEMENT_GRID_LOADING = 'AGREEMENT_GRID_LOADING, ';

export const RENEWAL_GRID_DATA = 'RENEWAL_GRID_DATA';
export const RENEWAL_GRID_ERROR = 'RENEWAL_GRID_ERROR';
export const RENEWAL_GRID_LOADING = 'RENEWAL_GRID_LOADING';
export const RENEWAL_GRID_STATE_CHANGE = 'RENEWAL_GRID_STATE_CHANGE';
export const RENEWAL_GRID_SERVICE_LEVEL = 'RENEWAL_GRID_SERVICE_LEVEL';
export const RENEWAL_GRID_COLUMN_TOGGLE = 'RENEWAL_GRID_COLUMN_TOGGLE';
export const RENEWAL_GRID_CONTROL_TOGGLE = 'RENEWAL_GRID_CONTROL_TOGGLE';
export const RENEWAL_GRID_COLUMN_SAVING = 'RENEWAL_GRID_COLUMN_SAVING';
export const RENEWAL_GRID_COLUMN_SAVED = 'RENEWAL_GRID_COLUMN_SAVED';
export const RENEWAL_GRID_COLUMN_ERROR = 'RENEWAL_GRID_COLUMN_ERROR';

export const COMPANY_USER_GRID_DATA = 'COMPANY_USER_GRID_DATA';
export const COMPANY_USER_GRID_LOADING = 'COMPANY_USER_GRID_LOADING';
export const COMPANY_USER_GRID_ERROR = 'COMPANY_USER_GRID_ERROR';
export const COMPANY_USER_GRID_COLUMN_TOGGLE = 'COMPANY_USAGE_USER_COLUMN_TOGGLE';
export const COMPANY_USER_GRID_CONTROL_TOGGLE = 'COMPANY_USER_GRID_CONTROL_TOGGLE';

export const COMPANY_LIST_LOADING = 'COMPANY_LIST_LOADING';
export const COMPANY_LIST_SET_ERROR = 'COMPANY_LIST_SET_ERROR';
export const COMPANY_LIST_SET_OPTIONS = 'COMPANY_LIST_SET_OPTIONS';
export const COMPANY_LIST_SELECT_OPTION = 'COMPANY_LIST_SELECT_OPTION';

export const COMPANY_EDITOR_COMPANY_LOADING = 'COMPANY_EDITOR_COMPANY_LOADING';
export const COMPANY_EDITOR_COMPANY_RESET = 'COMPANY_EDITOR_COMPANY_RESET';
export const COMPANY_EDITOR_COMPANY_ERROR = 'COMPANY_EDITOR_COMPANY_ERROR';
export const COMPANY_EDITOR_COMPANY_DATA = 'COMPANY_EDITOR_COMPANY_DATA';
export const COMPANY_EDITOR_CONFIRMING = 'COMPANY_EDITOR_CONFIRMING';
export const COMPANY_EDITOR_SAVING = 'COMPANY_EDITOR_SAVING';
export const COMPANY_EDITOR_INPUT = 'COMPANY_EDITOR_INPUT';
export const COMPANY_EDITOR_ERROR = 'COMPANY_EDITOR_ERROR';
export const COMPANY_EDITOR_SAVED = 'COMPANY_EDITOR_SAVED';

export const CompanyServiceLevels = {
    LITE: "Lite",
    LEADS: "Leads",
    REPORT: "Report",
    INVOICE: "Invoice",
    MILITARY: "Military",
    COMPLETE: "Complete",
    UNLIMITED: "Unlimited",
    MARKETSLICE: "Marketslice",
    INTELLIGENCE: "Intelligence",
};

export const CompanyBillingCycles = {
    ANNUAL: 'Annual',
    MONTHLY: 'Monthly',
    BIANNUAL: 'BiAnnual',
    QUARTERLY: 'Quarterly',
};

export const CompanyTypes = {
    DISTRIBUTOR: 'Distributor',
    WHOLESALER: 'Wholesaler',
    SUPPLIER: 'Supplier',
    BROKER: 'Broker',
    BRAND: 'Brand',
    OTHER: 'Other',
    ALL: 'All',
};