import { keyBy } from 'lodash';
import format from 'date-fns/format';

export const agreementGridColumns = keyBy([
    {
        headerName: 'Company',
        field: 'company.company',
        width: 250,
        filter: 'agTextColumnFilter',
        sortable: true,
        suppressMenu: true,
    },
    {
        headerName: 'Signed By',
        field: 'client.name',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        width: 250,
    },
    {
        headerName: 'Signed At',
        field: 'sign_date',
        suppressMenu: true,
        valueGetter: ({ data }) => {
            return data && data.sign_date ? format(data.sign_date, "YYYY/MM/DD [at] H:m") : null;
        },
    },
    {
        filter: false,
        sortable: false,
        field: 'browser',
        suppressMenu: true,
        headerName: 'Browser',
        cellRenderer: "browserGridCell",
    },
    {
        suppressMenu: true,
        headerName: 'IP',
        field: 'ip',
    },
    {
        suppressMenu: true,
        headerName: 'Sign',
        field: 'sign',
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        filter: false,
        cellRenderer: "actionGridCell",
    }
], 'field');

export const hiddenAgreementGridColumns = [
    'id', 
];