import React from 'react';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unserialize, isSerialized } from 'php-serialize';
import { Icon, Segment, Header, Grid, Message, Divider, Label, Form } from 'semantic-ui-react';

import SettingGeo from '../setting/components/geo';
import UserComponentSetting from '../setting/components/common';
import SettingSalesPeriod from '../setting/components/sales-period';

import { UserSettingKeys } from '../constants';
import { loadStateSnapshots } from '../actions';
import { selectSearchFromGrid } from '../search/actions';

const snapshotPropsToShow = (snapshot) => {
    return Object.keys(snapshot).filter(name => {
        return ['id', 'search_id', 'user_id', 'report', 'timestamp', 'excel'].indexOf(name) < 0;
    });
};

const getSettingsFromSnapshot = (snapshot, keys=[]) => {
    return keys.map(key => ({
        name: key, value: snapshot[key],
    }));
};

const getGeoSettingsFromSnapshot = (snapshot) => getSettingsFromSnapshot(snapshot, [UserSettingKeys.GEO_NAME, UserSettingKeys.GEO_TYPE]);
const hasChangedSettings = (previous, current, key) => {
    if (previous && previous[key] === current[key]) {
        return false;
    }

    return true;
};

const hasChangedGeoSettings = (previous, current) => {
    if (
        previous 
        && previous[UserSettingKeys.GEO_NAME] === current[UserSettingKeys.GEO_NAME]
        && previous[UserSettingKeys.GEO_TYPE] === current[UserSettingKeys.GEO_TYPE]
    ) {
        return false;
    }

    return true;
};

const hasChangedDateSettings = (previous, current) => {
    if (
        previous 
        && previous[UserSettingKeys.SALES_PERIOD_START] === current[UserSettingKeys.SALES_PERIOD_START]
        && previous[UserSettingKeys.SALES_PERIOD_TYPE] === current[UserSettingKeys.SALES_PERIOD_TYPE]
        && previous[UserSettingKeys.SALES_PERIOD_END] === current[UserSettingKeys.SALES_PERIOD_END]
    ) {
        return false;
    }

    return true;
};

const getDateSettingsFromSnapshot = (snapshot) => getSettingsFromSnapshot(snapshot, [
    UserSettingKeys.SALES_PERIOD_START,
    UserSettingKeys.SALES_PERIOD_TYPE,
    UserSettingKeys.SALES_PERIOD_END,
]);

class UserContainerStateSnapshot extends React.Component {
    state = {
        shownSnapshots: [],
    };

    toggleSnapshot = (snapshot, i) => {
        let { shownSnapshots } = this.state;
        if (this.isSnapshotShown(snapshot, i))
            shownSnapshots = shownSnapshots.filter(id => id !== snapshot.id);
        else 
            shownSnapshots = [...shownSnapshots, snapshot.id];

        this.setState({ shownSnapshots });
    };

    isSnapshotShown = (snapshot, i) => this.state.shownSnapshots.indexOf(snapshot.id) >= 0 || i === 0;

    componentWillMount() {
        const { search } = this.props;

        if (!search)
            return;

        this.props.loadStateSnapshots(search.id);
    };

    render() {
        const { search, snapshots, client, isLoading, error, unselect } = this.props;
        const searchFilters = isSerialized(search.search_filter) ? unserialize(search.search_filter) : null;
        const searchIntelFilters = isSerialized(search.search_intel_category_filter) ? unserialize(search.search_intel_category_filter) : null;
        const searchMsFilters = isSerialized(search.search_marketslice_category_filter) ? unserialize(search.search_marketslice_category_filter) : null;

        return (
            <Segment>
                <Grid>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name='settings' />
                            <Header.Content>
                                Settings for search #{search.id}
                                <Header.Subheader>
                                    Search Ran At {format(search.create_date, 'DD MMM YY, HH:mm')}.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign="right">
                        {isLoading && (
                            <Icon name="spinner" loading />
                        )}

                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                unselect();
                            }}
                        >
                            <Icon
                                name="delete"
                                floated="right"
                            />
                        </a>
                    </Grid.Column>
                </Grid>

                <Grid columns={3}>
                    <Grid.Column>
                        <p>Other Location Filters:</p>
                        <Divider />

                        {!!searchFilters ? Object.keys(searchFilters).map(k => (
                            <div key={`search_filter_${k}`}>
                                <p><b>{startCase(k)} :</b>  {searchFilters[k]}</p>
                            </div>
                        )) : (<p>None</p>)}
                    </Grid.Column>
                
                    <Grid.Column>
                        <p>Marketslice Filters:</p>
                        <Divider />

                        {!!searchMsFilters ? Object.keys(searchMsFilters).map(k => (
                            <div key={`search_ms_filter_${k}`}>
                                <p><b>{startCase(k)} :</b>  {searchMsFilters[k] || 'None'}</p>
                            </div>
                        )) : (<p>None</p>)}
                    </Grid.Column>
                
                    <Grid.Column>
                        <p>Product Category Filters:</p>
                        <Divider />

                        {!!searchIntelFilters ? Object.keys(searchIntelFilters).map(k => (
                            <div key={`search_intel_filter_${k}`}>
                                <p><b>{startCase(k)} :</b>  {searchIntelFilters[k]}</p>
                            </div>
                        )) : (<p>None</p>)}
                    </Grid.Column>
                </Grid>

                {!!error && (
                    <Message
                        error
                        content={error}
                        icon="warning sign"
                        header="Could not get settings for this particular search!"
                    />
                )}

                <Divider hidden />

                {snapshots.map((snapshot, i) => (
                    <React.Fragment key={snapshot.id}>
                        <p className="tp-15">
                            <Label
                                as="span"
                                size="mini"
                            >
                                {format(snapshot.timestamp, 'DD MMM, YY HH:mm')}
                            </Label>
                            <b className="lm-5">{snapshot.report}</b>
                            {i > 0 && '(Changed Settings Only)'}

                            {i !== 0 && (
                                <a 
                                    href="/" 
                                    className="fr lm-5"
                                    onClick={(e) => { e.preventDefault(); this.toggleSnapshot(snapshot, i); }}
                                >
                                    <Icon name={`chevron circle ${this.isSnapshotShown(snapshot, i) ? 'up' : 'down'}`} />
                                </a>
                            )}

                            { snapshot.excel && (
                                <Label 
                                    as="span" 
                                    size="mini" 
                                    color="blue" 
                                    className="fr"
                                >
                                    Excel Export
                                </Label>
                            )}
                        </p>
                        <Divider />
                        
                        
                        {this.isSnapshotShown(snapshot, i) && (
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile={16}>
                                        </Grid.Column>
                                        {snapshotPropsToShow(snapshot).map(name => hasChangedSettings(snapshots[i-1], snapshot, name) ? (
                                            <UserComponentSetting
                                                key={name}
                                                isDisabled={true}
                                                onChange={() => null}
                                                setting={{ name, value: snapshot[name], isDisabled: true }}
                                            />
                                        ) : null)}

                                        {hasChangedGeoSettings(snapshots[i - 1], snapshot) && (
                                            <Grid.Column width={6}>
                                                <SettingGeo
                                                    settings={getGeoSettingsFromSnapshot(snapshot)}
                                                    isDisabled={true}
                                                    user={client}
                                                />
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>

                                {hasChangedDateSettings(snapshots[i-1], snapshot) && (
                                    <SettingSalesPeriod 
                                        settings={getDateSettingsFromSnapshot(snapshot)} 
                                        onChange={() => null} 
                                        isDisabled={true}
                                    />
                                )}
                            </Form>
                        )}
                    </React.Fragment>
                ))}
            </Segment>
        );
    };
}

const mapStateToProps = ({ stateSnapshot }) => ({
    isLoading: stateSnapshot.isLoading,
    snapshots: stateSnapshot.snapshots,
    error: stateSnapshot.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    unselect: () => dispatch(selectSearchFromGrid(null)),
    loadStateSnapshots,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerStateSnapshot);