import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Menu } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import UserContainerEditor from '../containers/client-editor';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

import { loadCompanyListOptions } from '../../company/actions';

const UserCreatePage = ({history, loadCompanyListOptions}) => {
    return (
        <Grid>
            <TopNav 
                headerIcon='users'
                subheader='Add new user'
                headerContent='Create User'
                extraButtons={() => (
                    <React.Fragment>
                        <Menu.Item
                            icon="users"
                            name="All Users"
                            onClick={() => history.goBack()}
                        />
                        <ModuleEnvPicker
                            moduleName="user"
                            onChange={() => {
                                loadCompanyListOptions();
                            }}
                        />
                    </React.Fragment>
                )}
            />

            <Grid.Column mobile={16}>
                <UserContainerEditor />
            </Grid.Column>
        </Grid>
    );
};


const mapDispatchToProps = dispatch => bindActionCreators({
    loadCompanyListOptions,
}, dispatch);


export default connect(null, mapDispatchToProps)(UserCreatePage);
