import React from 'react';
import AceEditor from 'react-ace';
import { Segment, Button, Divider, } from 'semantic-ui-react';

const BotComponentLogViewer = ({
    filePath, log, 
    onLoadNextPage, onToggleViewer, onDownloadFile
}) => (
    <Segment vertical>
        <div className="tm-5 fl">
            {filePath}
            {(log && log.count) > 0 && <b> ({log.count} lines)</b>}
        </div>
        <Button.Group
            basic
            size="mini"
            floated="right"
        >
            {(!log || log.isShown) && (
                <Button
                    onClick={() => onLoadNextPage((log && log.page) || 0)}
                    content={log && log.ended ? "EOF" : "Load Page"}
                    loading={log && log.isLoading}
                    disabled={log && log.ended}
                />
            )}
            {!!log && (
                <Button
                    icon={log.isShown ? 'eye slash' : 'eye'}
                    onClick={() => onToggleViewer()}
                />
            )}
            <Button
                icon="download"
                onClick={() => onDownloadFile()}
            />
        </Button.Group>

        <Divider hidden clearing fitted />

        {(log && log.content && log.isShown) && (
            <AceEditor
                tabSize={4}
                mode="text"
                width="100%"
                fontSize={16}
                height="250px"
                theme="twilight"
                name={filePath}
                value={log.content}
                editorProps={{ $blockScrolling: Infinity }}
            />
        )}
    </Segment>
);

export default BotComponentLogViewer;