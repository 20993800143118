import React from 'react';
import { isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import { Form, Input } from 'formik-semantic-ui';

import ProductComponentTypeFilter from './type-filters';
import ProductSizePicker from '../size-picker/container';
import ProductColumnPicker from '../containers/column-picker';
import ProductCategoryPicker from '../category-picker/container';

const ProductComponentEditorForm = ({ 
    handleChange, setFieldValue, setFieldTouched, values, errors, onCancel, ...formProps 
}) => {
    return (
        <>
        <Form.Group>
            <Input
                name="brand"
                fieldProps={{ width: 4 }}
                label="Brand"
            />
            <Input
                name="label"
                fieldProps={{ width: 6 }}
                label="Label"
            />
            <Input
                name="product"
                fieldProps={{ width: 6 }}
                label="Product"
            />
        </Form.Group>

        <Form.Group>
            <Form.Field width={6}>
                <label>Product Type</label>
                <ProductComponentTypeFilter 
                    onSelect={(product_type) => {
                        setFieldValue('product_type', product_type.toUpperCase());
                        setFieldTouched('product_type');
                    }}
                    currentFilter={{ product_type: values.product_type.toLowerCase() }}
                    hideAll={true}
                    selection
                />
            </Form.Field>

            <ProductCategoryPicker 
                product={values} 
                dropdownProps={{ width: 6, clearable: true }}
                onChange={({ category, sub_category }) => {
                    setFieldValue('product_category', category);
                    setFieldValue('product_sub_category', sub_category);
                    setFieldTouched('product_category', 'product_sub_category');
                }}
            />
        </Form.Group>

        <Form.Group>
            <ProductSizePicker
                sizeOnly={true}
                product={values}
                dropdownProps={{ width: 6 }}
                onChange={({ size, group }) => {
                    setFieldValue('product_size', size);
                    setFieldTouched('product_size');

                    if (group) {
                        setFieldValue('product_size_group', group.product_size_group);
                        setFieldTouched('product_size_group');
                    }
                }}
            />

            <Input
                label="Price"
                name="product_price"
                fieldProps={{ width: 4 }}
                inputProps={{ type: 'number' }}
            />
        </Form.Group>

        <Form.Group>
            <ProductColumnPicker
                column="distributor"
                initialValue={values.distributor}
                valueGetter={(entry) => entry.distributor}
                dropdownProps={{ label: 'Distributor', name: 'distributor' }}
                onChange={({ distributor }) => { setFieldTouched('distributor'); setFieldValue('distributor', distributor) }}
            />
            <ProductColumnPicker
                column="supplier"
                initialValue={values.supplier}
                valueGetter={(entry) => entry.supplier}
                dropdownProps={{ label: 'Supplier', name: 'supplier' }}
                onChange={({ supplier }) => { setFieldTouched('supplier'); setFieldValue('supplier', supplier) }}
            />
        </Form.Group>
            
        { values.distributor.toLowerCase() === 'rndc' && (
            <Form.Group>
                <ProductColumnPicker 
                    column="rndc_division"
                    initialValue={values.rndc_division}
                    valueGetter={(entry) => entry.rndc_division}
                    onChange={({rndc_division}) => {setFieldTouched('rndc_division'); setFieldValue('rndc_division', rndc_division)}}
                    dropdownProps={{ 
                        label: 'RNDC Division', 
                        name: 'rndc_division', 
                        width: 6,
                        clearable: true, 
                    }}
                    />
                <ProductColumnPicker
                    column = "rndc_id"
                    initialValue={values.rndc_id}                    
                    valueGetter={(entry) => entry.rndc_id}
                    dropdownProps={{ 
                        width: 4,
                        label: 'RNDC ID', 
                        name: 'rndc_id', 
                        clearable: true,
                        searchInput: 'number',
                    }}
                />
            </Form.Group>
        ) }
        
        <br/>
        <Button
            disabled={isEmpty(formProps.touched)}
            color="teal"
            type="submit"
        >
            Save Product
        </Button>
        <Button
            color="yellow"
            type="button"
            onClick={() => onCancel()}
        >
            Cancel
        </Button>
        </>
    )
};

export default ProductComponentEditorForm;