import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Grid, Menu } from 'semantic-ui-react';

import TopNav from '../containers/top-nav';
import RecentGrid from '../../user/recent/grid';
import SearchGrid from '../../user/search/grid';
import ClientSettingPanel from '../../user/setting/panel';
import ModuleEnvPicker from '../containers/module-env-picker';
import StateSnapshot from '../../user/containers/state-snapshot';

import { loadRecentGridData } from '../../user/recent/actions';
import { selectSearchFromGrid } from '../../user/search/actions';
import { selectClientFromGrid } from '../../user/client-grid/actions';

const CommonHomePage = ({ 
    client, search, minutes,
    selectClient, selectSearch, loadRecentGridData
}) => {
    const hasSelectedClient = (client && client.id);
    const hasSelectedSearch = (search && search.id);

    return (
        <Grid>
            <TopNav
                headerContent='Dashboard'
                headerIcon='tachometer alternate'
                subheader='Welcome to the dash playa'
                extraButtons={() => (
                    <>
                    <Menu.Item
                        icon="cog"
                        color="green"
                        name="Settings" 
                        as={Link}
                        to="/setting"
                    />
                    <ModuleEnvPicker
                        moduleName="user"
                        onChange={() => {
                            selectClient(null);
                            selectSearch(null);
                            loadRecentGridData(minutes);
                        }}
                    />
                    </>
                )} 
                />

            <Grid.Row>
                <Grid.Column mobile={16} className="bp-15">
                    <RecentGrid />
                </Grid.Column>
                
                {hasSelectedClient && (
                    <React.Fragment key={`ClientSetting_${client.id}`}>
                        <Grid.Column mobile={16}>
                            <ClientSettingPanel client={client} />
                        </Grid.Column>
                        <Grid.Column
                            mobile={16}
                            className="hp-15"
                        >
                            <SearchGrid client={client} />
                        </Grid.Column>

                        { hasSelectedSearch && (
                            <Grid.Column mobile={16} key={`StateSnapshot_${client.id}_${search.id}`}>
                                <StateSnapshot search={search} />
                            </Grid.Column>
                        ) }
                    </React.Fragment>
                )}
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = ({ clientGrid, searchGrid, recentGrid }) => ({
    client: clientGrid.selected,
    search: searchGrid.selected,

    minutes: recentGrid.minutes,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadRecentGridData,
    selectClient: (client) => dispatch(selectClientFromGrid(client)),
    selectSearch: (search) => dispatch(selectSearchFromGrid(search)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommonHomePage);