import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header, Form, Grid, Button, Icon, Label } from 'semantic-ui-react';

import { searchGridColumns } from './columns';
import SelectedClientBadge from '../client-grid/selected-client-badge';
import {
    hideSearchGridColumn,
    changeSearchGridState,
    toggleSearchGridControl,
} from './actions';
import GridColumnPicker from '../../common/components/grid-column-picker';
import GridPageSizePicker from '../../common/components/grid-page-size-picker';

const UserContainerSearchGridControl = ({
    pageSize, showControl, hiddenColumns,
    hideSearchGridColumn, toggleSearchGridControl, changeGridState
}) => {
    if (!showControl) {   
        return (
            <Segment vertical clearing>
                <Label 
                    color="grey"
                    icon="search"
                    content="Search History" 
                />
                <SelectedClientBadge />

                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleSearchGridControl}
                >
                    Configure Grid
                </Button>

                <GridPageSizePicker
                    button
                    pointing="top"
                    pageSize={pageSize} 
                    text={`${pageSize}/page`}
                    onChange={changeGridState}
                    className="mini right floated" 
                />
            </Segment>
        );
    }
    
    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name="search"/>
                            <Header.Content>
                                Searches Grid
                                <Header.Subheader>
                                    Select columns, number of entries etc. you want to see in the grid.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleSearchGridControl}
                        >
                            Hide
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form className="tm-15">
                <Form.Group>
                    <Form.Field width={12}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker
                            hiddenColumns={hiddenColumns}
                            onChange={hideSearchGridColumn}
                            columns={searchGridColumns}
                        />
                    </Form.Field>

                    <Form.Field width={4}>
                        <label>Users Per Page</label>

                        <GridPageSizePicker 
                            fluid
                            selection
                            pageSize={pageSize}
                            onChange={changeGridState}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ searchGrid }) => ({
    pageSize: searchGrid.pageSize,
    currentPage: searchGrid.currentPage,
    showControl: searchGrid.showControl,
    shownColumns: searchGrid.shownColumns,
    hiddenColumns: searchGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hideSearchGridColumn, toggleSearchGridControl,
    changeGridState: (state) => dispatch(changeSearchGridState(state)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerSearchGridControl);