import { emptyCheck } from "../services/helpers";
import {
    CHECK_EDITOR_DATA, CHECK_EDITOR_ERROR, CHECK_EDITOR_SAVED, CHECK_EDITOR_SAVING, CHECK_EDITOR_LOADING
} from "../constants";

const initialState = {
    error: null,
    isSaving: false,
    isLoading: false,
    check: emptyCheck,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHECK_EDITOR_SAVED:
            return {
                ...state,
                error: null,
                isSaving: false,
                isLoading: false,
                check: action.payload,
            };

        case CHECK_EDITOR_DATA:
            return {
                ...state,
                check: action.payload || emptyCheck,
            };

        case CHECK_EDITOR_SAVING:
            return {
                ...state,
                isSaving: true,
            };

        case CHECK_EDITOR_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case CHECK_EDITOR_ERROR:
            return {
                ...state,
                isSaving: false,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};