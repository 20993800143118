import { sortBy } from 'lodash';

import { 
    PRODUCT_NORMAL_DATA, PRODUCT_NORMAL_LOADING, PRODUCT_NORMAL_ERROR, 
    PRODUCT_NORMAL_DELETED, PRODUCT_NORMAL_MINIMIZE, 
    PRODUCT_NORMAL_CREATED, PRODUCT_NORMAL_CREATING, PRODUCT_NORMAL_CREATE_ERROR, PRODUCT_NORMAL_SORT 
} from "../constants";
import { generateReducer } from '../../common/helpers';

const initialState = {
    normals: [],
    error: null,
    sortColumn: 'normalized',
    sortOrder: 'ASC',
    isCreating: false,
    createError: null,
    isLoading: false,
    minimized: true,
};

const getSortedNormals = (normals, column, order) => {
    let sorted = sortBy(normals, [column]);

    if (order === 'DESC') {
        sorted = sorted.reverse();
    }

    return sorted;
};

const setData = (state, { rows }) => ({
    ...state,
    error: null,
    isLoading: false,
    normals: getSortedNormals(rows, state.sortColumn, state.sortOrder),
});
const setDeleted = (state, deleted) => ({
    ...state,
    error: null,
    isLoading: false,
    normals: state.normals.filter(({ normalized }) => deleted !== normalized),
});
const setError = (state, error) => ({
    ...state,
    isLoading: false,
    error,
});
const setLoading = (state, ) => ({
    ...state,
    isLoading: true,
});
const setMinimize = (state, minimized) => ({
    ...state,
    minimized,
});
const setCreated = (state, normals) => ({
    ...state,
    isCreating: false,
    createError: null,
    normals: getSortedNormals([normals, ...state.normals], state.sortColumn, state.sortOrder)
});
const setCreateError = (state, error) => ({
    ...state,
    isCreating: false,
    createError: error.messages || error.message,
});
const setCreating = (state, ) => ({
    ...state,
    isCreating: true,
});

const setSort = (state, { order, column }) => {
    const { normals } = state;

    return { 
        ...state, 
        sortColumn: column, sortOrder: order,
        normals: getSortedNormals(normals, column, order), 
    };
};

const reducers = {
    [PRODUCT_NORMAL_DATA]: setData,
    [PRODUCT_NORMAL_DELETED]: setDeleted,
    [PRODUCT_NORMAL_ERROR]: setError,
    [PRODUCT_NORMAL_LOADING]: setLoading,
    [PRODUCT_NORMAL_MINIMIZE]: setMinimize,
    [PRODUCT_NORMAL_CREATED]: setCreated,
    [PRODUCT_NORMAL_CREATE_ERROR]: setCreateError,
    [PRODUCT_NORMAL_CREATING]: setCreating,
    [PRODUCT_NORMAL_SORT]: setSort,
};

export default generateReducer(reducers, initialState);