import { difference } from 'lodash';

import {
    RENEWAL_GRID_DATA, RENEWAL_GRID_ERROR, RENEWAL_GRID_LOADING,
    RENEWAL_GRID_COLUMN_TOGGLE, RENEWAL_GRID_CONTROL_TOGGLE, 
    RENEWAL_GRID_SERVICE_LEVEL, RENEWAL_GRID_STATE_CHANGE,
    COMPANY_EDITOR_SAVED,
    RENEWAL_GRID_COLUMN_SAVED,
} from "../constants";
import { generateReducer } from '../../common/helpers';
import { renewalGridColumns, hiddenRenewalGridColumns } from "./columns";

const initialState = {
    error: null,
    companies: [],
    isLoading: false,
    sortModel: [],
    filterModel: { service_status: { type: "equals", filter: 'active', filterType: 'text' }},   
    lastLoaded: null,
    showControl: false, 
    serviceLevel: 'complete',
    hiddenColumns: hiddenRenewalGridColumns,
    columnDefs: Object.values(renewalGridColumns),
    shownColumns: difference(Object.keys(renewalGridColumns), hiddenRenewalGridColumns),
};

const changeGridState = (state, payload) => ({ ...state, ...payload, });
const toggleColumn = (state, payload) => {
    const hiddenColumns = payload;
    const shownColumns = difference(Object.keys(renewalGridColumns), payload);

    return { ...state, shownColumns, hiddenColumns, };
};

const loadData = (state, payload) => ({
    ...state, error: null, isLoading: false,
    companies: payload.companies, lastLoaded: new Date(),
});

const updateCompany = (state, data) => {
    if (state.companies.length < 1)
        return state;

    const company = data.company ? data.company : data;
    let updated = false;
    let companies = state.companies.map(c => {
        if (c.id === company.id) {
            c = {...c, ...company};
            updated = true;
        }

        return c;
    });

    if (!updated) {
        companies = [company, ...state.companies];
    }

    return {...state, companies};
};

const setError = (state, payload) => ({ ...state, isLoading: false, error: payload, });
const setLoading = (state) => ({ ...state, isLoading: true, });
const toggleControl = (state) => ({ ...state, showControl: !state.showControl });
const setServiceLevel = (state, serviceLevel) => ({ ...state, serviceLevel });

const reducers = {
    [RENEWAL_GRID_DATA]: loadData,
    [RENEWAL_GRID_ERROR]: setError,
    [RENEWAL_GRID_LOADING]: setLoading,
    [RENEWAL_GRID_COLUMN_TOGGLE]: toggleColumn,
    [RENEWAL_GRID_STATE_CHANGE]: changeGridState,
    [RENEWAL_GRID_CONTROL_TOGGLE]: toggleControl,
    [RENEWAL_GRID_SERVICE_LEVEL]: setServiceLevel,

    [COMPANY_EDITOR_SAVED]: updateCompany,
    [RENEWAL_GRID_COLUMN_SAVED]: updateCompany,
};

export default generateReducer(reducers, initialState);