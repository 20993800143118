import React from 'react';

const CommonComponentColoredNumber = ({value}) => {
    let cls = 'text-';

    if (value <= 0)
        cls += 'red';
    if (value > 0)
        cls += 'green';

    return (
        <span className={cls}>{value ? parseFloat(`${value}`).toFixed(2) : '0.00'}</span>
    );
};

export default CommonComponentColoredNumber;