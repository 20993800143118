import {
    USAGE_GRID_DATA, USAGE_GRID_LOADING, USAGE_GRID_ERROR,
    USAGE_GRID_COLUMN_TOGGLE, USAGE_GRID_SELECT_COMPANY, 
    USAGE_GRID_SERVICE_LEVEL, USAGE_GRID_CONTROL_TOGGLE,
    USAGE_GRID_STATE_CHANGE,
} from '../constants';

import { makePrivateApiCall } from '../../common/api';

export const loadUsageGridData = params => (dispatch, getState) => {
    const { moduleEnv } = getState();
    params.env = moduleEnv.company;

    dispatch({
        type: USAGE_GRID_LOADING
    });

    makePrivateApiCall({
        url: '/company/usage',
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            const companies = res.data;

            dispatch({
                type: USAGE_GRID_DATA,
                payload: { companies }
            });
        }
    }).catch(err => {
        dispatch({
            type: USAGE_GRID_ERROR,
            payload: err.message
        });
    });
};

export const selectCompanyFromGrid = (company) => dispatch => dispatch({
    type: USAGE_GRID_SELECT_COMPANY,
    payload: company
});

export const toggleUsageGridControl = () => dispatch => dispatch({
    type: USAGE_GRID_CONTROL_TOGGLE
});

export const hideUsageGridColumn = (columns) => dispatch => {
    dispatch({
        type: USAGE_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const changeUsageGridState = (gridState) => dispatch => dispatch({
    type: USAGE_GRID_STATE_CHANGE,
    payload: gridState
});

export const changeUsageGridServiceLevel = (service = 'complete') => dispatch => {
    dispatch({
        type: USAGE_GRID_SERVICE_LEVEL,
        payload: service
    });

    return dispatch(loadUsageGridData({ service }));
};