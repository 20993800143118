import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'formik-semantic-ui';
import { bindActionCreators } from 'redux';
import { debounce, get, isEqual, capitalize, uniqBy } from 'lodash';

import {
    loadProductSubcategories, selectProductSubcategory
} from './action';

class ProductCategoryPicker extends React.Component {
    state = { searchQuery: '' };

    // on first load, set searchquery from product's actual sub/cat
    componentWillMount() {
        const { product } = this.props;
        const searchQuery = product ? this.valueFromProduct(product) : '';
        this.setState({ searchQuery }, this.loadData);
    };

    // debounce the load data so we don't bomb the api with 100 calls
    loadData = debounce(() => {
        const type = get(this.props, 'product.product_type', null);
        this.props.loadProductSubcategories(this.state.searchQuery, type);
    }, 300);

    valueGetter = (cat) => {
        if (!!this.props.only)
            return cat[this.props.only];

        return `${cat.category} - ${cat.sub_category}`;
    };

    valueFromProduct = (product) => {
        if (!!this.props.only)
            return product[`product_${this.props.only}`];

        return `${product.product_category} - ${product.product_sub_category}`;
    };

    handleSearchInput = (e, {searchQuery}) => {
        this.setState({ searchQuery });
        this.loadData();
    };

    handleSelect = (e, { value }) => {
        if (value === this.valueFromProduct(this.props.product)) {
            return null;
        }

        let selected = { category:'', sub_category: '' };

        if (value) {
            selected = this.props.subcategories.find(g => this.valueGetter(g) === value);
            this.props.selectProductSubcategory(selected);
            this.setState({ searchQuery: this.valueGetter(selected) });
        } else {
            this.setState({ searchQuery: '' });
        }
        
        if (this.props.onChange) {
            this.props.onChange(selected);
        }
    };

    componentDidUpdate(prevProps) {
        const prevType = get(prevProps, 'product.product_type'),
            newType = get(this.props, 'product.product_type');

        if (!isEqual(prevType, newType)) {
            this.loadData();
        }
    };

    render() {
        const {
            isLoading, dropdownProps={}, only,
            subcategories, error,
        } = this.props;

        const options = uniqBy(subcategories.map(sub => ({
            key: sub.sub_category_id + this.valueGetter(sub), text: this.valueGetter(sub),
            value: this.valueGetter(sub), description: sub.type,
        })), 'value');

        return (
            <Form.Dropdown
                fluid
                search
                selection
                width={6}
                error={!!error}
                options={options}
                loading={isLoading}
                onChange={this.handleSelect}
                searchQuery={this.state.searchQuery}
                onSearchChange={this.handleSearchInput}
                label={only ? capitalize(only) : "Category-Subcategory"}
                {...dropdownProps}
            />
        );
    };
};

const mapStateToProps = ({ productCategoryPicker }) => productCategoryPicker;

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductSubcategories,
    selectProductSubcategory
}, dispatch);

ProductCategoryPicker.propTypes = {
    onChange: PropTypes.func,
    dropdownProps: PropTypes.object,
    product: PropTypes.object.isRequired,
    only: PropTypes.oneOf(['category', 'sub_category']),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryPicker);

