import React from 'react';
import { Form } from 'semantic-ui-react';

import ProductUpcPicker from './container';

export default class ProductUpcPickerAgGrid extends React.Component {
    state = {
        upc: '',
    };

    componentWillMount () {
        this.setUpc(this.props.value);
    };

    getValue() {
        return this.state.upc;
    };

    isPopup() {
        return true;
    };

    setUpc = (upc) => {
        this.setState({ upc });
    };

    handleChange = ({ upc }) => {
        this.setState({ upc }, () => this.props.api.stopEditing());
    };

    render() {
        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <ProductUpcPicker
                        selectedUpc = { this.state.upc }
                        dropdownProps={{ width: 16 }}
                        onChange={this.handleChange}
                    />
                </Form>
            </div>
        );
    }
}