import {
    CLIENT_SEARCH_GRID_SELECT_SEARCH,
    CLIENT_SEARCH_GRID_DATA, CLIENT_SEARCH_GRID_LOADING, CLIENT_SEARCH_GRID_ERROR,
    CLIENT_SEARCH_GRID_COLUMN_TOGGLE, CLIENT_SEARCH_GRID_PAGE_CHANGE, CLIENT_SEARCH_GRID_STATE_CHANGE, CLIENT_SEARCH_GRID_CONTROL_TOGGLE,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';

export const loadSearchGridPage = (client, state, grid) => (dispatch, getState) => {
    let { lastRow, sortModel, filterModel } = state;
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_SEARCH_GRID_LOADING
    });

    const params = {
        env: moduleEnv.user,
        endRow: grid.endRow,
        filterModel, sortModel,
        startRow: grid.startRow,
    };

    makePrivateApiCall({
        url: `/user/client/${client.id}/searches`,
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            const searches = res.data.rows;
            lastRow = res.data.count;

            grid.successCallback(searches, lastRow);

            dispatch({
                type: CLIENT_SEARCH_GRID_DATA,
                payload: { searches, lastRow }
            });
        }
    }).catch(err => {
        grid.failCallback(err);
        dispatch({
            type: CLIENT_SEARCH_GRID_ERROR,
            payload: err.message
        });
    });
};

export const toggleSearchGridControl = () => dispatch => dispatch({
    type: CLIENT_SEARCH_GRID_CONTROL_TOGGLE
});

export const changeSearchGridPage = (page) => {
    return {
        type: CLIENT_SEARCH_GRID_PAGE_CHANGE,
        payload: page
    };
};

export const changeSearchGridState = (gridState) => {
    return {
        type: CLIENT_SEARCH_GRID_STATE_CHANGE,
        payload: gridState
    };
};

export const selectSearchFromGrid = (client) => {
    return {
        type: CLIENT_SEARCH_GRID_SELECT_SEARCH,
        payload: client
    };
};

export const hideSearchGridColumn = (columns) => dispatch => {
    dispatch({
        type: CLIENT_SEARCH_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};