import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

import SearchGrid from '../search/grid';
import ClientGrid from '../client-grid/grid';
import ClientSettingPanel from '../setting/panel';
import TopNav from '../../common/containers/top-nav';
import StateSnapshot from '../containers/state-snapshot';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

import { selectSearchFromGrid } from '../search/actions';
import { selectClientFromGrid } from '../client-grid/actions';

const CreateUserButton = ({ createUser, changeEnv }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="add user"
                name="Create User"
                onClick={createUser}
            />
            <ModuleEnvPicker 
                moduleName="user"
                onChange={changeEnv}
            />
        </React.Fragment>
    );
};

const UserHomePage = ({ 
    client, search, history,
    selectClient, selectSearch, 
}) => {
    const hasSelectedClient = (client && client.id);
    const hasSelectedSearch = (search && search.id);
    let clientGrid = React.createRef();

    return (
        <Grid>
            <TopNav 
                headerContent='Users'
                headerIcon='users'
                subheader='Home to ydk app clients'
                extraButtons={() => <CreateUserButton 
                    createUser={() => history.push('/user/create')} 
                    changeEnv={() => {
                        selectClient(null);
                        selectSearch(null);
                        clientGrid.wrappedInstance.reloadGridData();
                    }} 
                />}
            />

            <Grid.Column mobile={16}>
                <ClientGrid 
                    ref={(ref) => clientGrid = ref}
                />
            </Grid.Column>

            { hasSelectedClient && (
                <Grid.Column mobile={16} key={`ClientSetting_${client.id}`}>
                    <ClientSettingPanel client={ client }/>
                </Grid.Column>
            ) }

            { hasSelectedClient && (
                <Grid.Column 
                    mobile={16} 
                    className="hp-15" 
                    key={`SearchGrid_${client.id}`}
                >
                    <SearchGrid client={ client }/>
                </Grid.Column>
            ) }

            { (hasSelectedClient && hasSelectedSearch) && (
                <Grid.Column mobile={16} key={`StateSnapshot_${client.id}_${search.id}`}>
                    <StateSnapshot search={search} client={client} />
                </Grid.Column>
            ) }
        </Grid>
    );
};

const mapDispatchToProps = dispatch => bindActionCreators({
    selectClient: (client) => dispatch(selectClientFromGrid(client)),
    selectSearch: (search) => dispatch(selectSearchFromGrid(search)),
}, dispatch);

const mapStateToProps = ({ clientGrid, searchGrid }) => ({
    client: clientGrid.selected,
    search: searchGrid.selected,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserHomePage));