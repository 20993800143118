
import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { loadRenewalGridData } from '../renewal/actions';
import { loadAgreementGridData } from '../agreement/actions';
import { loadUsageGridData, selectCompanyFromGrid } from '../usage/actions';

import TopNav from '../../common/containers/top-nav';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

const Buttons = ({ 
    history, usageServiceLevel, renewalServiceLevel,
    loadRenewalGridData, loadUsageGridData, selectCompanyFromGrid, loadAgreementGridData
}) => {
    let isUsage = history.location.pathname.indexOf('/usage') >= 0,
        isAgreement = history.location.pathname.indexOf('/agreements') >= 0,
        url = isUsage ? 'renewals' : 'usage',
        icon = isUsage ? 'dollar sign' : 'chart line',
        name = isUsage ? 'Renewals' : 'Usage';

    return (
        <React.Fragment>
            <Menu.Item
                icon="plus"
                color="green"
                name="Company"
                onClick={() => history.push(`/company/create`)}
            />
            <Menu.Item
                icon="file"
                color="green"
                name="Agreements"
                onClick={() => history.push(`/company/agreements`)}
            />
            <Menu.Item
                icon={icon}
                name={name}
                color="green"
                onClick={() => history.push(`/company/${url}`)}
            />
            <ModuleEnvPicker 
                moduleName="company"
                onChange={() => {
                    if (isUsage) {
                        loadUsageGridData({ service: usageServiceLevel });
                        selectCompanyFromGrid(null);
                    } else if (isAgreement) {
                        loadAgreementGridData();
                    } else {
                        loadRenewalGridData({ service: renewalServiceLevel });
                        selectCompanyFromGrid(null);
                    }
                }}
            />
        </React.Fragment>
    );
};

const mapStateToProps = ({ companyRenewalGrid, companyUsageGrid }) => ({
    usageServiceLevel: companyUsageGrid.serviceLevel,
    renewalServiceLevel: companyRenewalGrid.serviceLevel,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUsageGridData, loadRenewalGridData, selectCompanyFromGrid,
    loadAgreementGridData,
}, dispatch);

const ExtraCompanyButtons = connect(mapStateToProps, mapDispatchToProps)(withRouter(Buttons));

export default () => (
    <TopNav
        headerIcon='briefcase'
        headerContent='Companies'
        subheader={`Manage company renewals and monitor usage.`}
        extraButtons={() => <ExtraCompanyButtons />} />
);