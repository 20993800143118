export const CLIENT_GRID_CONFIRMING_CLIENT_CANCEL = 'CLIENT_GRID_CONFIRMING_CLIENT_CANCEL';
export const CLIENT_GRID_CANCELLING_CLIENT = 'CLIENT_GRID_CANCELLING_CLIENT';
export const CLIENT_GRID_CANCELLED_CLIENT = 'CLIENT_GRID_CANCELLED_CLIENT';

export const CLIENT_GRID_COLUMN_SAVING = 'CLIENT_GRID_COLUMN_SAVING';
export const CLIENT_GRID_COLUMN_SAVED = 'CLIENT_GRID_COLUMN_SAVED';
export const CLIENT_GRID_COLUMN_ERROR = 'CLIENT_GRID_COLUMN_ERROR';

export const CLIENT_GRID_SEARCHED_WITHIN_FILTER = 'CLIENT_GRID_SEARCHED_WITHIN_FILTER';
export const CLIENT_GRID_COLUMN_CHANGE_STATE = 'CLIENT_GRID_COLUMN_CHANGE_STATE';
export const CLIENT_GRID_CONTROL_TOGGLE = 'CLIENT_GRID_CONTROL_TOGGLE';
export const CLIENT_GRID_SELECT_CLIENT = 'CLIENT_GRID_SELECT_CLIENT';
export const CLIENT_GRID_COLUMN_TOGGLE = 'CLIENT_GRID_COLUMN_TOGGLE';
export const CLIENT_GRID_STATE_CHANGE = 'CLIENT_GRID_STATE_CHANGE';
export const CLIENT_GRID_PAGE_CHANGE = 'CLIENT_GRID_PAGE_CHANGE';
export const CLIENT_GRID_LOADING = 'CLIENT_GRID_LOADING';
export const CLIENT_GRID_ERROR = 'CLIENT_GRID_ERROR';
export const CLIENT_GRID_DATA = 'CLIENT_GRID_DATA';

export const CLIENT_SEARCH_GRID_CONTROL_TOGGLE = 'CLIENT_SEARCH_GRID_CONTROL_TOGGLE';
export const CLIENT_SEARCH_GRID_SELECT_SEARCH = 'CLIENT_SEARCH_GRID_SELECT_SEARCH';
export const CLIENT_SEARCH_GRID_COLUMN_TOGGLE = 'CLIENT_SEARCH_GRID_COLUMN_TOGGLE';
export const CLIENT_SEARCH_GRID_STATE_CHANGE = 'CLIENT_SEARCH_GRID_STATE_CHANGE';
export const CLIENT_SEARCH_GRID_PAGE_CHANGE = 'CLIENT_SEARCH_GRID_PAGE_CHANGE';
export const CLIENT_SEARCH_GRID_LOADING = 'CLIENT_SEARCH_GRID_LOADING';
export const CLIENT_SEARCH_GRID_ERROR = 'CLIENT_SEARCH_GRID_ERROR';
export const CLIENT_SEARCH_GRID_DATA = 'CLIENT_SEARCH_GRID_DATA';

export const RECENT_GRID_CONTROL_TOGGLE = 'RECENT_GRID_CONTROL_TOGGLE';
export const RECENT_GRID_COLUMN_TOGGLE = 'RECENT_GRID_COLUMN_TOGGLE';
export const RECENT_GRID_MINUTES = 'RECENT_GRID_MINUTES';
export const RECENT_GRID_LOADING = 'RECENT_GRID_LOADING';
export const RECENT_GRID_ERROR = 'RECENT_GRID_ERROR';
export const RECENT_GRID_COUNT = 'RECENT_GRID_COUNT';
export const RECENT_GRID_DATA = 'RECENT_GRID_DATA';

export const CLIENT_SETTING_LOADING = 'CLIENT_SETTING_LOADING';
export const CLIENT_SETTING_UPDATED = 'CLIENT_SETTING_UPDATED';
export const CLIENT_SETTING_ERROR = 'CLIENT_SETTING_ERROR';
export const CLIENT_SETTING_DATA = 'CLIENT_SETTING_DATA';

export const STATE_SNAPSHOT_LOADING = 'STATE_SNAPSHOT_LOADING';
export const STATE_SNAPSHOT_ERROR = 'STATE_SNAPSHOT_ERROR';
export const STATE_SNAPSHOT_DATA = 'STATE_SNAPSHOT_DATA';

export const CLIENT_EDITOR_CLIENT_LOADING = 'CLIENT_EDITOR_CLIENT_LOADING';
export const CLIENT_EDITOR_CLIENT_RESET = 'CLIENT_EDITOR_CLIENT_RESET';
export const CLIENT_EDITOR_CLIENT_ERROR = 'CLIENT_EDITOR_CLIENT_ERROR';
export const CLIENT_EDITOR_CLIENT_DATA = 'CLIENT_EDITOR_CLIENT_DATA';
export const CLIENT_EDITOR_CONFIRMING = 'CLIENT_EDITOR_CONFIRMING';
export const CLIENT_EDITOR_SAVING = 'CLIENT_EDITOR_SAVING';
export const CLIENT_EDITOR_INPUT = 'CLIENT_EDITOR_INPUT';
export const CLIENT_EDITOR_ERROR = 'CLIENT_EDITOR_ERROR';
export const CLIENT_EDITOR_SAVED = 'CLIENT_EDITOR_SAVED';

export const SHOW_BY = 'show_by';
export const COVERAGE = 'coverage';
export const GEO_TYPE = 'geo_type';
export const GEO_NAME = 'geo_name';
export const ALCOHOL_TYPE = 'alc_type';
export const PREMISE_TYPE = 'off_type';
export const DISPLAY_TYPE = 'display_by_unit';
export const SERVICE_PREMISES = 'service_premises';
export const SERVICE_PRODUCT_TYPE = 'service_product_type';

export const SALES_PERIOD_END = 'sales_period_end';
export const SALES_PERIOD_TYPE = 'sales_period_type';
export const SALES_PERIOD_START = 'sales_period_start';
export const COMPARE_PERIOD_END = 'compare_period_end';
export const COMPARE_PERIOD_START = 'compare_period_start';

export const WEEKLY_PERIOD_END = 'weekly_period_end';
export const WEEKLY_PERIOD_START = 'weekly_period_start';
export const WEEKLY_COMPARE_PERIOD_END = 'weekly_compare_end';
export const WEEKLY_COMPARE_PERIOD_START = 'weekly_compare_start';


export const UserSettingKeys = {
    GEO_TYPE,
    GEO_NAME,
    SHOW_BY,
    COVERAGE,
    ALCOHOL_TYPE,
    PREMISE_TYPE,
    DISPLAY_TYPE,
    SERVICE_PREMISES,
    SALES_PERIOD_END,
    WEEKLY_PERIOD_END,
    SALES_PERIOD_TYPE,
    COMPARE_PERIOD_END,
    SALES_PERIOD_START,
    WEEKLY_PERIOD_START,
    COMPARE_PERIOD_START,
    SERVICE_PRODUCT_TYPE,
    WEEKLY_COMPARE_PERIOD_END,
    WEEKLY_COMPARE_PERIOD_START,
};

export const UserSettingIgnoreKeys = ['order_by', 'results_per_page', 'order_dir', 'show_by', 'geo', 'service_premise'];

export const UserSettingAllowedValues = {
    'SERVICE_PREMISES': ['On', 'Off'],
    'PREMISE_TYPE': ['0', '1', '2', '3'],
    'GEO_TYPE': ["region", "state", "city"],
    'SHOW_BY': ["Brand", "Product", "Label"],
    'DISPLAY_TYPE': ['Cases', 'Units', 'Sales'],
    'COVERAGE': ["all", "full", "partial", "weekly"],
    'SERVICE_PRODUCT_TYPE': ['Liquor', 'Wine', 'Beer', 'N/A', 'Other'],
    'ALCOHOL_TYPE': ['All Bev', 'Liquor', 'Wine', 'Beer'],
    'SALES_PERIOD_TYPE': ['12Months', 'YTD', 'Custom', 'CurrentMonth'],
};

export const UserSettingSalesPeriods = {
    'YTD': 'Year To Date',
    'CurrentMonth': 'Current Month',
    '12Months': 'Rolling 12 Months',
    'Custom': 'Custom Period',
};

export const UserSettingPremiseTypes = [
    'Retail Only',
    'Wholesale Only',
    'Retail & Wholesale (Mix)',
    'All Accounts',
];

export const UserSubscriptionProducts = {
    da: 1,
    lite: 4,
    intel: 10,
    unlimited: 11,
    marketslice: 20
};

export const UserSubscriptionStatuses = {
    DEMO: 'Demo',
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    CANCELLED: 'Cancelled',
    SUSPENDED: 'Suspended',
    PAUSED: 'Paused',
    ONBOARDING: 'Onboarding',
};

export const UserRndcDivisions = {
    DIAMOND: 'Diamond',
    EAGLE: 'Eagle',
    FALCON: 'Falcon',
    HAWK: 'Hawk',
    PLATINUM: 'Platinum',
    RAVEN: 'Raven',
    VINTAGE: 'Vintage'
};