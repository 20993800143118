import React from 'react';
import { Form } from 'semantic-ui-react';

import ProductColumnPicker from './column-picker';

export default class ProductColumnPickerAgGrid extends React.Component {
    state = {
        value: '',
    };

    componentWillMount () {
        this.setType(this.props.value);
    };

    getValue() {
        return this.state.value;
    };

    isPopup() {
        return true;
    };

    setType = (value) => {
        this.setState({ value });
    };

    handleChange = (value) => {
        this.setState({ value }, () => this.props.api.stopEditing());
    };

    render() {
        const { column } = this.props;

        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <Form.Field>
                        <label>Select {column.colDef.headerName}</label>
                        <ProductColumnPicker 
                            column={column.colId}
                            dropdownProps={{ width: 16 }}
                            initialValue={ this.state.value }
                            valueGetter={(entry) => entry[column.colId]}
                            onChange={(data) => this.handleChange(data[column.colId])}
                        />
                    </Form.Field>
                </Form>
            </div>
        );
    }
}