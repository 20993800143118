import {
    COMPANY_LIST_LOADING, COMPANY_LIST_SET_OPTIONS, COMPANY_LIST_SET_ERROR, COMPANY_LIST_SELECT_OPTION
} from "../constants";
import { makePrivateApiCall } from '../../common/api';

export const loadCompanyListOptions = () => (dispatch, getState) => {
    const {moduleEnv} = getState();
    
    dispatch({
        type: COMPANY_LIST_LOADING
    });

    makePrivateApiCall({
        url: '/company/selectables',
        method: 'get', params: { env: moduleEnv.company },
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: COMPANY_LIST_SET_OPTIONS,
                payload: res.data
            });
        }
    }).catch(err => {
        dispatch({
            type: COMPANY_LIST_SET_ERROR,
            payload: err.message
        });
    });
};

export const selectCompanyListOption = (companyId) => dispatch => {
    dispatch({
        type: COMPANY_LIST_SELECT_OPTION,
        payload: companyId
    });
};