import React from 'react';
import { connect } from 'react-redux';
import { keys, chunk, isArray, isObject } from 'lodash';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Menu, Message, Segment, Header, Table } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import CompanyContainerEditor from '../containers/editor';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

import { loadCompanyForEdit, resetEditingCompany } from '../actions';

const ListCompanyButton = withRouter(({ history }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="briefcase"
                name="All Companies"
                onClick={() => history.goBack()}
            />
            <ModuleEnvPicker 
                moduleName="company" 
                menuProps={{ disabled: true }}
            />
        </React.Fragment>
    );
});

const printRoninObject = roninObject => Object.keys(roninObject).map(key => `${key}: ${roninObject[key]}`).join(' | ')

const parseRoninDetails = details => {
    if (isArray(details)) {
        return details.map(detail => {
            if (isObject(detail)) {
                if (detail.name) return detail.name;
                return printRoninObject(detail);
            }

            return detail;
        }).join(', ');
    }
    if (isObject(details)) return printRoninObject(details);
    return details;
};

const RoninDetails = ({ ronin }) => {
    const columns = keys(ronin),
        items = chunk(columns, Math.round(columns.length/2));
    return (
        <Segment className="tp-15">
            <Header
                icon="coffee"
                content="Ronin Details"
            />

            <Table basic="very" striped>
                <Table.Body>
                    {items[0].map((key, i) => console.log(ronin, items) || (
                        <Table.Row key={key}>
                            <Table.Cell>
                                {key}
                            </Table.Cell>
                            <Table.Cell>
                                {ronin[key]}
                            </Table.Cell>

                            <Table.Cell>
                                {items[1][i]}
                            </Table.Cell>
                            <Table.Cell>
                                { parseRoninDetails(ronin[items[1][i]]) }
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
}

class CompanyEditPage extends React.Component {
    componentWillMount() {
        const { computedMatch } = this.props;
        this.props.loadCompanyForEdit(computedMatch.params.companyId);
    };

    componentWillUnmount() {
        this.props.resetEditingCompany();
    };

    render() {
        const { error, company, computedMatch, } = this.props;
        return (
            <Grid>
                <TopNav
                    headerIcon='briefcase'
                    subheader='Update existing company details'
                    headerContent='Edit Company'
                    extraButtons={() => <ListCompanyButton />}
                />

                <Grid.Column mobile={16}>
                    {error && (
                        <Message
                            error
                            content={error}
                            header={`Error loading company #${computedMatch.params.companyId}`}
                        />
                    )}
                    <CompanyContainerEditor />
                    
                    {!!company && !!company.ronin && (
                        <RoninDetails ronin={company.ronin}/>
                    )}
                </Grid.Column>
            </Grid>
        );
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    loadCompanyForEdit, resetEditingCompany,
}, dispatch);

const mapStateToProps = ({ companyEditor }) => ({
    error: companyEditor.companyError,
    company: companyEditor.company,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditPage);