import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

import CheckContainerEdit from '../containers/editor';
import TopNav from '../../common/containers/top-nav';

const CheckCreatePage = ({ history }) => {
    return (
        <Grid>
            <TopNav
                headerContent='Add New Check'
                headerIcon='check circle outline'
                subheader={`Create a new check with ample details of how it works and why it's needed`}
                extraButtons={() => (
                    <React.Fragment>
                        <Menu.Item
                            icon="delete"
                            name="Cancel"
                            onClick={() => history.push('/check')}
                        />
                        <Menu.Item
                            name="Reset"
                            icon="sync alternate"
                            onClick={() => history.push('/check')}
                        />
                    </React.Fragment>
                )}
            />

            <Grid.Row>
                <Grid.Column mobile={16} >
                    <CheckContainerEdit />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default withRouter(CheckCreatePage);