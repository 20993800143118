import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'formik-semantic-ui';

import { makePrivateApiCall } from '../../common/api';

class ProductColumnPicker extends React.Component {
    state = {
        loading: false,
        error: null,
        data: [],
    };

    getValues = () => this.props.data[this.props.column];

    loadData = async (keyword) => {
        this.setState({ loading: true });

        try {
            const res = await makePrivateApiCall({
                url: `/product/distinct-column-values/${this.props.column}`,
                method: 'get', params: { keyword }
            });

            if (res.data) {
                return this.setState({ data: res.data, loading: false });
            }
        } catch (err) {
            this.setState({ error: err.message, loading: false });
        }
    };

    componentDidMount() {
        this.loadData(this.props.initialValue || '');
    };

    handleSearchInput = debounce((e, { searchQuery }) => {
        // console.log(e, data);
        this.loadData(searchQuery);
    }, 300);

    handleSelect = (e, { value }) => {
        const { valueGetter, onChange } = this.props;
        const selected = this.state.data.find(item => valueGetter(item) === value);

        if (onChange) {
            onChange(selected);
        }
    };

    render() {
        const {
            dropdownProps = {}, valueGetter, initialValue,
        } = this.props;

        const options = this.state.data.map(item => ({
            key: valueGetter(item), text: valueGetter(item),
            value: valueGetter(item),
        }));

        return (
            <Form.Dropdown
                fluid
                search
                selection
                width={6}
                options={options}
                value={initialValue}
                error={!!this.state.error}
                loading={this.state.loading}
                onChange={this.handleSelect}
                defaultSearchQuery={`${initialValue}`}
                onSearchChange={this.handleSearchInput}
                {...dropdownProps}
            />
        );
    };
};

ProductColumnPicker.propTypes = {
    onChange: PropTypes.func,
    column: PropTypes.string.isRequired,
    valueGetter: PropTypes.func.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProductColumnPicker;

