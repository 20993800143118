import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const GridColumnPicker = ({ columns, hiddenColumns, onChange, ...others }) => (
    <Dropdown
        fluid
        multiple
        selection
        value={hiddenColumns}
        placeholder='Hide Columns'
        onChange={(e, data) => onChange(data.value)}
        // disabled={Object.values(columns).length-1 === hiddenColumns.length}
        options={Object.values(columns).map(c => ({ 
            key: c.field,
            value: c.field, 
            text: c.headerName 
        }))}
        {...others}
    />
);

GridColumnPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    columns: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GridColumnPicker;