import React from 'react';
import { Grid } from 'semantic-ui-react';

import CompanyContainerAgreementGrid from '../agreement/grid';
import CompanyComponentTopBar from '../containers/top-bar';

const CompanyAgreementsPage = () => {
    return (
        <Grid>
            <CompanyComponentTopBar />
            <Grid.Row>
                <Grid.Column mobile={16} >
                    <CompanyContainerAgreementGrid />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CompanyAgreementsPage;