import React from "react";
import { Label } from "semantic-ui-react";

const CompanyComponentServicesGridCell = ({ value }) => {
    if (!value)
        return null;

    const colors = {
        liquor: 'brown',
        wine: 'purple',
        on: 'olive',
        off: 'blue',
    };

    return (
        <Label.Group
            size = "mini" 
        >
            {value.split(',').map(sp => (
                <Label 
                    basic
                    key={sp}
                    content={sp}
                    color={colors[sp.toLowerCase()]} 
                />
            ))}
        </Label.Group>
    );
};

export default CompanyComponentServicesGridCell;