import Joi from 'joi-browser';

import { makePublicApiCall, makePrivateApiCall } from '../../common/api';
import { setAuthToken, setAuthUser } from "./helpers";

export const validateSigninData = (credentials) => {
    const schema = {
        email: Joi.string().email().required(),
        password: Joi.string().required(),
    };

    return Joi.validate(credentials, schema, {abortEarly: false});
};

export const validateProfileData = (profile={}) => {
    const schema = {
        color: Joi.string().length(7).trim().optional(),
        title: Joi.string().min(3).trim().optional(),
        name: Joi.string().min(3).trim().optional(),
        email: Joi.string().email().optional(),
    };

    return Joi.validate(profile, schema, {
        stripUnknown: true,
        abortEarly: false,
        convert: true,
    });
};

export const validateSignupData = (credentials) => {
    const schema = {
        name: Joi.string().required(),
        title: Joi.string().required(),
        password: Joi.string().required(),
        email: Joi.string().email().required(),
        confirm_password: Joi.string().required().valid(Joi.ref('password')).options({
            language: {
                any: {
                    allowOnly: '!!Passwords do not match',
                }
            }
        }),
    };

    return Joi.validate(credentials, schema, {abortEarly: false});
};

export const signin = (credentials) => {
    return makePublicApiCall({
        url: 'auth/signin',
        method: 'post',
        data: credentials
    }).then(res => {
        setAuthToken(res.data.access_token);
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const signup = (credentials) => {
    return makePublicApiCall({
        url: 'auth/signup',
        method: 'post',
        data: credentials
    }).then(res => {
        setAuthToken(res.data.access_token);
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const signout = () => {
    setAuthToken(null);
    setAuthUser(null);

    return makePrivateApiCall({
        url: 'signout',
        method: 'post',
    });
};

export const validateToken = () => {
    return makePrivateApiCall({
        url: 'validate_token',
        method: 'get'
    }).then(res => {
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const updateProfile = (data={}) => {
    return makePrivateApiCall({
        url: 'profile',
        method: 'put', data
    }).then(res => {
        setAuthUser(res.data.user);
        return res.data.user;
    });
};