import React from 'react';
import { debounce } from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown } from 'semantic-ui-react';

import { 
    loadProductUpcs, selectProductUpc,
} from './action';


class ProductUpcPicker extends React.Component {
    componentWillMount () {
        this.props.loadProductUpcs(this.props.selectedUpc);
    };

    handleSearchInput = debounce((e, { searchQuery }) => {
        // console.log(e, data);
        this.props.loadProductUpcs(searchQuery);
    }, 300);

    handleSelect = (e, { value }) => {
        const { upcs, selectedUpc, onChange, selectProductUpc } = this.props;
        const selected = upcs.find(g => g.upc === value);
        
        // if selection is being made twice, just ignore
        if (selected && selected.upc === selectedUpc) {
            return;
        }

        selectProductUpc(selected);

        if (onChange) {
            onChange(selected);
        }
    };

    render () {
        const {
            error, isLoading, dropdownProps, upcs, selected,
        } = this.props;
        
        const options = upcs.map(upc => ({
            key: upc.upc, text: upc.upc,
            value: upc.upc, description: pluralize('prod', upc.total_products, true),
        }));

        return (
            <Dropdown
                search
                selection
                error={!!error}
                options={options}
                loading={isLoading}
                selectOnBlur={false}
                onChange={this.handleSelect}
                value={selected ? selected.upc : ''}
                onSearchChange={this.handleSearchInput}
                defaultSearchQuery={selected ? selected.upc : ''}
                {...dropdownProps}
            />
        );
    };
};

const mapStateToProps = ({ productUpcPicker }) => productUpcPicker;

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductUpcs, selectProductUpc,
}, dispatch);

ProductUpcPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    dropdownProps: PropTypes.object,
    product: PropTypes.object,
    upcOnly: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpcPicker);

