import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form } from 'formik-semantic-ui';
import { Segment, Header } from 'semantic-ui-react';

import { authUpdateProfile } from '../actions';
import { validateProfileData } from '../services/api';

const AuthContainerProfileEditor = ({ 
    name, email, title, profileUpdating, 
    authUpdateProfile 
}) => {
    const formSchema = {
        email: {
            label: 'Email Address',
            value: email,
            type: 'email'
        },
        name: {
            label: 'Name',
            value: name,
        },
        title: {
            label: 'Title',
            value: title
        }
    };

    const handleSubmit = async (profileData, formikApi) => {            
        try {
            const profile = await validateProfileData(profileData);
            await authUpdateProfile(profile);
            formikApi.setSubmitting(false);
        } catch (err) {
            err.details.forEach(errDet => {
                formikApi.setFieldError(errDet.context.key, errDet.message);
            });
            formikApi.setSubmitting(false);
        }
    };
    
    return (
        <Segment>
            <Header
                content="Update your account"
            />
            <Form
                schema={formSchema}
                onSubmit={handleSubmit}
                isSubmitting={profileUpdating}
            >
                <Button.Submit>Save</Button.Submit>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ auth }) => ({
    email: auth.user.email,
    name: auth.user.name,
    title: auth.user.title,
    profileUpdating: auth.profileUpdating,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authUpdateProfile
}, dispatch);

AuthContainerProfileEditor.propTypes = {
    dropdownProps: PropTypes.object,
    editable: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainerProfileEditor);