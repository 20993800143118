import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import { 
    PRODUCT_UNTANGLER_CONFIRMING, PRODUCT_UNTANGLER_UNTANGLING,
    PRODUCT_UNTANGLER_SET_INPUT, PRODUCT_UNTANGLER_COMPLETE,
    PRODUCT_UNTANGLER_ERROR ,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { apiCallError } from '../../common/actions';
import { makePrivateApiCall } from '../../common/api';

export const setProductUntanglerInput = (field, value) => dispatch => {
    dispatch({
        type: PRODUCT_UNTANGLER_SET_INPUT,
        payload: {[field]: value},
    });
};

export const setProductUntanglerError = (error) => dispatch => {
    return dispatch({
        type: PRODUCT_UNTANGLER_ERROR,
        payload: error
    });
};

export const setProductUntanglerConfirming = (confirming) => dispatch => {
    return dispatch({
        type: PRODUCT_UNTANGLER_CONFIRMING,
        payload: !!confirming
    });
};

export const confirmProductUntangle = (inputs={}) => dispatch => {
    dispatch({
        type: PRODUCT_UNTANGLER_UNTANGLING,
        payload: true,
    });

    return makePrivateApiCall({
        url: `/product/untangle`,
        method: 'post',
        data: inputs,
    }).then(({ data }) => {
        if (data && data.success) {
            toast({
                time: 5000,
                icon: 'random',
                type: 'success',
                title: 'Product untangled',
                description: `Product ${data.data.tangled_product_id} untangled to ${data.data.true_product_id}.`,
            });

            return dispatch({
                type: PRODUCT_UNTANGLER_COMPLETE,
                payload: data
            });
        }

        return dispatch(setProductUntanglerError("Could not queue untangle on server :("));
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        if (err.response) {
            const message = get(err, 'response.data.message', 'Something went wrong');
            return dispatch(setProductUntanglerError(message));
        }

        return dispatch(apiCallError(err));
    });
};