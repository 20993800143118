import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Menu, Message } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import UserContainerEditor from '../containers/client-editor';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

import { loadClientForEdit, resetEditingClient } from '../actions';

const ListUserButton = withRouter(({ history }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="users"
                name="All Users"
                onClick={() => history.goBack()}
            />
            <ModuleEnvPicker 
                moduleName="user" 
                menuProps={{ disabled: true }}
            />
        </React.Fragment>
    );
});

class UserEditPage extends React.Component {
    componentWillMount() {
        const { computedMatch } = this.props;
        this.props.loadClientForEdit(computedMatch.params.userId);
    };

    componentWillUnmount() {
        this.props.resetEditingClient();
    };
    
    render () {
        const { error, computedMatch, } = this.props;
        return (
            <Grid>
                <TopNav
                    headerIcon='users'
                    subheader='Update existing user details'
                    headerContent='Edit User'
                    extraButtons={() => <ListUserButton />}
                />

                <Grid.Column mobile={16}>
                    {error && (
                        <Message
                            error
                            content={error}
                            header={`Error loading client #${computedMatch.params.userId}`}
                        />
                    )}
                    <UserContainerEditor />
                </Grid.Column>
            </Grid>
        );
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    loadClientForEdit, resetEditingClient,
}, dispatch);

const mapStateToProps = ({ clientEditor }) => ({
    error: clientEditor.clientError
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);