import { omit } from 'lodash';

import {
    PRODUCT_QUEENIFIER_QUEUE_CONFIRMING,
    PRODUCT_QUEENIFIER_SET_QUEEN,
    PRODUCT_QUEENIFIER_SET_PAWN,
    PRODUCT_QUEENIFIER_QUEUEING,
    PRODUCT_QUEENIFIER_QUEUED,
    PRODUCT_QUEENIFIER_QUEUE_ERROR,
    PRODUCT_QUEENIFIER_MINIMIZE,
    PRODUCT_QUEENIFIER_MAXIMIZE,
} from '../constants';

export const initialState = {
    queen: '', pawn: '', error: null, minimized: true,
    isQueueing: false, isConfirming: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_QUEENIFIER_MINIMIZE:
            return { ...state, minimized: true };

        case PRODUCT_QUEENIFIER_MAXIMIZE:
            return { ...state, minimized: false };

        case PRODUCT_QUEENIFIER_SET_QUEEN:
            return { ...state, queen: action.payload };

        case PRODUCT_QUEENIFIER_SET_PAWN:
            return { ...state, pawn: action.payload };

        case PRODUCT_QUEENIFIER_QUEUEING:
            return { ...state, isQueueing: !!action.payload };

        case PRODUCT_QUEENIFIER_QUEUE_CONFIRMING:
            return { ...state, isConfirming: !!action.payload };

        // when queueing is done, reset everything to initial state except for minimized cause we want to keep the panel open
        case PRODUCT_QUEENIFIER_QUEUED:
            return {...state, ...omit(initialState, ['minimized'])};

        case PRODUCT_QUEENIFIER_QUEUE_ERROR:
            return { ...state, isQueueing: false, error: action.payload };

        default:
            return state;
    }
};