import axios from "axios";

import { getAuthToken } from '../auth/services/helpers';

// const API_URL = process.env.REACT_APP_YDK_BACK_WEB_API_URL || 'http://localhost:8080/api';
const API_URL = process.env.REACT_APP_YDK_BACK_WEB_API_URL || 'https://backapi.ydr.ink/api';

export const makePrivateApiCall = (config) => {
    const params = { 
        url:'/',
        method:'get', 
        baseURL: API_URL + '/v1', 
        responseType: 'json',
        headers: { 'Authorization': getAuthToken() },
        ...config
    };

    return axios(params);
};

export const makePublicApiCall = (config) => {
    const params = {
        url: '/',
        method: 'get',
        baseURL: API_URL,
        responseType: 'json',
        ...config
    };

    return axios(params);
};