import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';

import { UserSettingKeys } from '../../constants';
import { getLocaleColumns, getGeoOptions } from '../helpers';

class SettingGeo extends React.Component {
    state = {
        name: '',
        type: '',
        nameQuery: '',
        nameOptions: [],
        nameSetting: null,
        typeSetting: null,
        loadingNames: true,
        typeOptions: getLocaleColumns({}).map(l => ({ key: l, text: l, value: l })),
    };

    loadOptions = debounce(() => {
        if (!this.props.user)
            return;

        this.setState({loadingNames: true});
        getGeoOptions(this.props.user.id, this.state.type, this.state.nameQuery, this.props.env).then(options => {
            this.setState({ loadingNames: false });
            this.setState({
                nameOptions: options.map(n => ({key: n, text: n, value: n}))
            });
        });
    }, 300);

    syncOptions() {
        const { settings, user } = this.props;
        const typeOptions = getLocaleColumns(user).map(l => ({ key: l, text: l, value: l }));
        
        let name = '', type = '', nameSetting = null, typeSetting = null;

        settings.forEach(setting => {
            if (UserSettingKeys.GEO_NAME === setting.name) {
                name = setting.value;
                nameSetting = setting;
            }
            if (UserSettingKeys.GEO_TYPE === setting.name) {
                type = setting.value;
                typeSetting = setting;
            }
        });

        this.setState({ name, type, nameSetting, typeOptions, typeSetting, nameQuery: name }, () => this.loadOptions());
    };

    setType = (type='') => {
        if (type === this.state.type)
            return;

        this.setState({ type, name: '' }, () => this.loadOptions());
    };

    setName = (name='') => {
        this.setState({ name, nameQuery: name }, () => {
            const { nameSetting, typeSetting, type } = this.state;
            this.props.onChange([{...nameSetting, value: name}, {...typeSetting, value: type}]);
        });
    };

    setNameQuery = (nameQuery='') => {
        this.setState({ nameQuery }, () => this.loadOptions());
    };

    componentDidMount() {
        this.syncOptions();
    };

    componentDidUpdate(prevProps) {
        const q = ({ name }) => (name === UserSettingKeys.GEO_NAME),
            prev = prevProps.settings.find(q),
            now = this.props.settings.find(q);

        if (prev && now && prev.id !== now.id) {
            this.syncOptions();
        }
    };

    render () {
        const { isDisabled = false } = this.props;
        const { name, nameQuery, type, typeOptions, nameOptions, loadingNames } = this.state;
                
        return (
            <Form.Group disabled={isDisabled}>
                <Form.Field 
                    width={6}
                    disabled={isDisabled}
                >
                    <label>Geo Type</label>
                    <Dropdown
                        fluid
                        selection
                        value={type}
                        selectOnBlur={false}
                        options={typeOptions}
                        onChange={(e, data) => this.setType(data.value)}   
                    />
                </Form.Field>
                <Form.Field 
                    width={10}
                    disabled={isDisabled}
                >
                    <label>Geo</label>
                    <Dropdown
                        fluid
                        search
                        selection
                        value={name}
                        selectOnBlur={false}
                        options={nameOptions}
                        loading={loadingNames}
                        searchQuery={nameQuery}
                        onChange={(e, data) => this.setName(data.value)}   
                        onSearchChange={(e, data) => this.setNameQuery(data.searchQuery)}
                    />
                </Form.Field>
            </Form.Group>
        );
    };
};

SettingGeo.propTypes = {
    settings: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    env: PropTypes.string,
};

export default SettingGeo;