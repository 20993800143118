import { toast } from 'react-semantic-toasts';

import {
    CLIENT_SETTING_DATA, CLIENT_SETTING_ERROR, CLIENT_SETTING_LOADING, CLIENT_SETTING_UPDATED,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';

export const loadClientSettings = (clientId) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_SETTING_LOADING
    });

    makePrivateApiCall({
        url: `/user/client/${clientId}/settings`,
        method: 'get', params: {env: moduleEnv.user}
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: CLIENT_SETTING_DATA,
                payload: { settings: res.data }
            });
        }
    }).catch(err => {
        dispatch({
            type: CLIENT_SETTING_ERROR,
            payload: err.message
        });
    });
};

export const changeClientSetting = (settingId, value) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_SETTING_LOADING
    });
    
    makePrivateApiCall({
        url: `/user/setting/${settingId}`,
        method: 'put', data: { value }, params: {env: moduleEnv.user}
    }).then((res) => {
        if (res.data) {
            toast({
                time: 3000,
                icon: 'cog',
                type: res.data.error ? 'info' : 'success',
                title: res.data.error ? 'Not Updated' : 'Setting Updated',
                description: res.data.error ? res.data.message : `Successfully updated setting to ${value}`,
            });

            dispatch({
                type: CLIENT_SETTING_UPDATED,
                payload: { id: settingId, value }
            });
        }
    }).catch(err => {
        dispatch({
            type: CLIENT_SETTING_ERROR,
            payload: err.message
        });
    });
};