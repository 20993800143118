import React from 'react';
import { connect } from 'react-redux';
import { Grid, Card, Button } from 'semantic-ui-react';

import { persistor } from '../../store';
import TopNav from '../../common/containers/top-nav';
import AuthContainerProfileColor from '../../auth/containers/profile-color';
import AuthContainerProfileEditor from '../../auth/containers/profile-editor';

const CommonHomePage = ({ user }) => {
    return (
        <Grid>
            <TopNav
                headerIcon='cog'
                headerContent='Settings'
                subheader='Manage your preferences' />

            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={5} className="bp-15">
                    <Card fluid>
                        <Card.Content>
                            <AuthContainerProfileColor 
                                buttonProps={{ floated: 'right' }}
                                editable={true}
                            />
                            <Card.Header>{user.name}</Card.Header>
                            <Card.Meta>{user.title}</Card.Meta>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {user.email}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Card.Description>
                                If you're noticing any anomalies or not seeing something you expect to see, clear your cached data first and try again.
                                <br/><br/>
                                All your local settings and preferences will be reset.
                            </Card.Description>
                            <br/>
                            <Button 
                                fluid 
                                color="orange"
                                onClick={() => {
                                    persistor.purge().then(() => window.location.reload());
                                }}>
                                Clear Cached Data
                            </Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                    <AuthContainerProfileEditor />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(CommonHomePage);