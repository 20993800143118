import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import {
    RENEWAL_GRID_DATA, RENEWAL_GRID_LOADING, RENEWAL_GRID_ERROR,
    RENEWAL_GRID_COLUMN_TOGGLE,
    RENEWAL_GRID_CONTROL_TOGGLE,
    RENEWAL_GRID_SERVICE_LEVEL,
    RENEWAL_GRID_STATE_CHANGE,
    RENEWAL_GRID_COLUMN_SAVING,
    RENEWAL_GRID_COLUMN_SAVED,
    RENEWAL_GRID_COLUMN_ERROR,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { makePrivateApiCall } from '../../common/api';

export const loadRenewalGridData = (params) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    params.env = moduleEnv.company;

    dispatch({
        type: RENEWAL_GRID_LOADING
    });

    makePrivateApiCall({
        url: '/company/renewal',
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: RENEWAL_GRID_DATA,
                payload: { companies: res.data.rows }
            });
        }
    }).catch(err => {
        dispatch({
            type: RENEWAL_GRID_ERROR,
            payload: err.message
        });
    });
};

export const hideRenewalGridColumn = (columns) => dispatch => {
    dispatch({
        type: RENEWAL_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const toggleRenewalGridControl = () => dispatch => dispatch({
    type: RENEWAL_GRID_CONTROL_TOGGLE
});

export const changeRenewalGridState = (gridState) => dispatch => dispatch({
    type: RENEWAL_GRID_STATE_CHANGE,
    payload: gridState
});

export const changeRenewalGridServiceLevel = (service = 'complete') => dispatch => {
    dispatch({
        type: RENEWAL_GRID_SERVICE_LEVEL,
        payload: service
    });
    
    return dispatch(loadRenewalGridData({ service }));
};

export const saveRenewalGridColumn = (companyId, company = {}) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: RENEWAL_GRID_COLUMN_SAVING,
        payload: true,
    });

    return makePrivateApiCall({
        url: `/company/${companyId}`,
        method: 'put', data: company,
        params: { env: moduleEnv.company },
    }).then(res => {
        if (res.data) {
            if (!res.data.updated) {
                toast({
                    type: 'info',
                    icon: 'info',
                    title: 'Update not needed',
                    description: 'Nothing was changed in the database',
                });
            } else {
                toast({
                    type: 'success',
                    icon: 'checkmark',
                    title: 'Upated Company',
                    description: 'Company column has been updated',
                });
                return dispatch({
                    type: RENEWAL_GRID_COLUMN_SAVED,
                    payload: res.data
                });
            }
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: RENEWAL_GRID_COLUMN_ERROR,
            payload: err.message
        });

        toast({
            type: 'error',
            icon: 'close',
            title: 'Upate Error',
            description: get(err, 'response.data.message', null) || err.message,
        });
    });
};