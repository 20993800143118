import { isBefore, isAfter } from 'date-fns';

export function dateFilterComparator(filterDate, cellValue) {
    if (isBefore(cellValue, filterDate)) {
        return -1;
    } else if (isAfter(cellValue, filterDate)) {
        return 1;
    } else {
        return 0;
    }
};

// given a set of reducer map with keys referring to the action type and values are functions that handle the reduce action
// this function will return a reducer function that can be linked to redux store
export const generateReducer = (reducers, initialState) => {
    return (state = initialState, action) => {
        if (action.type in reducers) {
            return reducers[action.type](state, action.payload);
        }

        return state;
    };
};

export const getEnvColor = (env) => {
    if (env === 'production') {
        return 'purple';
    }

    return 'blue';
};

export const downloadFileFromBlob = (blob, filename) => {
    const url = URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout(() => link.parentNode.removeChild(link), 1000);
};