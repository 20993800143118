import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

import { activityMiddlewareLogger } from './activity/middleware';
import auth from "./auth/reducers";
import botList from "./bot/reducers";
import activity from "./activity/reducer";
import { nav, moduleEnv } from "./common/reducers";
import { checkList, checkEditor } from "./check/reducers";

import {
    reportList,
    reportStats,
} from './report/reducers';

import companyUsageGrid from './company/usage/reducer';
import companyRenewalGrid from './company/renewal/reducer';
import companyUserGrid from './company/usage/user-reducer';
import companyAgreementGrid from './company/agreement/reducer';
import { companyList, companyEditor } from "./company/reducers";

import searchGrid from "./user/search/reducer";
import recentGrid from "./user/recent/reducer";
import clientSetting from "./user/setting/reducer";
import clientGrid from "./user/client-grid/reducer";
import { stateSnapshot, clientEditor } from "./user/reducers";

import { 
    productEditor, productGrid, productUpdate, productUntangler, 
} from "./product/reducers";
import productUpc from './product/upc/reducer';
import productNormal from './product/normal/reducer';
import productMatchGrid from './product/match/reducer';
import productUpcPicker from './product/upc-picker/reducer';
import productQueenifier from './product/queenifier/reducer';
import productSizePicker from './product/size-picker/reducer';
import productCategoryPicker from './product/category-picker/reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();
const rootReducer = combineReducers({
    auth,
    botList,
    activity,
    nav, moduleEnv,
    checkList, checkEditor,
    reportList, reportStats,
    productMatchGrid, productQueenifier, productUntangler, productEditor, 
    clientGrid, recentGrid, searchGrid, clientSetting, stateSnapshot, clientEditor,
    companyUsageGrid, companyRenewalGrid, companyUserGrid, companyList, companyEditor, companyAgreementGrid,
    productGrid, productUpc, productUpdate, productNormal, productSizePicker, productUpcPicker, productCategoryPicker,
});
const persistedReducer = persistReducer({
    key: 'root', storage,
    whitelist: [
        "nav", "moduleEnv", "botList", "productGrid", "activity",
        "companyUsageGrid", "companyRenewalGrid",
        "searchGrid", "companyUserGrid", 
        "reportList", "reportStats",
    ]
}, rootReducer);

export const store = createStore(
    connectRouter(history)(persistedReducer),
    composeEnhancers(applyMiddleware(
        thunk,
        routerMiddleware(history), 
        activityMiddlewareLogger,
    )),
);

export const persistor = persistStore(store);