import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEqual } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';

import { loadAgreementGridData } from './actions';

import BrowserGridCell from '../../user/components/browser-grid-cell';
import ActionGridCell from './action-grid-cell';

const frameworkComponents = {
    browserGridCell: BrowserGridCell,
    actionGridCell: ActionGridCell,
};

class RenewalGrid extends React.Component {
    grid = null;

    componentDidMount() {
        const { loadAgreementGridData } = this.props;
        loadAgreementGridData();
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }
        
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeRenewalGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeRenewalGridState({ filterModel: this.grid.api.getFilterModel() });
        });
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render() {
        const { agreements } = this.props;

        return (
            <div>
                <div className="ag-theme-balham">
                    <AgGridReact
                        animateRows
                        rowHeight={32}
                        rowData={agreements}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        onSelectionChanged={this.selectCompany}
                        frameworkComponents={frameworkComponents}
                    />
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ companyAgreementGrid }) => ({
    error: companyAgreementGrid.error,
    agreements: companyAgreementGrid.agreements,
    isLoading: companyAgreementGrid.isLoading,
    sortModel: companyAgreementGrid.sortModel,
    columnDefs: companyAgreementGrid.columnDefs,
    lastLoaded: companyAgreementGrid.lastLoaded,
    filterModel: companyAgreementGrid.filterModel,
    shownColumns: companyAgreementGrid.shownColumns,
    hiddenColumns: companyAgreementGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    push,
    loadAgreementGridData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RenewalGrid);