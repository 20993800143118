import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import CompanyContainerUsageGrid from '../usage/grid';
import CompanyContainerUserGrid from '../usage/user-grid';
import CompanyComponentTopBar from '../containers/top-bar';

const CompanyUsagePage = ({ selectedCompany }) => {
    return (
        <Grid>
            <CompanyComponentTopBar />
            <Grid.Row>
                <Grid.Column mobile={16} >
                    <CompanyContainerUsageGrid />
                </Grid.Column>

                {selectedCompany && (
                    <Grid.Column 
                        mobile={16}
                        key={`userUsageGrid_${selectedCompany.id}`}
                    >
                        <CompanyContainerUserGrid />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

export default connect(({ companyUsageGrid }) => ({
    selectedCompany: companyUsageGrid.selected
}))(CompanyUsagePage);