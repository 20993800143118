import React from 'react';
import { Container, Header, Button, Icon } from 'semantic-ui-react';

export default () => (
    <Container text>
        <Header
            as='h1'
            content='yDashboard'/>
        <Header
            as='h2'
            content='A bit lost, are we?.'/>
        <Button primary size='huge' as='a' href="/">
            Show me the way
            <Icon name='right arrow' />
        </Button>
    </Container>
);