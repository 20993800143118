import { toast } from 'react-semantic-toasts';

import {
    PRODUCT_UPC_DELETED, PRODUCT_UPC_MINIMIZE, PRODUCT_UPC_VARIATIONS_DELETED, PRODUCT_UPC_DELETING,
    PRODUCT_UPC_CREATING, PRODUCT_UPC_CREATED, PRODUCT_UPC_CREATE_ERROR,
    PRODUCT_UPC_DATA, PRODUCT_UPC_LOADING, PRODUCT_UPC_ERROR, PRODUCT_UPC_SORT, 
} from '../constants';

import { makePrivateApiCall } from '../../common/api';
import { apiCallError } from '../../common/actions';
import { authExpire } from '../../auth/actions';

export const loadUpcsForProduct = (productId) => dispatch => {
    dispatch({
        type: PRODUCT_UPC_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/upc`,
        method: 'get',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPC_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_UPC_ERROR,
            payload: err.message
        });
    });
};

export const minimizeUpcList = (minimize = true) => dispatch => {
    return dispatch({
        type: PRODUCT_UPC_MINIMIZE,
        payload: minimize
    });
};

export const createUpcForProduct = (productId, upc) => dispatch => {
    dispatch({
        type: PRODUCT_UPC_CREATING
    });

    makePrivateApiCall({
        url: `/product/${productId}/upc`,
        method: 'post',
        data: {
            productId, upc
        },
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPC_CREATED,
                payload: res.data
            });
        }
    }).catch(err => {

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        if (err.response.data) {
            return dispatch({
                type: PRODUCT_UPC_CREATE_ERROR,
                payload: err.response.data
            });
        }

        dispatch(apiCallError(err));
    });
};

export const deleteUpcVariationsForProduct = (productId, upc) => dispatch => {
    dispatch({
        type: PRODUCT_UPC_DELETING
    });

    makePrivateApiCall({
        url: `/product/${productId}/upc/${upc}/variations`,
        method: 'delete',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPC_VARIATIONS_DELETED,
                payload: res.data
            });

            toast({
                time: 3000,
                type: 'success',
                icon: 'trash alternate',
                title: 'UPC variations removed',
                description: `Successfully removed ${res.data.length} upcs from product`,
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_UPC_ERROR,
            payload: err.message
        });
    });
};

export const deleteUpcForProduct = (productId, upc) => dispatch => {
    dispatch({
        type: PRODUCT_UPC_LOADING
    });

    makePrivateApiCall({
        url: `/product/${productId}/upc/${upc}`,
        method: 'delete',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPC_DELETED,
                payload: upc
            });

            toast({
                time: 3000,
                type: 'success',
                icon: 'trash alternate',
                title: 'UPC removed',
                description: `Successfully removed upc ${upc} from product`,
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_UPC_ERROR,
            payload: err.message
        });
    });
};

export const sortUpcs = (column, order) => dispatch => dispatch({
    type: PRODUCT_UPC_SORT,
    payload: {column, order}
});