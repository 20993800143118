import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import {
    PRODUCT_MATCH_GRID_DATA, PRODUCT_MATCH_GRID_LOADING, PRODUCT_MATCH_GRID_ERROR,
    PRODUCT_MATCH_GRID_COLUMN_TOGGLE, PRODUCT_MATCH_GRID_PAGE_CHANGE,
    PRODUCT_MATCH_GRID_STATE_CHANGE, PRODUCT_MATCH_GRID_SELECT_MATCH, PRODUCT_MATCH_GRID_COLUMN_SAVING, 
    PRODUCT_MATCH_GRID_COLUMN_ERROR, PRODUCT_MATCH_GRID_COLUMN_SAVED,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { loadUpdatesForProduct } from '../actions';
import { loadUpcsForProduct } from '../upc/actions';
import { makePrivateApiCall } from '../../common/api';
import { loadNormalsForProduct } from '../normal/actions';
import { setProductUntanglerInput } from '../actions/untangler';

export const loadProductMatchGridPage = (productId, state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;

    dispatch({
        type: PRODUCT_MATCH_GRID_LOADING
    });

    const params = {
        endRow: grid.endRow,
        filterModel, sortModel,
        startRow: grid.startRow,
    };

    makePrivateApiCall({
        url: `/product/${productId}/match/grid`,
        method: 'get', params,
    }).then(res => {
        if (res.data) {
            const products = res.data.rows;
            lastRow = res.data.count;

            grid.successCallback(products, lastRow);

            dispatch({
                type: PRODUCT_MATCH_GRID_DATA,
                payload: { products, lastRow }
            });
        }
    }).catch(err => {
        grid.failCallback(err);

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_MATCH_GRID_ERROR,
            payload: err.message
        });
    });
};

export const selectProductMatchFromGrid = (match) => (dispatch, getState) => {
    if (match && match.product_id) {
        dispatch(setProductUntanglerInput('productSize', match.product_size));
        dispatch(setProductUntanglerInput('tangledUpc', match.upc));
        dispatch(setProductUntanglerInput('tangledProductId', match.product_id));
        dispatch(setProductUntanglerInput('matchingPhrase', match.source));

        // if the match being selected does not have the same product id, that means we need to renew the sidepanel
        if (get(getState(), 'productMatchGrid.selected.product_id', null) !== get(match, 'product_id', null)) {
            dispatch(loadUpdatesForProduct(match.product_id));
            dispatch(loadNormalsForProduct(match.product_id));
            dispatch(loadUpcsForProduct(match.product_id));
        }
    }

    dispatch({
        type: PRODUCT_MATCH_GRID_SELECT_MATCH,
        payload: match
    });

    return;
};

export const changeProductMatchGridPage = (page) => {
    return {
        type: PRODUCT_MATCH_GRID_PAGE_CHANGE,
        payload: page
    };
};

export const changeProductMatchGridState = (gridState) => {
    return {
        type: PRODUCT_MATCH_GRID_STATE_CHANGE,
        payload: gridState
    };
};

export const hideProductMatchGridColumn = (columns) => dispatch => {
    dispatch({
        type: PRODUCT_MATCH_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const saveMatchGridColumn = (matchId, match = {}) => dispatch => {
    dispatch({
        type: PRODUCT_MATCH_GRID_COLUMN_SAVING,
        payload: true,
    });

    makePrivateApiCall({
        url: `/product/match/${matchId}`,
        method: 'put', data: match,
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_MATCH_GRID_COLUMN_SAVED,
                payload: res.data
            });

            const toastParams = {
                type: 'success', icon: 'checkmark',
                title: 'Upated Match',
                description: 'Match entry has been updated',
            };

            if (!res.data.updated) {
                toastParams.icon = 'info';
                toastParams.type = 'info';
                toastParams.title = 'Update not needed';
                toastParams.description = 'Nothing was changed in the database';
            }

            toast(toastParams);
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_MATCH_GRID_COLUMN_ERROR,
            payload: err.message
        });

        toast({
            type: 'error', icon: 'close',
            title: 'Upate Error',
            description: get(err, 'response.data.message', null) || err.message,
        });
    });
};