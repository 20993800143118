import { 
    AUTH_SIGNIN_REQUEST, AUTH_SIGNIN_SUCCESS, 
    AUTH_SIGNIN_INPUT_CHANGE, AUTH_SIGNIN_INPUT_ERROR, 
    AUTH_SIGNUP_REQUEST, AUTH_SIGNUP_SUCCESS, 
    AUTH_SIGNUP_INPUT_CHANGE, AUTH_SIGNUP_INPUT_ERROR, 
    AUTH_RESET_INPUT_CHANGE, AUTH_TOGGLE_PASSWORD_FIELD,
    AUTH_SIGNOUT, AUTH_EXPIRE, 
    AUTH_PROFILE_UPDATE_ERROR, AUTH_PROFILE_UPDATING, AUTH_PROFILE_UPDATED
} from "../constants";
import { signin, signup, validateSigninData, validateToken, signout, validateSignupData, validateProfileData, updateProfile } from '../services/api';
import { apiCallError } from '../../common/actions';
import { persistor } from '../../store';


export const authSignin = (credentials) => dispatch => {
    const { error, value } = validateSigninData(credentials);

    if (error) {
        const payload = {};
        error.details.forEach(e => payload[e.path[0]] = e.message);
        return dispatch({
            type: AUTH_SIGNIN_INPUT_ERROR,
            payload
        });
    }

    dispatch({
        type: AUTH_SIGNIN_REQUEST,
    });

    signin(value).then(result => {
        dispatch({
            type: AUTH_SIGNIN_SUCCESS,
            payload: result
        });
    }).catch(err => {
        if (err.response && err.response.status === 400) {
            return dispatch({
                type: AUTH_SIGNIN_INPUT_ERROR,
                payload: {password: err.response.data.message}
            });
        } else {
            dispatch({
                type: AUTH_SIGNIN_INPUT_ERROR,
                payload: { password: "our servers broke, please try again later" }
            });
            return dispatch(apiCallError(err));
        }

    });
};

export const authSignup = (credentials) => dispatch => {
    const { error, value } = validateSignupData(credentials);

    if (error) {
        const payload = {};
        error.details.forEach(e => payload[e.path[0]] = e.message);
        return dispatch({
            type: AUTH_SIGNUP_INPUT_ERROR,
            payload
        });
    }

    dispatch({
        type: AUTH_SIGNUP_REQUEST,
    });

    signup(value).then(result => {
        dispatch({
            type: AUTH_SIGNUP_SUCCESS,
            payload: result
        });
    }).catch(err => {
        if (err.response.status === 400) {
            return dispatch({
                type: AUTH_SIGNUP_INPUT_ERROR,
                payload: { password: err.response.data.message }
            });
        }

        dispatch(apiCallError(err));
    });
};

export const authValidate = () => dispatch => {
    dispatch({
        type: AUTH_SIGNIN_REQUEST,
    });

    validateToken().then(user => {
        dispatch({
            type: AUTH_SIGNIN_SUCCESS,
            payload: user
        });
    }).catch(err => {
        return dispatch({
            type: AUTH_SIGNOUT
        });
    });
};

export const authSigninInputChange = (field, value) => dispatch => {
    dispatch({
        type: AUTH_SIGNIN_INPUT_CHANGE,
        payload: {[field]: value},
    });
};

export const authSignupInputChange = (field, value) => dispatch => {
    dispatch({
        type: AUTH_SIGNUP_INPUT_CHANGE,
        payload: {[field]: value},
    });
};

export const authResetInputChange = (field, value) => dispatch => {
    dispatch({
        type: AUTH_RESET_INPUT_CHANGE,
        payload: {[field]: value},
    });
};

export const authSignout = () => dispatch => {
    return signout()
        .then(
            () => dispatch({ type: AUTH_SIGNOUT }), 
            () => dispatch({ type: AUTH_SIGNOUT })
        )
        .then(() => setTimeout(() => persistor.purge(), 500));
};

export const authTogglePasswordField = () => dispatch => dispatch({
    type: AUTH_TOGGLE_PASSWORD_FIELD
});

export const authExpire = () => ({
    type: AUTH_EXPIRE
});

export const authUpdateProfile = (profile) => dispatch => {
    const { error, value } = validateProfileData(profile);

    if (error) {
        const payload = {};
        error.details.forEach(e => payload[e.path[0]] = e.message);
        return dispatch({
            type: AUTH_PROFILE_UPDATE_ERROR,
            payload
        });
    }

    dispatch({
        type: AUTH_PROFILE_UPDATING,
        payload: true,
    });

    return updateProfile(value).then(user => dispatch({
        type: AUTH_PROFILE_UPDATED,
        payload: user,
    })).catch(err => {
        dispatch(apiCallError(err));
    });
};