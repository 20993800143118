import { difference } from 'lodash';

import {
    COMPANY_USER_GRID_DATA, COMPANY_USER_GRID_ERROR, COMPANY_USER_GRID_LOADING,
    COMPANY_USER_GRID_COLUMN_TOGGLE,
    COMPANY_USER_GRID_CONTROL_TOGGLE,
} from "../constants";
import { generateReducer } from '../../common/helpers';
import { companyUserGridColumns, hiddenCompanyUserGridColumns } from "./columns";

const initialState = {
    users: [],
    error: null,
    selected: null,
    isLoading: true,
    showControl: false,
    serviceLevel: 'complete',
    hiddenColumns: hiddenCompanyUserGridColumns,
    columnDefs: Object.values(companyUserGridColumns),
    shownColumns: difference(Object.keys(companyUserGridColumns), hiddenCompanyUserGridColumns),
};

const setData = (state, { users }) => ({ ...state, error: null, isLoading: false, users });
const setLoading = (state) => ({ ...state, isLoading: true });
const setError = (state, error) => ({ ...state, error });

const toggleColumn = (state, hiddenColumns) => {
    const shownColumns = difference(Object.keys(companyUserGridColumns), hiddenColumns);
    return { ...state, shownColumns, hiddenColumns, };
};
const toggleControl = (state) => ({ ...state, showControl: !state.showControl });

const reducers = {
    [COMPANY_USER_GRID_DATA]: setData,
    [COMPANY_USER_GRID_ERROR]: setError,
    [COMPANY_USER_GRID_LOADING]: setLoading,

    [COMPANY_USER_GRID_COLUMN_TOGGLE]: toggleColumn,
    [COMPANY_USER_GRID_CONTROL_TOGGLE]: toggleControl,
};

export default generateReducer(reducers, initialState);