import { connect } from 'react-redux';
import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { bindActionCreators } from 'redux';

import { downloadAgreementImage } from './actions';

class ActionGridCell extends Component {
    state = {
        value: null,
        downloadingImage: false,
    };

    componentDidMount() {
        this.setState({ value: this.props.value });
    };

    refresh(params) {
        if (params.value !== this.state.value) {
            this.setState({
                value: params.value
            })
        }

        return true;
    };

    handleDownloadImage = () => {
        this.setState({downloadingImage: true});
        this.props.downloadAgreementImage(this.props.data).then(() => {
            this.setState({downloadingImage: false});
        });
    };

    render() {
        const { data } = this.props;
        
        return (
            <span title={this.state.value}>
                <Icon 
                    className="clickable"
                    onClick={this.handleDownloadImage}
                    loading={this.state.downloadingImage}
                    name={ this.state.downloadingImage ? "spinner" : "file"} 
                />
            </span>
        );
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    downloadAgreementImage,
}, dispatch);

export default connect(null, mapDispatchToProps)(ActionGridCell);