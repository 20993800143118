import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Header, Menu, Confirm } from 'semantic-ui-react';

import { authSignout } from '../../auth/actions';
import { toggleSideNav, minimizeSideNav, maximizeSideNav } from '../actions';

class TopNav extends React.Component {
    state = {
        showSignoutConfirm: false,
    };
    
    showSignoutConfirm = () => this.setState({ showSignoutConfirm: true });
    handleSignoutCancel = () => this.setState({ showSignoutConfirm: false });
    
    handleSignoutConfirm = () => {
        this.setState({ showSignoutConfirm: false }, () => this.props.authSignout());
    };

    render () {
        return (
            <Grid.Row className="page-topbar">
                <Confirm
                    onConfirm={this.handleSignoutConfirm}
                    open={this.state.showSignoutConfirm}
                    onCancel={this.handleSignoutCancel}
                    content='Sure you want to logout?'
                    confirmButton='Log Me Out'
                    cancelButton='Nevermind'
                />

                <Grid.Column mobile={16} computer={8}>
                    <div className="hp-15 sp-15">
                        <Header 
                            as='h3'
                            icon={this.props.headerIcon}
                            subheader={this.props.subheader}
                            onClick={this.props.toggleSideNav}
                            content={this.props.headerContent}
                        />
                    </div>
                </Grid.Column>

                <Grid.Column mobile={16} computer={8}>
                    <Menu secondary floated="right" className="tp-10">
                        {this.props.extraButtons && this.props.extraButtons()}
                        {/* <Menu.Item 
                            onClick={this.showSignoutConfirm}
                            icon="bell"/> */}
                        <Menu.Item 
                            onClick={this.showSignoutConfirm}
                            icon="sign out" 
                            name="logout" />
                    </Menu>
                </Grid.Column>
            </Grid.Row>
        );
    };
};

TopNav.propTypes = {
    headerContent: PropTypes.string.isRequired,
    headerIcon: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    authSignout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    extraButtons: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authSignout, toggleSideNav, minimizeSideNav, maximizeSideNav
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));