import React from 'react';
import { format } from 'date-fns';
import { Header, List, Table } from 'semantic-ui-react';
import { DATETIME_FORMAT } from '../../common/constants';

const headerMap = {
    unopenedEmails: "Received but didn't open",
    openedButNotDownloadedReports: "Opened but didn't download file",
};

const eventTsToShowMap = {
    unopenedEmails: 'deliveryEvent',
    openedButNotDownloadedReports: 'openEvent',
};

const eventTsHeaderMap = {
    unopenedEmails: 'Received',
    openedButNotDownloadedReports: 'Opened',
};

const ReportComponentUnopenedList = ({
    emails,
    listType = 'unopenedEmails',
}) => {
    const header = headerMap[listType];
    const eventTsToShow = eventTsToShowMap[listType];
    const eventTsHeader = eventTsHeaderMap[listType];

    return (
    <>
        <Header 
            size="small" 
            content={header}
        />

        <Table compact size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>
                        {eventTsHeader}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Reports</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {emails.map(email => (
                    <Table.Row
                        key={`unopened_list_item_${email.id}`}
                    >
                        <Table.Cell>
                            {email.email}
                        </Table.Cell>

                        <Table.Cell collapsing>
                            {
                                email[eventTsToShow] 
                                    ? format(new Date(email[eventTsToShow].timestamp), DATETIME_FORMAT)
                                    : null
                            }
                        </Table.Cell>

                        <Table.Cell>
                            <List
                                items={email.reportDefs.map(def => `#${def.id} ${ def.description }`)}
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </>
    );
};

export default ReportComponentUnopenedList;