import Joi from 'joi-browser';

export const validateCheckCreate = async (check) => {
    const schema = {
        check_id: Joi.number().optional(),
        server: Joi.string().required(),
        author_email: Joi.string().email().required(),
        description: Joi.string().required(),
        note: Joi.string().required(),
        check_query: Joi.string().required(),
        resolve_instructions: Joi.string().required(),
    };

    return Joi.validate(check, schema, {
        stripUnknown: true,
    });
};