import React from 'react';
import { connect } from 'react-redux';
import Clipboard from 'react-clipboard.js';
import { bindActionCreators } from 'redux';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router-dom';
import { Label, Icon, Button } from 'semantic-ui-react';

import { selectProductFromGrid } from '../actions';

const SelectedProductBadge = ({ 
    product, history, 
    unselect, onUnselect 
}) => {
    if (!product)
        return (
            <Label disabled>
                No Product Selected
            </Label>
        );

    return (
        <>
        <Label color="violet">
            <Clipboard
                component="span" 
                data-clipboard-text={product.product_id}
                onSuccess={data => {
                    toast({ 
                        icon: 'copy outline', 
                        title: `Copied to clipboard`, 
                        description: `Product Id ${data.text} has been copied` 
                    });
                }}
            >
                #{product.product_id}
            </Clipboard>
            <Icon
                name='delete'
                onClick={() => {
                    unselect(null);
                    if (onUnselect)
                        onUnselect(product);
                }}
            />
        </Label>
        <Label 
            color="teal"
            as={Button} 
            onClick={() => history.push(`/product/${product.product_id}/edit`)}>
            Edit Product
        </Label>
        </>
    );
};

const mapStateToProps = ({ productGrid }) => ({
    product: productGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    unselect: selectProductFromGrid,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedProductBadge));