import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-semantic-toasts';
import { Icon, List, Label } from 'semantic-ui-react';

const copySuccess = data => {
    toast({
        icon: 'copy outline',
        title: `Copied to clipboard`,
        description: `UPC ${data.text} has been copied`
    });
};

const ProductUpcListItem = ({ upc, onDelete, onSelect }) => (
    <List.Item>
        <List.Content floated='right'>
            <a 
                href="/" 
                onClick={(e) => e.preventDefault()}
            >
                <Clipboard
                    component="span" 
                    onSuccess={copySuccess}
                    data-clipboard-text={upc.upc}
                >
                    <Icon 
                        size="small" 
                        name="copy" 
                        color="teal"
                    />
                </Clipboard>
            </a>
            <a 
                href="/" 
                onClick={(e) => { 
                    e.preventDefault(); 
                    onDelete(upc);
                }}
            >
                <Icon 
                    size="small"
                    name="delete" 
                    color="orange" 
                />
            </a>
        </List.Content>
        <List.Content onClick={() => onSelect && onSelect(upc)} className="clickable">
            <Label 
                empty 
                circular 
                size="mini" 
                color={upc.active === 1 ? 'green' : 'red'}
                title={upc.active === 1 ? 'Active' : 'Inactive'} 
            />
            &nbsp;
            <b>{upc.upc}</b> - {pluralize('prod', upc.total_products, true) } | {format(upc.create_date, 'DD MMM, YY') }
        </List.Content>
    </List.Item>
);

ProductUpcListItem.propTypes = {
    upc: PropTypes.shape({
        upc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        create_date: PropTypes.any.isRequired,
        active: PropTypes.oneOf([-1, 0, 1]),
    }),
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
};

export default ProductUpcListItem;