import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

export default class ActionGridCell extends Component {
    state = {
        value: null
    };

    componentDidMount() {
        this.setState({ value: this.props.value });
    };

    refresh(params) {
        if (params.value !== this.state.value) {
            this.setState({
                value: params.value
            })
        }

        return true;
    };

    render() {
        const { data } = this.props;
        return (
            <span title={this.state.value}>
                <a 
                    target="_blank" 
                    title="View Ronin"
                    rel="noopener noreferrer" 
                    href={`https://virtue.roninapp.com/clients/${data.ronin_id}`}
                >
                    <Icon name="coffee" />
                </a>
                <a 
                    target="_blank" 
                    title="Compute Rate"
                    rel="noopener noreferrer" 
                    href={`//tx.ydr.ink/back/?ajax=companies&results=compute&company=${data.company}`}
                >
                    <Icon name="money bill alternate outline" />
                </a>
                {data.need_po && <Icon name="file alternate outline" title="Need PO"/>}
            </span>
        );
    };
};
