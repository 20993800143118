import React from 'react';
import { Table } from "semantic-ui-react";

const ReportComponentCountTable = ({ summary }) => (
    <>
        <Table definition striped celled>
            <Table.Header>
                <Table.Row textAlign="right">
                    <Table.HeaderCell />
                    <Table.HeaderCell>Sent</Table.HeaderCell>
                    <Table.HeaderCell>Unique Open</Table.HeaderCell>
                    <Table.HeaderCell>Total Open</Table.HeaderCell>
                    <Table.HeaderCell>Unique Click</Table.HeaderCell>
                    <Table.HeaderCell>Total Click</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row textAlign="right">
                    <Table.Cell>
                        Emails
                    </Table.Cell>
                    <Table.Cell>{summary.totalEmailsSent}</Table.Cell>
                    <Table.Cell>{ summary.uniqueEmailsOpened }</Table.Cell>
                    <Table.Cell>{ summary.totalEmailsOpened }</Table.Cell>
                    <Table.Cell>{ summary.uniqueEmailsClicked }</Table.Cell>
                    <Table.Cell>{ summary.totalEmailsClicked }</Table.Cell>
                </Table.Row>
                <Table.Row textAlign="right">
                    <Table.Cell>
                        Files
                    </Table.Cell>
                    <Table.Cell>{summary.totalFilesSent}</Table.Cell>
                    <Table.Cell>{ summary.uniqueFilesOpened }</Table.Cell>
                    <Table.Cell>{ summary.totalFilesOpened }</Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                </Table.Row>
            </Table.Body>
        </Table>
    </>
);

export default ReportComponentCountTable;