import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { values, without, capitalize } from 'lodash';

import { CompanyServiceLevels } from '../constants';

const allowedValues = [ 'all', ...without(
    values(CompanyServiceLevels), 
    CompanyServiceLevels.INTELLIGENCE, 
    CompanyServiceLevels.MARKETSLICE
)].map(level => level.toLowerCase());

const CompanyComponentServiceFilter = ({ 
    onChange,

    serviceLevel='complete', 
    dropdownProps={},
    isLoading, 
}) => {
    return (
        <Dropdown
            loading={isLoading}
            value={serviceLevel}
            onChange={(e, data) => {
                if (data.value !== serviceLevel)
                    onChange(data.value);
                }
            }
            options={allowedValues.map(i => ({ key: i, text: capitalize(i), value: i }))}
            {...dropdownProps}
        />
    );
};

CompanyComponentServiceFilter.propTypes = {
    serviceLevel: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CompanyComponentServiceFilter;