import {
    isArray,
    isString,
    isEmpty,
} from "lodash";
import {
    PRODUCT_EDITOR_INPUT,
    PRODUCT_EDITOR_ERROR,
    PRODUCT_EDITOR_SAVING,
    PRODUCT_EDITOR_SAVED,
    PRODUCT_EDITOR_CONFIRMING,
    PRODUCT_EDITOR_PRODUCT_ERROR,
    PRODUCT_EDITOR_PRODUCT_LOADING,
    PRODUCT_EDITOR_PRODUCT_DATA,
    PRODUCT_EDITOR_PRODUCT_RESET,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    inputs: {},
    isConfirming: false,
    isSaving: false,
    errors: [],

    product: null,
    productError: null,
    isLoadingProduct: false,
};

const setError = (state, error) => {
    let {
        errors
    } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return {
        ...state,
        isSaving: false,
        errors,
    };
};
const setConfirming = (state, product) => {
    // if product is not literally false, then set it to true
    const newState = {
        ...state,
        isConfirming: product !== false
    };

    if (product && !isEmpty(product)) {
        newState.inputs = product;
    }

    return newState;
};
const setSaving = (state) => ({
    ...state,
    isSaving: true
});
const resetState = (state) => initialState;

const setInput = (state, inputs = {}) => {
    return {
        ...state,
        inputs: {
            ...state.inputs,
            ...inputs
        }
    };
};

const setProductLoading = (state) => ({
    ...state,
    isLoadingProduct: true,
    productError: null
});
const resetProduct = (state) => ({
    ...state,
    inputs: {},
    product: null,
    productError: null,
    isLoadingProduct: false,
});
const setProductData = (state, product) => {
    const inputs = product;
    return {
        ...state,
        isLoadingProduct: false,
        product,
        inputs,
        productError: null
    }
};
const setProductError = (state, err) => ({
    ...state,
    isLoadingProduct: false,
    product: null,
    productError: err
});

const reducers = {
    [PRODUCT_EDITOR_PRODUCT_LOADING]: setProductLoading,
    [PRODUCT_EDITOR_PRODUCT_ERROR]: setProductError,
    [PRODUCT_EDITOR_PRODUCT_DATA]: setProductData,
    [PRODUCT_EDITOR_PRODUCT_RESET]: resetProduct,

    [PRODUCT_EDITOR_CONFIRMING]: setConfirming,
    [PRODUCT_EDITOR_SAVED]: resetState,
    [PRODUCT_EDITOR_SAVING]: setSaving,
    [PRODUCT_EDITOR_INPUT]: setInput,
    [PRODUCT_EDITOR_ERROR]: setError,
};

export default generateReducer(reducers, initialState);