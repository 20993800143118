import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-semantic-toasts';
import { Grid, Dropdown } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { endOfMonth, parse, format, subMonths, differenceInMonths } from 'date-fns';

import { UserSettingKeys, UserSettingAllowedValues, UserSettingSalesPeriods } from '../../constants';

const availablePeriods = UserSettingAllowedValues['SALES_PERIOD_TYPE'];

class PeriodRangePicker extends React.Component {
    state = {
        range: ''
    };

    componentDidMount () {
        this.setState({ range: `${this.props.start} - ${this.props.end}` });
    };

    handleChange = (e, data) => {
        const range = data.value;
        this.setState({ range });

        let [start, end] = range.split(' - ');

        if (!start || !end)
            return;

        const startDate = parse(start),
            endDate = endOfMonth(parse(end));

        if (differenceInMonths(endDate, startDate) < 1) {
            // if user selects 2 dates from same months, reset it to only have the start date and show an alert
            this.setState({ range: `${start} - `});

            return toast({
                time: 3000,
                icon: 'warning',
                type: 'warning',
                title: 'Invalid Setting',
                description: `Select a valid month range`,
            });
        }

        this.props.onChange([ format(startDate, 'YYYY-MM-DD'), format(endDate, 'YYYY-MM-DD') ]);
    };

    render () {
        return (
            <DatesRangeInput
                fluid
                name="period"
                iconPosition="left"
                dateFormat="YYYY/MM"
                placeholder="Start - End"
                closeOnMouseLeave={false}
                value={this.state.range}
                onChange={this.handleChange}
            />
        );
    };
};

PeriodRangePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
};

class SettingSalesPeriod extends React.Component {
    render() {
        const { settings, onChange } = this.props;
        let start, end, type;
        let startDate, endDate;

        settings.forEach(setting => {
            if (UserSettingKeys.SALES_PERIOD_START === setting.name)
                start = setting;
            if (UserSettingKeys.SALES_PERIOD_END === setting.name)
                end = setting;
            if (UserSettingKeys.SALES_PERIOD_TYPE === setting.name)
                type = setting;
        });

        if (!start || !end)
            return null;

        if (type.value.toLowerCase() === 'custom') {
            if (start && start.value) {
                startDate = start.value.split('-').slice(0, -1).join('/');
            } else {
                startDate = format(subMonths(new Date(), 3), 'YYYY-MM');
            }

            if (end && end.value) {
                endDate = end.value.split('-').slice(0, -1).join('/');
            } else {
                endDate = format(subMonths(new Date(), 2), 'YYYY-MM');
            }
        }
        return (
            <Grid verticalAlign="middle">
                <Grid.Column width={8}>
                    <b>Sales Period Type</b>
                </Grid.Column>

                <Grid.Column width={8}>
                    <Dropdown
                        fluid
                        selection
                        selectOnBlur={false}
                        value={type.value.toLowerCase()}
                        disabled={this.props.isDisabled}
                        onChange={(e, data) => onChange(type.id, data.value)}
                        options={ availablePeriods.map(period => ({
                            key: period.toLowerCase(), 
                            value: period.toLowerCase(), 
                            text: UserSettingSalesPeriods[period] 
                        })) }
                    />
                </Grid.Column>

                { type.value.toLowerCase() === 'custom' && (
                    <React.Fragment>
                        <Grid.Column width={6}>
                            <b>Sales Period</b>
                        </Grid.Column>

                        <Grid.Column width={10}>
                            <PeriodRangePicker
                                onChange={([from, to]) => {
                                    onChange(start.id, from);
                                    onChange(end.id, to);
                                }} 
                                start={startDate}
                                end={endDate}
                            />
                        </Grid.Column>
                    </React.Fragment>
                )}
            </Grid>
        );
    };
};

SettingSalesPeriod.propTypes = {
    settings: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

export default SettingSalesPeriod;