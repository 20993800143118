import { difference } from 'lodash';

import { 
    RECENT_GRID_DATA, RECENT_GRID_ERROR, RECENT_GRID_LOADING, 
    RECENT_GRID_COLUMN_TOGGLE, RECENT_GRID_MINUTES,
    RECENT_GRID_CONTROL_TOGGLE
} from "../constants";
import { allRecentGridColumns } from "./columns";

const initialState = {
    error: null,
    clients: [],
    minutes: 10,
    isLoading: false,
    hiddenColumns: [],
    showControl: false,
    shownColumns: Object.keys(allRecentGridColumns),
    columnDefs: Object.values(allRecentGridColumns),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECENT_GRID_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                clients: action.payload.clients,
            };

        case RECENT_GRID_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case RECENT_GRID_CONTROL_TOGGLE:
            return {
                ...state,
                showControl: !state.showControl,
            };

        case RECENT_GRID_COLUMN_TOGGLE:
            const hiddenColumns = action.payload;
            const shownColumns = difference(Object.keys(allRecentGridColumns), action.payload);

            return {
                ...state,
                shownColumns,
                hiddenColumns,
            };

        case RECENT_GRID_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case RECENT_GRID_MINUTES:
            return {
                ...state,
                minutes: action.payload,
            };

        default:
            return state;
    }
};