import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { differenceInHours } from 'date-fns';
import { push } from 'connected-react-router';
import { debounce, isEqual, get, without } from 'lodash';

import { selectCompanyFromGrid } from '../usage/actions';
import { loadRenewalGridData, changeRenewalGridState, saveRenewalGridColumn } from './actions';

import RenewalGridControl from './grid-control';
import AgGridDatePicker from '../../common/ag-grid/date-picker';
import AgGridPremisePicker from '../../common/ag-grid/premise-picker';
import CompanyComponentActionGridCell from '../components/action-grid-cell';
import AgGridServiceTypePicker from '../../common/ag-grid/service-type-picker';
import CompanyComponentServicesGridCell from '../components/services-grid-cell';
import CommonContainerColoredNumber from '../../common/components/colored-number';

const frameworkComponents = {
    agGridDatePicker: AgGridDatePicker,
    agGridPremisePicker: AgGridPremisePicker,
    agGridServiceTypePicker: AgGridServiceTypePicker,
    coloredNumberRenderer: CommonContainerColoredNumber,
    companyActionGridCell: CompanyComponentActionGridCell,
    companyServicesGridCell: CompanyComponentServicesGridCell,
};

class RenewalGrid extends React.Component {
    grid = null;

    componentDidMount() {
        const { lastLoaded, loadRenewalGridData, serviceLevel } = this.props;
        if (!lastLoaded || differenceInHours(new Date(), lastLoaded) > 3) {
            loadRenewalGridData({ service: serviceLevel });
        }
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }
        
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeRenewalGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeRenewalGridState({ filterModel: this.grid.api.getFilterModel() });
        });

        this.grid.api.addEventListener('cellValueChanged', (e) => {
            if (e.newValue !== e.oldValue)
                this.props.saveRenewalGridColumn(e.data.id, {
                    [e.column.colId]: e.newValue
                }).then(() => {
                    this.grid.api.redrawRows();
                });
        });

        this.grid.api.addEventListener('cellDoubleClicked', (e) => {
            const col = get(e, 'colDef.field', null);

            if (['actions'].indexOf(col) >= 0) {
                this.props.push(`/company/${e.data.id}/edit`);
            }
        });

        const { selectedCompany } = this.props;
        if (selectedCompany) {
            this.grid.api.forEachNode(row => {
                const shouldBeSelectedRow = (row.data && row.data.id === selectedCompany.id);
                if (shouldBeSelectedRow && !row.selected) {
                    row.setSelected(true, true);
                }
            });
        }
    };

    selectCompany = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectCompanyFromGrid(selectedRows[0]);
        } else {
            this.props.selectCompanyFromGrid(null);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render() {
        const { companies, shownColumns } = this.props;

        return (
            <div>
                <RenewalGridControl
                    onDownload={() => this.grid.api.exportDataAsCsv({
                        fileName: `company_renewal_${Date.now()}`,
                        columnKeys: without(shownColumns, 'actions')
                    })} 
                    resetFilters={() => this.grid.api.setFilterModel(null)}
                />
                <div className="ag-theme-balham">
                    <AgGridReact
                        animateRows
                        rowHeight={32}
                        rowData={companies}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        onSelectionChanged={this.selectCompany}
                        frameworkComponents={frameworkComponents}
                    />
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ companyRenewalGrid, companyUsageGrid }) => ({
    error: companyRenewalGrid.error,
    companies: companyRenewalGrid.companies,
    isLoading: companyRenewalGrid.isLoading,
    sortModel: companyRenewalGrid.sortModel,
    columnDefs: companyRenewalGrid.columnDefs,
    lastLoaded: companyRenewalGrid.lastLoaded,
    filterModel: companyRenewalGrid.filterModel,
    shownColumns: companyRenewalGrid.shownColumns,
    hiddenColumns: companyRenewalGrid.hiddenColumns,
    
    selectedCompany: companyUsageGrid.selected,
    serviceLevel: companyUsageGrid.serviceLevel,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    push,
    loadRenewalGridData,
    selectCompanyFromGrid,
    saveRenewalGridColumn,
    changeRenewalGridState,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RenewalGrid);