import Joi from 'joi-browser';
import { omit } from 'lodash';

const schema = {
    id: Joi.number().required(),
    ronin_id: Joi.number().optional(),
    need_po: Joi.boolean().required(),
    need_sign: Joi.boolean().required(),
    type: Joi.string().trim().required(),
    service_price: Joi.number().required(),
    company: Joi.string().trim().required(),
    service_status: Joi.string().required(),
    max_users: Joi.number().required().min(0),
    service_level: Joi.string().trim().required(),
    service_start: Joi.string().trim().required(),
    zip: Joi.string().trim().allow('').optional(),
    city: Joi.string().trim().allow('').optional(),
    state: Joi.string().trim().allow('').optional(),
    manager: Joi.string().trim().allow('').optional(),
    address: Joi.string().trim().allow('').optional(),
    service_product_type: Joi.array().min(1).max(2).required(),
    service_premises: Joi.array().min(1).max(2).required(),
    service_renewal: Joi.string().trim().required(),
    supplier: Joi.string().trim().allow('').optional(),
    distributor: Joi.string().trim().allow('').optional(),
    brand: Joi.string().trim().allow('').optional(),
    service_billing_cycle: Joi.string().trim().required(),
};

export const validateCompanyCreate = (company) => {
    return Joi.validate(company, omit(schema, ['id']), {
        convert: true,
        stripUnknown: true,
    });
};

export const validateCompanyUpdate = (company) => {
    return Joi.validate(company, schema, {
        convert: true,
        stripUnknown: true,
    });
};