import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { createRef } from 'react';
import { bindActionCreators } from 'redux';
import { ChromePicker } from 'react-color';
import { Button, Popup } from 'semantic-ui-react';

import { authUpdateProfile } from '../actions';

class AuthContainerProfileColor extends React.Component {
    state = { showPicker: false };
    popupRef = createRef();

    togglePicker = () => this.setState({ showPicker: !this.state.showPicker });

    render () {
        const { color='#45078B', name, authUpdateProfile, editable=false, buttonProps={} } = this.props;
        
        return (
            <>
                {editable && (
                    <Popup 
                        context={this.popupRef}
                        position="bottom right"
                        open={this.state.showPicker}
                    >
                        <div className="bp-5 clear">
                            <Button
                                size="tiny"
                                content="close"
                                floated="right"
                                onClick={this.togglePicker}
                            />
                        </div>

                        <ChromePicker 
                            color={color}
                            onChangeComplete={(color) => authUpdateProfile({ color: color.hex })}
                        />
                    </Popup>
                )}
                <span ref={(r) => this.popupRef = r}></span>
                <Button
                    circular
                    className="profile-color"
                    content={name[0].toUpperCase()}
                    style={{ backgroundColor: color }}
                    onClick={() => editable ? this.togglePicker() : null}
                    {...buttonProps}
                />
            </>
        );
    };
};

const mapStateToProps = ({ auth }) => ({
    color: auth.user.color,
    name: auth.user.name,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authUpdateProfile
}, dispatch);

AuthContainerProfileColor.propTypes = {
    dropdownProps: PropTypes.object,
    editable: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainerProfileColor);