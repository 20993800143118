import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEqual } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Segment, Label, Button } from 'semantic-ui-react';

import {
    selectProductMatchFromGrid, saveMatchGridColumn,
    loadProductMatchGridPage, hideProductMatchGridColumn,
    changeProductMatchGridPage, changeProductMatchGridState,
} from './actions';

import ProductSizePickerAgGrid from '../size-picker/ag-grid';
import GridPageSizePicker from '../../common/components/grid-page-size-picker';
import CommonComponentCellWithCopyButton from '../../common/components/cell-with-copy-button';

class ProductContainerProductMatchGrid extends React.Component {
    grid = null;
    gridDataSource = {
        getRows: (params) => {
            this.props.loadProductMatchGridPage(this.props.selectedProduct.product_id, this.props, params)
        },
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeGridState({ filterModel: this.grid.api.getFilterModel() });
        });

        this.grid.api.addEventListener('cellValueChanged', (e) => {
            if (e.newValue !== e.oldValue)
                this.props.saveMatchGridColumn(e.data.id, { [e.column.colId]: e.newValue });
        });
    };

    selectMatch = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0 && selectedRows[0]) {
            this.props.selectProductMatchFromGrid(selectedRows[0]);
        } else {
            this.props.selectProductMatchFromGrid(null);
        }
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage)
                return;

            // console.log('changePage', page, this.props.currentPage);
            this.props.changeProductMatchGridPage(page);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.grid.api.sizeColumnsToFit();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid && this.grid.api.showLoadingOverlay();
        }

        // if selected product changes, refresh the grid data
        if (this.props.selectedProduct && !isEqual(prevProps.selectedProduct.product_id, this.props.selectedProduct.product_id)) {
            this.grid.api.refreshInfiniteCache();
        }

        if (!isEqual(prevProps.pageSize, this.props.pageSize)) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.selectedMatch, this.props.selectedMatch)) {
            this.grid.api.sizeColumnsToFit();
        }
    };

    render() {
        const { selectedProduct, selectedMatch } = this.props;

        return (
            <div>
                <Segment clearing vertical>
                    <Label
                        content={`Matches of #${selectedProduct.product_id}`}
                        color="grey"
                    />

                    <GridPageSizePicker
                        button
                        pointing="top"
                        className="right floated mini"
                        text={`${this.props.pageSize}/page`}
                        pageSize={this.props.pageSize}
                        onChange={this.props.changeGridState}
                    />

                    {selectedMatch && (
                        <Button
                            size="tiny"
                            floated="right"
                            content="Clear Selection"
                            onClick={() => {
                                this.props.selectProductMatchFromGrid(null)
                            }}
                        />
                    )}
                </Segment>

                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        rowSelection="single"
                        floatingFilter={true}
                        rowModelType="infinite"
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        onSelectionChanged={this.selectMatch}
                        frameworkComponents={{
                            productSizePicker: ProductSizePickerAgGrid,
                            cellWithCopyButton: CommonComponentCellWithCopyButton,
                        }}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ productMatchGrid, productGrid }) => ({
    matches: productMatchGrid.matches,
    lastRow: productMatchGrid.lastRow,
    pageSize: productMatchGrid.pageSize,
    isLoading: productMatchGrid.isLoading,
    sortModel: productMatchGrid.sortModel,
    columnDefs: productMatchGrid.columnDefs,
    selectedMatch: productMatchGrid.selected,
    filterModel: productMatchGrid.filterModel,
    currentPage: productMatchGrid.currentPage,
    shownColumns: productMatchGrid.shownColumns,
    hiddenColumns: productMatchGrid.hiddenColumns,
    
    selectedProduct: productGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductMatchGridPage, hideProductMatchGridColumn, selectProductMatchFromGrid,
    saveMatchGridColumn,
    changeGridState: (state) => dispatch(changeProductMatchGridState(state)),
    changeProductMatchGridPage: (page) => dispatch(changeProductMatchGridPage(page)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerProductMatchGrid);