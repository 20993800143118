import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header, Form, Dropdown, Grid, Button, Label, Icon } from 'semantic-ui-react';

import {
    loadRecentGridData,
    hideRecentGridColumn,
    setRecentGridMinutes,
    toggleRecentGridControl
} from './actions';

import { allRecentGridColumns } from './columns';
import TotalSearchCount from './total-search-count';
import GridColumnPicker from '../../common/components/grid-column-picker';


const UserContainerRecentGridControl = ({
    showControl, clients, minutes, isLoading, hiddenColumns,
    hideRecentGridColumn, toggleRecentGridControl, loadRecentGridData, setRecentGridMinutes
}) => {
    if (!showControl) {
        return (
            <Segment vertical clearing>
                <TotalSearchCount clients={ clients }/>

                <Label as="span" color="blue" size="mini">
                    <Icon name='users' />
                    {clients.length} Online
                </Label>

                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleRecentGridControl}
                >
                    Configure Grid
                </Button>
                <Dropdown
                    inline
                    button
                    value={minutes}
                    className="mini right floated" 
                    text={`${minutes} mins`}
                    onChange={(e, {value}) => {
                        if (minutes !== value)
                            setRecentGridMinutes(value); 
                    }}
                    options={[10, 15, 30].map(i => ({ key: i, text: i, value: i }))}
                />
                <Button 
                    size="mini"
                    floated="right"
                    content="Update"
                    loading={isLoading}
                    icon="sync alternate"
                    onClick={() => loadRecentGridData(minutes)}
                />
            </Segment>
        );
    };

    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            More Config
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleRecentGridControl}
                        >
                            Hide
                        </Button>
                        <Button 
                            size="mini"
                            floated="right" 
                            content="Update"
                            loading={isLoading}
                            icon="sync alternate"
                            onClick={() => loadRecentGridData(minutes)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
            <Form>
                <Form.Group>
                    <Form.Field width={12}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker 
                            hiddenColumns={hiddenColumns}
                            columns={allRecentGridColumns} 
                            onChange={hideRecentGridColumn}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Field width={16}>
                    <label>
                        Users in the last &nbsp;
                        <Dropdown
                            inline
                            value={minutes}
                            onChange={(e, {value}) => {
                                if (minutes !== value)
                                    setRecentGridMinutes(value); 
                            }}
                            options={[10, 15, 30].map(i => ({ key: i, text: i, value: i }))}
                        />
                        &nbsp;minutes

                        &nbsp;&nbsp;
                        <Label as="span" color="blue" size="mini">
                            <Icon name='users' />
                            {clients.length} Online
                        </Label>

                        &nbsp;&nbsp;
                        <TotalSearchCount clients={ clients }/>
                    </label>
                </Form.Field>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ recentGrid }) => ({
    clients: recentGrid.clients,
    minutes: recentGrid.minutes,
    isLoading: recentGrid.isLoading,
    showControl: recentGrid.showControl,
    hiddenColumns: recentGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hideRecentGridColumn, loadRecentGridData, 
    setRecentGridMinutes, toggleRecentGridControl,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerRecentGridControl);