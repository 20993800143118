import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import { Segment, Grid, Message, Header, Divider, } from "semantic-ui-react";

import {
    loadReportStats,
    changeReportStatsDateRange,
} from "../actions";
import ReportComponentCountTable from "../components/count-table";
import ReportComponentPerformanceList from "../components/performance-list";
import ReportComponentUnopenedList from "../components/unopened-list";

class ReportContainerStats extends React.Component {
    componentWillMount() {
        this.props.loadReportStats();
    }

    render() {
        const {
            data,
            isLoading,
            dateRange,

            changeReportStatsDateRange,
        } = this.props;

        return (
            <React.Fragment>
                <DatesRangeInput
                    name="datesRange"
                    value={dateRange}
                    iconPosition="left"
                    placeholder="From - To"
                    dateFormat="YYYY-MM-DD"
                    closeOnMouseLeave={false}
                    popupPosition="bottom left"
                    onChange={(e, data) => changeReportStatsDateRange(data.value)}
                />

                <Divider clearing />
                {isLoading ? (
                    <Segment loading style={{ minHeight: "200px" }} />
                ) : Object.keys(data).length > 0 ? (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                mobile={16} 
                                tablet={8} 
                                computer={8}
                            >
                                <ReportComponentUnopenedList
                                    listType="openedButNotDownloadedReports"
                                    emails = {data.openedButNotDownloadedReports}
                                />

                                <Header size="small" content="Interaction summary" />
                                <Segment>
                                    <ReportComponentCountTable
                                        summary={data}
                                    />
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                <ReportComponentPerformanceList 
                                                    ranking="top"
                                                    reports={data.topDownloadedReports}
                                                />
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                <ReportComponentPerformanceList 
                                                    ranking="bottom"
                                                    reports={data.bottomDownloadedReports}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column
                                mobile={16} 
                                tablet={8} 
                                computer={8}
                            >
                                <ReportComponentUnopenedList
                                    listType="unopenedEmails"
                                    emails = {data.unopenedEmails}
                                />                                    
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <Message error content="Sorry no report stats found" />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ reportStats }) => ({
    data: reportStats.data,
    error: reportStats.error,
    dateRange: reportStats.dateRange,
    isLoading: reportStats.isLoading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadReportStats,
            changeReportStatsDateRange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainerStats);