import React from 'react';
import { Grid } from 'semantic-ui-react';

import BotContainerList from '../containers/list';
import TopNav from '../../common/containers/top-nav';

const BotHomePage = () => {
    return (
        <Grid>
            <TopNav 
                headerContent='Bots'
                headerIcon='android'
                subheader={`Periodic bots to make sure everything's in tip top shape.`}
            />

            <Grid.Row>
                <Grid.Column mobile={16} >
                    <BotContainerList />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default BotHomePage;