import { 
    ACTIVITY_PERFROMED,
    ACTIVITY_TOGGLE_HISTORY,
} from './constants';

const initialState = {
    history: [],
    showHistory: false,
};

const addActivity = (state, history) => {
    history.timestamp = new Date();

    return { ...state, history: [history, ...state.history] }
};

const toggleHistory = (state) => {
    return {...state, showHistory: !state.showHistory};
};

const reducers = {
    [ACTIVITY_PERFROMED]: addActivity,
    [ACTIVITY_TOGGLE_HISTORY]: toggleHistory,
};

const reducer = (state = initialState, action) => {
    if (action.type in reducers)
        return reducers[action.type](state, action.payload);

    return state;
};

export default reducer;