import { toast } from "react-semantic-toasts";

import { 
    CHECK_EDITOR_DATA, CHECK_EDITOR_SAVING, CHECK_EDITOR_SAVED, 
    CHECK_EDITOR_ERROR, CHECK_EDITOR_LOADING 
} from "../constants";
import { makePrivateApiCall } from '../../common/api';
import { authExpire } from '../../auth/actions';

export const setCheckEditorData = (check) => dispatch => {
    dispatch({
        type: CHECK_EDITOR_DATA,
        payload: check,
    });
};

// setSubmitting comes from formik, it must be fired with `false` when submission is complete and server data is returned regardless of error or success
export const saveCheckEditorData = (check, setSubmitting) => dispatch => {
    dispatch({
        type: CHECK_EDITOR_SAVING,
    });

    const url = check.check_id ? `/check/${check.check_id}` : '/check',
        method = check.check_id ? 'PUT' : 'POST';

    return makePrivateApiCall({
        url, method, data: check
    }).then(res => {
        if (res.data) {
            toast({
                time: 4000,
                type: 'success',
                title: 'Check Saved',
                icon: 'check circle outline',
                description: `Check has been saved and setup successfully.`,
            });

            setSubmitting(false);
            return dispatch({
                type: CHECK_EDITOR_SAVED,
                payload: res.data
            });
        }
    }).catch(err => {
        setSubmitting(false);

        if (err.response) {
            if (err.response.status === 401)
                return dispatch(authExpire());

            if (err.response.data && err.response.data.message) {
                return dispatch({
                    type: CHECK_EDITOR_ERROR,
                    payload: err.response.data.message,
                });
            }
        }

        return dispatch({
            type: CHECK_EDITOR_ERROR,
            payload: err.message
        });
    });
};

export const loadCheckForEditor = (checkId) => dispatch => {
    dispatch({
        type: CHECK_EDITOR_LOADING,
    });

    return makePrivateApiCall({
        url: `/check/${checkId}`, 
        method: 'GET'
    }).then(res => {
        if (res.status < 300) {
            return dispatch({
                type: CHECK_EDITOR_SAVED,
                payload: res.data
            });
        }
    }).catch(err => {
        if (err.response) {
            if (err.response.status === 401)
                return dispatch(authExpire());

            if (err.response.data && err.response.data.message) {
                return dispatch({
                    type: CHECK_EDITOR_ERROR,
                    payload: err.response.data.message,
                });
            }
        }

        return dispatch({
            type: CHECK_EDITOR_ERROR,
            payload: err.message
        });
    });
};
