import React from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { differenceInHours } from 'date-fns';
import { withRouter } from 'react-router-dom';

import { 
    loadUsageGridData, 
    hideUsageGridColumn, 
    selectCompanyFromGrid, 
    changeUsageGridState 
} from './actions';

import UsageGridControl from './grid-control';
import CommonContainerColoredNumber from '../../common/components/colored-number';

class UsageGrid extends React.Component {
    grid = null;

    componentDidMount() {
        const { lastLoaded, loadUsageGridData, serviceLevel } = this.props;
        if (!lastLoaded || differenceInHours(new Date(), lastLoaded) > 3) {
            loadUsageGridData({ service: serviceLevel });
        }
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid, false);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid, false);
        this.grid = null;
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (this.props.isLoading) {
            this.grid && this.grid.api.showLoadingOverlay();
        }
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }
        
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeUsageGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeUsageGridState({ filterModel: this.grid.api.getFilterModel() });
        });
        
        const { selectedCompany } = this.props;
        if (selectedCompany) {
            this.grid.api.forEachNode(row => {
                const shouldBeSelectedRow = (row.data && row.data.id === selectedCompany.id);
                if (shouldBeSelectedRow && !row.selected) {
                    row.setSelected(true, true);
                }
            });
        }
    };

    toggleGridColumn = (cols) => {
        this.props.hideUsageGridColumn(cols);
    };

    selectCompany = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectCompanyFromGrid(selectedRows[0]);
        } else {
            this.props.selectCompanyFromGrid(null);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render () {
        const { companies } = this.props;

        return (
            <div className="bm-15">
                <UsageGridControl 
                    onDownload={() => this.grid.api.exportDataAsCsv({
                        fileName: `company_usage_${Date.now()}`
                    })} 
                    resetFilters={() => this.grid.api.setFilterModel(null)}
                />
                
                <div className="ag-theme-balham" style={{height: '400px'}}>
                    <AgGridReact
                        animateRows
                        rowData={companies}
                        floatingFilter={true}
                        rowSelection="single"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        onSelectionChanged={this.selectCompany}
                        frameworkComponents={{ coloredNumberRenderer: CommonContainerColoredNumber }}
                    />
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ companyUsageGrid }) => ({
    error: companyUsageGrid.error,
    companies: companyUsageGrid.companies,
    isLoading: companyUsageGrid.isLoading,
    sortModel: companyUsageGrid.sortModel,
    columnDefs: companyUsageGrid.columnDefs,
    lastLoaded: companyUsageGrid.lastLoaded,
    filterModel: companyUsageGrid.filterModel,
    selectedCompany: companyUsageGrid.selected,
    shownColumns: companyUsageGrid.shownColumns,
    serviceLevel: companyUsageGrid.serviceLevel,
    hiddenColumns: companyUsageGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUsageGridData, 
    hideUsageGridColumn,
    selectCompanyFromGrid,
    changeUsageGridState,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsageGrid));