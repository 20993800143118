import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Form } from 'formik-semantic-ui';

import { 
    UserSettingKeys, 
    UserSettingIgnoreKeys,
    UserSettingAllowedValues, 
    UserSettingPremiseTypes 
} from '../../constants';

const UserComponentSetting = ({ user, setting, onChange, isDisabled=false }) => {
    if (UserSettingIgnoreKeys.indexOf(setting.name) >= 0)
        return null;

    let options = [];
    let dropdownProps = {};

    switch (setting.name) {
        case UserSettingKeys.DISPLAY_TYPE:
            options = UserSettingAllowedValues['DISPLAY_TYPE'];
            dropdownProps.label = 'Display Type';
            break;

        case UserSettingKeys.SERVICE_PREMISES:
            if (user && user.id && !!user.service_premises) {
                options = user.service_premises.split(',');
            } else {
                options = UserSettingAllowedValues['SERVICE_PREMISES'];
            }

            dropdownProps.label = 'Service Premises';
            break;

        case UserSettingKeys.COVERAGE:
            options = UserSettingAllowedValues['COVERAGE'];
            dropdownProps.label = 'Coverage';
            break;

        case UserSettingKeys.SERVICE_PRODUCT_TYPE:
            if (user && user.id && !!user.service_product_type) {
                options = user.service_product_type.split(',');
            } else {
                options = UserSettingAllowedValues['SERVICE_PRODUCT_TYPE'];
            }

            dropdownProps.label = 'Service Product Type';
            break;

        case UserSettingKeys.ALCOHOL_TYPE:
            options = UserSettingAllowedValues['ALCOHOL_TYPE'];
            dropdownProps.label = 'Alcohol Type';
            break;

        case UserSettingKeys.PREMISE_TYPE:
            options = UserSettingAllowedValues['PREMISE_TYPE'].map(type => ({
                key: type,
                value: type,
                text: UserSettingPremiseTypes[type],
            }));
            dropdownProps.label = 'Off Premise Type';
            break;

        case UserSettingKeys.SHOW_BY:
            options = UserSettingAllowedValues['SHOW_BY'];
            dropdownProps.label = 'Show By';
            break;

        case UserSettingKeys.WEEKLY_COMPARE_PERIOD_START:
        case UserSettingKeys.WEEKLY_COMPARE_PERIOD_END:
        case UserSettingKeys.COMPARE_PERIOD_START:
        case UserSettingKeys.SALES_PERIOD_START:
        case UserSettingKeys.WEEKLY_PERIOD_START:
        case UserSettingKeys.COMPARE_PERIOD_END:
        case UserSettingKeys.SALES_PERIOD_TYPE:
        case UserSettingKeys.SALES_PERIOD_END:
        case UserSettingKeys.WEEKLY_PERIOD_END:
        case UserSettingKeys.GEO_NAME:
        case UserSettingKeys.GEO_TYPE:
            return null;

        default:
            break;
    }

    // if options are not just strings, that means we already mapped them for dropdown
    if (typeof options[0] !== 'object') {
        options = options.map(type => ({
            key: type,
            text: type,
            value: type,
        }));
    }

    return (
        <Grid.Column
            className="bp-15"
            computer={4}
            mobile={16}
            tablet={8}
        >
            <Form.Dropdown
                fluid
                selection
                options={options}
                selectOnBlur={false}
                disabled={isDisabled}
                value={setting.value}
                onChange={(e, data) => onChange(setting.id, data.value)}
                {...dropdownProps}
            />
        </Grid.Column>
    );
};

UserComponentSetting.propTypes = {
    setting: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    user: PropTypes.object,
};

export default UserComponentSetting;