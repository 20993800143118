import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Segment, Header, Form, Icon, Button, Grid } from 'semantic-ui-react';

import {
    loadUsageGridData,
    hideUsageGridColumn,
    selectCompanyFromGrid,
    toggleUsageGridControl,
    changeUsageGridServiceLevel,
} from './actions';
import { companyUsageGridColumns } from './columns';

import SelectedCompanyBadge from '../containers/selected-badge';
import CompanyComponentServiceFilter from '../components/service-filter';
import GridColumnPicker from '../../common/components/grid-column-picker';

const UsageGridControl = ({
    hiddenColumns, selectedCompany, history, showControl, serviceLevel, isLoading, filterModel,
    hideUsageGridColumn, toggleUsageGridControl, changeUsageGridServiceLevel, loadUsageGridData, onDownload, resetFilters,
}) => {
    if (!showControl) {
        return (
            <Segment vertical clearing>
                <SelectedCompanyBadge />

                {!!selectedCompany && (
                    <Button
                        compact
                        size="tiny"
                        color="teal"
                        content="Edit"
                        onClick={() => history.push(`/company/${selectedCompany.id}/edit`)}
                    />
                )}
                
                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleUsageGridControl}
                >
                    Configure Grid
                </Button>

                <Button
                    size="mini"
                    icon="refresh"
                    floated="right"
                    loading={isLoading}
                    onClick={() => loadUsageGridData({ service: serviceLevel })}
                />

                <Button
                    size="mini"
                    icon="download"
                    floated="right"
                    onClick={onDownload}
                />

                {!isEmpty(filterModel) && (
                    <Button
                        size="mini"
                        icon="filter"
                        content="Clear"
                        floated="right"
                        onClick={resetFilters}
                    />
                )}

                <CompanyComponentServiceFilter 
                    isLoading={isLoading}
                    serviceLevel={serviceLevel}
                    onChange={changeUsageGridServiceLevel}
                    dropdownProps = {{ 
                        button: true, 
                        pointing: "top",
                        className:"right floated mini" 
                    }}
                />
            </Segment>
        );
    }

    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name="chart line"/>
                            <Header.Content>
                                Usage Grid
                                <Header.Subheader>
                                    Configure The Grid, select a company to see their details, settings etc.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleUsageGridControl}
                        >
                            Hide
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form className="tm-15">
                <Form.Group>
                    <Form.Field width={8}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker
                            hiddenColumns={hiddenColumns}
                            onChange={hideUsageGridColumn}
                            columns={companyUsageGridColumns}
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>Subscription</label>
                        <CompanyComponentServiceFilter 
                            isLoading={isLoading}
                            serviceLevel={serviceLevel}
                            onChange={changeUsageGridServiceLevel}
                            dropdownProps = {{ fluid: true, selection: true }}
                        />
                    </Form.Field>
                    <Form.Field width={5} disabled={!selectedCompany}>
                        <label>Actions</label>
                        <SelectedCompanyBadge />
                        <Button
                            compact
                            size="tiny"
                            color="teal"
                            content="Edit"
                            onClick={() => history.push(`/company/${selectedCompany.id}/edit`)}
                        />
                        
                        <Button
                            size="tiny"
                            icon="filter"
                            content="Clear"
                            onClick={resetFilters}
                            disabled={isEmpty(filterModel)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ companyUsageGrid }) => ({
    error: companyUsageGrid.error,
    companies: companyUsageGrid.companies,
    isLoading: companyUsageGrid.isLoading,
    columnDefs: companyUsageGrid.columnDefs,
    showControl: companyUsageGrid.showControl,
    filterModel: companyUsageGrid.filterModel,
    selectedCompany: companyUsageGrid.selected,
    shownColumns: companyUsageGrid.shownColumns,
    serviceLevel: companyUsageGrid.serviceLevel,
    hiddenColumns: companyUsageGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUsageGridData, 
    hideUsageGridColumn,
    selectCompanyFromGrid,
    toggleUsageGridControl,
    changeUsageGridServiceLevel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsageGridControl));