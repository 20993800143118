import React from 'react'
import PropTypes from 'prop-types'
import { Label, Icon } from 'semantic-ui-react';

const TotalSearchCount = ({ clients = [] }) => {
    if (clients.length < 1)
        return null;

    let searchCount = 0;
    clients.forEach(c => searchCount += c.searches.length);

    return (
        <Label as="span" color="violet" size="mini">
            <Icon name='search' />
            {searchCount} Total Searches
        </Label>
    );
};

TotalSearchCount.propTypes = {
    clients: PropTypes.array.isRequired,
};

export default TotalSearchCount;