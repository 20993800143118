import {
    CHECK_DATA, CHECK_ERROR, CHECK_LOADING,
    CHECK_DETAIL_SHOW, CHECK_DETAIL_HIDE,
    CHECK_EDITOR_SAVED,
    CHECK_DELETED,
} from "../constants";

const initialState = {
    checks: [],
    error: null,
    isLoading: false,
    detailsExposedChecks: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHECK_EDITOR_SAVED:
            const newCheck = action.payload;

            // if the saved check does not have check_id, means it wasn't created in the db so don't add it in the list
            if (!newCheck || !newCheck.check_id)
                return state;

            let { checks } = state,
                updated = false;

            checks = checks.map(chk => {
                if (chk.check_id === newCheck.check_id) {
                    updated = true;
                    return newCheck;
                }

                return chk;
            });

            if (!updated)
                checks.push(newCheck);

            return {
                ...state, checks
            };

        case CHECK_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                checks: action.payload,
            };

        case CHECK_DELETED:
            return {
                ...state,
                isLoading: false,
                checks: state.checks.filter(chk => chk.check_id !== action.payload.check_id),
            };

        case CHECK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case CHECK_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case CHECK_DETAIL_SHOW:
            return {
                ...state,
                detailsExposedChecks: [...state.detailsExposedChecks, action.payload],
            };

        case CHECK_DETAIL_HIDE:
            const checkFinder = (check) => (check.check_id !== action.payload.check_id);
            return {
                ...state,
                detailsExposedChecks: state.detailsExposedChecks.filter(checkFinder)
            };

        default:
            return state;
    }
};