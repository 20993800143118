import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Grid } from 'semantic-ui-react';
import { Form, Input, Checkbox, Dropdown } from 'formik-semantic-ui';

import CompanyContainerSelector from '../../company/containers/selector';
import { UserRndcDivisions, UserSettingAllowedValues, UserSubscriptionStatuses } from '../constants';
import { CompanyServiceLevels } from "../../company/constants";

const rndcDivisions = Object.values(UserRndcDivisions).map(d => ({
    key: d, text: d, value: d,
}));
const findCompany = (companies, id) => companies.find(c => c.id === id);

const UserComponentEditorForm = ({
    handleChange, setFieldValue, setFieldTouched, values, errors, companies, ...formProps
}) => {
    const selectedCompany = findCompany(companies, values.company_id);
    const isRndc = selectedCompany && selectedCompany.distributor.toLowerCase() === 'rndc';
    const noLogin = selectedCompany && ['Report', 'Invoice'].indexOf(selectedCompany.service_level) === -1;

    return (
        <Grid divided>
            <Grid.Row>
                <Grid.Column width={16}>
                    <CompanyContainerSelector
                        onSelect={companyId => {
                            setFieldValue('company_id', companyId);
                            setFieldTouched('company_id');
                            const company = findCompany(companies, companyId);

                            if (!!company) {
                                setFieldValue('service_level', company.service_level);
                                setFieldValue('service_status', company.service_status);
                                setFieldValue('service_premises', company.service_premises.split(','));
                                setFieldValue('service_product_type', company.service_product_type.split(','));
                            }
                        }}
                        error={!!errors.company_id}
                        value={values.company_id}
                    />
                </Grid.Column>
            </Grid.Row>
            {!!values.company_id && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={8}>
                        <Input
                            name="name"
                            label="Name"
                            inputProps={{
                                placeholder: 'First & Last Name'
                            }}
                        />
                        <Input
                            name="email"
                            label="Email"
                            inputProps={{
                                type: "email",
                                placeholder: 'Email Address',
                            }}
                        />

                        {noLogin && (
                            <Input
                                name="password"
                                label="Password"
                                inputProps={{
                                    placeholder: 'Something secret and hard to guess',
                                }}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                        <Form.Group>
                            <Dropdown
                                fieldProps={{ width: 8 }}
                                inputProps={{
                                    fluid: true, multiple: true,
                                }}
                                name="service_product_type"
                                label="Services"
                                options={UserSettingAllowedValues.SERVICE_PRODUCT_TYPE.map(type => ({
                                    key: type, value: type, text: type
                                }))}
                            />

                            <Dropdown
                                fieldProps={{ width: 8 }}
                                inputProps={{
                                    fluid: true, multiple: true,
                                }}
                                name="service_premises"
                                label="Premises"
                                options={UserSettingAllowedValues.SERVICE_PREMISES.map(type => ({
                                    key: type, value: type, text: type
                                }))}
                            />
                        </Form.Group>

                        {isRndc && (
                            <Dropdown
                                placeholder='RNDC Division'
                                options={rndcDivisions}
                                label='RNDC Division'
                                name='rndc_division'
                            />
                        )}

                        <Form.Group widths="equal">
                            <Dropdown
                                label="Status"
                                name="service_status"
                                options={Object.values(UserSubscriptionStatuses).map(type => ({
                                    key: type, value: type, text: type
                                }))}
                            />

                            <Dropdown
                                label="Level"
                                name="service_level"
                                options={Object.values(CompanyServiceLevels).map(type => ({
                                    key: type, value: type, text: type
                                }))}
                            />

                            <Form.Field>
                                <label>Power User</label>
                                <Checkbox
                                    inputProps={{ toggle: true }}
                                    value={(values.power_user > 0)}
                                    name="power_user"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Force Password Change</label>
                                <Checkbox
                                    inputProps={{ toggle: true }}
                                    value={(values.force_change_password > 0)}
                                    name="force_change_password"
                                />
                            </Form.Field>
                        </Form.Group>

                    </Grid.Column>
                    <Grid.Column width={16}>
                        <br />
                        <Button
                            disabled={isEmpty(formProps.touched)}
                            color="teal"
                            type="submit"
                        >
                            Save User
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    )
};

export default UserComponentEditorForm;