import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';
import { debounce, isEqual, get } from 'lodash';

import ProductGridControl from './product-grid-control';
import ProductUpcPickerAgGrid from '../upc-picker/ag-grid';
import ProductTypePickerAgGrid from './ag-grid-type-picker';
import ProductSizePickerAgGrid from '../size-picker/ag-grid';
import ProductColumnPickerAgGrid from './ag-grid-column-picker';
import ProductCategoryPickerAgGrid from '../category-picker/ag-grid';

import { 
    loadProductGridPage, 
    hideProductGridColumn, 
    saveProductGridColumn,
    changeProductGridPage, 
    changeProductGridState, 
    selectProductFromGrid,
    changeProductColumnState,
} from '../actions';

class DataGrid extends React.Component {
    grid = null;
    gridDataSource = {
        getRows: (params) => {
            // console.log(params);
            this.props.loadProductGridPage(this.props, params);
        },
    };

    componentWillMount () {        
        // load the correct page on first load in case the grid was not on first page when user left the page
        const { currentPage, pageSize } = this.props;
        setTimeout(() => {
            this.grid && this.grid.api.ensureIndexVisible((currentPage - 1) * pageSize);
        }, 900);
    };

    componentWillUnmount () {
        this.grid = null;
    };

    saveColumnState = debounce(() => {
        this.props.changeProductColumnState(this.grid.columnApi.getColumnState());
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (!this.props.columnState) {
            this.grid.api.sizeColumnsToFit();
        } else {
            this.grid.columnApi.setColumnState(this.props.columnState);
        }
        
        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeProductGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeProductGridState({ filterModel: this.grid.api.getFilterModel() });
        });

        this.grid.api.addEventListener('columnResized', this.saveColumnState);
        this.grid.api.addEventListener('columnMoved', this.saveColumnState);

        this.grid.api.addEventListener('cellDoubleClicked', (e) => {
            const col = get(e, 'colDef.field', null);

            if (['product_id'].indexOf(col) >= 0) {
                this.props.push(`/product/${e.data.product_id}/edit`);
            }
        });

        this.grid.api.addEventListener('cellValueChanged', (e) => {
            if (e.newValue !== e.oldValue)
                this.props.saveProductGridColumn(e.data.product_id, {
                    [e.column.colId]: e.newValue
                });
        });
    };

    selectProduct = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectProductFromGrid(selectedRows[0]);
        } else {
            this.props.selectProductFromGrid(null);
        }
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;
            
            if (page === this.props.currentPage)
                return;
            
            this.props.changeProductGridPage(page);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
            // this.grid.api.sizeColumnsToFit();
            
            const { selectedProduct } = this.props;
            if (selectedProduct) {
                this.grid.api.forEachNode(row => {
                    const shouldBeSelectedRow = (row.data && row.data.product_id === selectedProduct.product_id);
                    if (shouldBeSelectedRow && !row.selected) {
                        row.setSelected(true, true);
                    }
                });
            }
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if(!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
    };

    render () {
        return (
            <div>
                <ProductGridControl
                    resetFilters={() => this.grid.api.setFilterModel(null)}
                />

                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        onSelectionChanged={this.selectProduct}
                        frameworkComponents={{ 
                            productCategoryPicker: ProductCategoryPickerAgGrid,
                            productColumnPicker: ProductColumnPickerAgGrid,
                            productSizePicker: ProductSizePickerAgGrid,
                            productTypePicker: ProductTypePickerAgGrid,
                            productUpcPicker: ProductUpcPickerAgGrid,
                        }}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ productGrid }) => ({
    products: productGrid.products,
    pageSize: productGrid.pageSize,
    isLoading: productGrid.isLoading,
    sortModel: productGrid.sortModel,
    columnDefs: productGrid.columnDefs,
    filterModel: productGrid.filterModel,
    currentPage: productGrid.currentPage,
    selectedProduct: productGrid.selected,
    
    columnState: productGrid.columnState,
    shownColumns: productGrid.shownColumns,
    hiddenColumns: productGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    push,
    loadProductGridPage, 
    hideProductGridColumn, 
    selectProductFromGrid,
    saveProductGridColumn, 
    changeProductGridState,
    changeProductColumnState,
    changeProductGridPage: (page) => dispatch(changeProductGridPage(page)),
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    // this allows the grid api to be called from it's parent containers
    { withRef: true }
)(DataGrid);