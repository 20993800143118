import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Form, Confirm, Message, Header, Icon, Divider } from 'semantic-ui-react';

import { 
    setProductQueenifierPawn, setProductQueenifierQueen, 
    requestProductMerge, setProductQueenifierConfirming,
    queueProductMerge, setProductQueenifierError, 
} from './actions';

export const ProductContainerQueenifier = ({
    queen, pawn, error, isQueueing, isConfirming,
    setProductQueenifierPawn, setProductQueenifierQueen, queueProductMerge, setProductQueenifierConfirming, requestProductMerge, onQueued
}) => {
    return (
        <Segment>
            <Confirm
                size="tiny"
                open={isConfirming}
                confirmButton="Merge"
                cancelButton="Nevermind"
                onCancel={() => setProductQueenifierConfirming(false)}
                content={`Sure you want #${queen} to swallow #${pawn}?`}
                onConfirm={() => queueProductMerge(queen, pawn).then(onQueued)}
            />

            <Header>
                <Icon name="chess queen" />
                <Header.Content>
                    Product Queenifier
                    </Header.Content>
            </Header>
            <Divider />

            <Form 
                onSubmit={() => requestProductMerge(queen, pawn)} 
                loading={isQueueing}
            >
                <Form.Input
                    fluid
                    value={queen}
                    label='Have queen'
                    placeholder='Queen product id'
                    onChange={(e, {value}) => setProductQueenifierQueen(value)}
                />
                <Form.Button 
                    fluid
                    disabled={queen.length < 1 && pawn.length < 1}
                    onClick={(e) => {
                        setProductQueenifierPawn(queen);
                        setProductQueenifierQueen(pawn);
                    }}
                    type="button" 
                    icon="arrows alternate vertical" 
                />
                <Form.Input
                    fluid
                    value={pawn}
                    label='Have pawn'
                    placeholder='Pawn product id'
                    onChange={(e, {value}) => setProductQueenifierPawn(value)}
                />
                <Form.Button content='Merge' fluid />
            </Form>

            {error && (<Message error content={error} />)}
        </Segment>
    );
};

const mapStateToProps = ({ productQueenifier }) => ({
    isConfirming: productQueenifier.isConfirming,
    isQueueing: productQueenifier.isQueueing,
    minimized: productQueenifier.minimized,
    queen: productQueenifier.queen,
    error: productQueenifier.error,
    pawn: productQueenifier.pawn,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductQueenifierPawn, setProductQueenifierQueen, 
    requestProductMerge, setProductQueenifierConfirming,
    queueProductMerge, setProductQueenifierError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerQueenifier);