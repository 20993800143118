import { keyBy } from 'lodash';
import format from 'date-fns/format';
import { dateFilterComparator } from '../../common/helpers';

export const companyUsageGridColumns = keyBy([
    {
        headerName: 'Company',
        field: 'company',
        width: 250,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        sortable: true,
    },
    {
        headerName: 'ID',
        field: 'company_id',
        filter: 'agNumberColumnFilter',
    },
    {
        headerName: 'Type',
        field: 'type',
        filter: 'agTextColumnFilter',
        sortable: true,
    },
    {
        headerName: 'Start',
        field: 'start_date',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueGetter: ({ data }) => {
            return data && data.start_date ? format(data.start_date, "MM/DD/YYYY") : null;
        },
        filterParams: { comparator: dateFilterComparator },
    },
    {
        suppressMenu: true,
        sortable: true,
        headerName: 'Status',
        field: 'service_status',
        filter: 'agTextColumnFilter',
        filterParams: { newRowsAction: 'keep' },
    },
    {
        filter: false,
        headerName: 'Max Usr',
        field: 'max_users',
        type: 'numericColumn',
        sortable: false,
    },
    {
        filter: false,
        headerName: 'Ttl Usr',
        field: 'total_users',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        headerName: 'Usr R12',
        field: 'active_users',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Usr R3',
        field: 'active_users_r3',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Usr R1',
        field: 'active_users_r1',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R3 Chg',
        field: 'users_r3_change',
        cellRenderer: 'coloredNumberRenderer',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R1 Chg',
        field: 'users_r1_change',
        cellRenderer: 'coloredNumberRenderer',
        type: 'numericColumn',
        sortable: true,
    },
    {
        headerName: 'Ttl Srch',
        field: 'total_searches',
        suppressMenu: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Avg/Mo',
        field: 'avg_searches',
        cellRenderer: 'coloredNumberRenderer',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Sch R3',
        field: 'searches_r3',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Sch R1',
        field: 'searches_r1',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R3 Chg',
        field: 'searches_r3_change',
        cellRenderer: 'coloredNumberRenderer',
        type: 'numericColumn',
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R1 Chg',
        field: 'searches_r1_change',
        cellRenderer: 'coloredNumberRenderer',
        type: 'numericColumn',
        sortable: true,
    }
], 'field');

export const hiddenCompanyUsageGridColumns = [
    'max_users',
    'active_users_r3',
    'active_users_r1',
    'searches_r3',
    'searches_r1',
    'company_id',
];


export const companyUserGridColumns = keyBy([
    {
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'cellWithCopyButton',
        cellStyle: { "white-space": "normal" },
        width: 250,
        filter: 'agTextColumnFilter',
        sortable: true,
    },
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'Email',
        cellRenderer: 'cellWithCopyButton',
        cellStyle: { "white-space": "normal" },
        minWidth: 200,
        field: 'email',
        filter: 'agTextColumnFilter',
        sortable: true,
    },
    {
        sortable: true,
        headerName: 'Status',
        field: 'service_status',
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'First Search',
        field: 'first_search',
        filter: 'agDateColumnFilter',
        valueGetter: ({ data }) => {
            return data && data.first_search ? format(data.first_search, "MM/DD/YYYY") : null;
        },
        filterParams: { comparator: dateFilterComparator },
    },
    {
        headerName: 'Last Search',
        field: 'last_search',
        filter: 'agDateColumnFilter',
        valueGetter: ({ data }) => {
            return data && data.last_search ? format(data.last_search, "MM/DD/YYYY") : null;
        },
        filterParams: { comparator: dateFilterComparator },
    },
    {
        filter: false,
        headerName: '#Search',
        field: 'total_searches',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Avg/Mo',
        field: 'avg_searches',
        cellRenderer: 'coloredNumberRenderer',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Sch R3',
        field: 'searches_r3',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Sch R1',
        field: 'searches_r1',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R3 Chg',
        field: 'searches_r3_change',
        cellRenderer: 'coloredNumberRenderer',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'R1 Chg',
        field: 'searches_r1_change',
        cellRenderer: 'coloredNumberRenderer',
        cellStyle: { textAlign: "right" },
        sortable: true,
    },
], 'field');

export const hiddenCompanyUserGridColumns = [
    'id'
];