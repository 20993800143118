import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Segment, List, Label, Message } from 'semantic-ui-react';

import { loadUpdatesForProduct, deleteUpdateForProduct, minimizeUpdateList } from '../actions';

class ProductContainerUpdateList extends React.Component {
    state = {
        hoveredUpdate: null,
    };

    componentWillMount () {
        const { product } = this.props;
        
        if (!product)
            return;
        
        this.props.loadUpdatesForProduct(product.product_id);
    };

    render () {
        const { updates, isLoading, error, product, minimized } = this.props;

        return (
            <Segment>
                <Label
                    ribbon
                    color="violet"
                    content={`${(!isLoading && updates.length > 0) ? updates.length +' ' : '' }Updates of #${product.product_id}`}
                />

                <a
                    href="/"
                    className="fr"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.minimizeUpdateList(!minimized);
                    }}
                >
                    {isLoading ? (
                        <Icon name="spinner" loading />
                    ) : (
                        <Icon
                            name={`window maximize${minimized ? ' outline' : ''}`}
                            floated="right"
                        />
                    )}
                </a>
                
                {!minimized && (
                    <div className="tp-15 tm-15 border-t-1 border-grey border-solid">
                        {error && (<Message header="Something went wrong!" icon="warning sign" content={error} error={true}/>)}
                        
                        <div
                            className="rp-10"
                            style={{ overflowY: 'scroll', maxHeight: '320px' }}>
                            <List 
                                divided 
                                relaxed 
                                verticalAlign='middle'>
                                {updates.map(update => (
                                    <List.Item 
                                        key={`${update.id}`}
                                        onMouseLeave={() => this.setState(({ hoveredUpdate }) => {
                                            if (hoveredUpdate === update.id)
                                                return {hoveredUpdate: null};
                                        })}
                                        onMouseEnter={() => this.setState(({ hoveredUpdate }) => {
                                            if (hoveredUpdate !== update.id)
                                                return { hoveredUpdate: update.id };
                                        })}>
                                        <List.Content>
                                            <List.Header>
                                                <Label
                                                    size="mini"
                                                    content={`#${update.id}`}
                                                />
                                                &nbsp;On {format(update.create_date, 'DD MMM, YY')} {update.admin && `By ${update.admin.name}`}
                                            </List.Header>
                                            <List.Description>
                                                { this.state.hoveredUpdate === update.id 
                                                    ? update.update_sql 
                                                    : `${update.update_sql.substr(0, 40)}...` }
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>
                        </div>
                    </div>
                )}
            </Segment>
        );
    };
};

ProductContainerUpdateList.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productUpdate }) => ({
    minimized: productUpdate.minimized,
    isLoading: productUpdate.isLoading,
    updates: productUpdate.updates,
    error: productUpdate.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUpdatesForProduct, deleteUpdateForProduct, minimizeUpdateList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerUpdateList);