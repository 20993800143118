import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import {
    AGREEMENT_GRID_DATA, 
    AGREEMENT_GRID_ERROR,
    AGREEMENT_GRID_LOADING, 
} from '../constants';
import { makePrivateApiCall } from '../../common/api';
import { downloadFileFromBlob } from '../../common/helpers';

export const loadAgreementGridData = (params={}) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    params.env = moduleEnv.company;

    dispatch({
        type: AGREEMENT_GRID_LOADING
    });

    makePrivateApiCall({
        url: '/company/agreement',
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: AGREEMENT_GRID_DATA,
                payload: { agreements: res.data.rows }
            });
        }
    }).catch(err => {
        dispatch({
            type: AGREEMENT_GRID_ERROR,
            payload: err.message
        });
    });
};

export const downloadAgreementImage = (agreement) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    const params = { env: moduleEnv.company };

    return makePrivateApiCall({
        url: `/company/agreement/${agreement.id}/image`,
        method: 'get', responseType: 'blob', params,
    }).then((res) => {
        downloadFileFromBlob(res.data, `signed_agreement_image_${agreement.id}.jpg`);
    }).catch((err) => {
        console.log(err);

        toast({
            type: 'error',
            icon: 'close',
            title: 'Download Error',
            description: get(err, 'response.data.message', null) || err.message,
        });
    });
};