import { ACTIVITY_PERFROMED, ActivitiesToTrack } from "./constants";

export const activityMiddlewareLogger = (store) => next => action => {    
    if (typeof action !== 'function' && ActivitiesToTrack.indexOf(action.type) >= 0) {
        store.dispatch({
            type: ACTIVITY_PERFROMED,
            payload: action,
        });
    }

    return next(action);
};