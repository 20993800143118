import { toast } from 'react-semantic-toasts';

import {
    CHECK_DATA, CHECK_LOADING, CHECK_ERROR, CHECK_DELETED,
    CHECK_DETAIL_SHOW, CHECK_DETAIL_HIDE,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';
import { authExpire } from '../../auth/actions';

export const loadChecks = () => dispatch => {
    dispatch({
        type: CHECK_LOADING
    });

    makePrivateApiCall({
        url: '/check',
        method: 'get',
    }).then(res => {
        if (res.data) {
            const { rows: checks } = res.data;

            dispatch({
                type: CHECK_DATA,
                payload: checks
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: CHECK_ERROR,
            payload: err.message
        });
    });
};

export const showCheckDetails = (check) => dispatch => {
    dispatch({
        type: CHECK_DETAIL_SHOW,
        payload: check,
    });
};

export const hideCheckDetails = (check) => dispatch => {
    dispatch({
        type: CHECK_DETAIL_HIDE,
        payload: check,
    });
};

export const deleteCheck = (check) => dispatch => {
    dispatch({
        type: CHECK_LOADING
    });

    makePrivateApiCall({
        url: `/check/${check.check_id}`,
        method: 'delete',
    }).then(res => {
        if (res.data) {
            dispatch({
                type: CHECK_DELETED,
                payload: check
            });

            toast({
                time: 3000,
                type: 'success',
                icon: 'trash alternate',
                title: 'Check Removed',
                description: `Successfully removed check #${check.check_id}`,
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: CHECK_ERROR,
            payload: err.message
        });
    });
};