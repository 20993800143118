import { sortBy } from 'lodash';

import {
    PRODUCT_UPC_DELETED, PRODUCT_UPC_MINIMIZE, PRODUCT_UPC_VARIATIONS_DELETED,
    PRODUCT_UPC_CREATING, PRODUCT_UPC_CREATED, PRODUCT_UPC_CREATE_ERROR,
    PRODUCT_UPC_DATA, PRODUCT_UPC_LOADING, PRODUCT_UPC_ERROR, PRODUCT_UPC_DELETING, PRODUCT_UPC_SORT, 
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    upcs: [],
    error: null,
    isCreating: false,
    createError: null,
    minimized: false,
    isLoading: false,
    sortOrder: 'ASC',
    sortColumn: 'upc',
    isDeletingUpcVariation: false,
};

const setData = (state, { rows }) => ({ ...state, error: null, isLoading: false, upcs: rows, });

const setDeleted = (state, deleted) => ({
    ...state,
    error: null,
    isLoading: false,
    upcs: state.upcs.filter(({ upc }) => deleted !== upc),
});

const deleteVariation = (state, variations) => ({
    ...state,
    error: null,
    isDeletingUpcVariation: false,
    upcs: state.upcs.filter(({ upc }) => variations.indexOf(upc) < 0),
});

const setError = (state, error) => ({ ...state, isLoading: false, error, });

const setLoading = (state) => ({ ...state, isLoading: true, });

const setDeleting = (state) => ({ ...state, isDeletingUpcVariation: true });

const setMinimize = (state, minimized) => ({ ...state, minimized });

const setCreated = (state, upcs) => ({
    ...state,
    isCreating: false,
    createError: null,
    upcs: [...upcs, ...state.upcs]
});

const setCreateError = (state, data) => ({
    ...state,
    isCreating: false,
    createError: data.messages || data.message,
});

const setCreating = (state) => ({ ...state, isCreating: true, });

const sortUpcs = (state, {column, order}) => {
    const { upcs } = state;
    let sorted = sortBy(upcs, [column]);

    if (order === 'DESC') {
        sorted = sorted.reverse();
    }

    return { ...state, upcs: sorted, sortColumn: column, sortOrder: order };
};

const reducers = {
    [PRODUCT_UPC_DATA]: setData, 
    [PRODUCT_UPC_DELETED]: setDeleted, 
    [PRODUCT_UPC_VARIATIONS_DELETED]: deleteVariation, 
    [PRODUCT_UPC_ERROR]: setError, 
    [PRODUCT_UPC_LOADING]: setLoading, 
    [PRODUCT_UPC_DELETING]: setDeleting, 
    [PRODUCT_UPC_MINIMIZE]: setMinimize, 
    [PRODUCT_UPC_CREATED]: setCreated, 
    [PRODUCT_UPC_CREATE_ERROR]: setCreateError, 
    [PRODUCT_UPC_CREATING]: setCreating, 
    [PRODUCT_UPC_SORT]: sortUpcs,
};

export default generateReducer(reducers, initialState);