import { 
    AUTH_SIGNIN_REQUEST, AUTH_SIGNIN_SUCCESS, 
    AUTH_SIGNIN_INPUT_CHANGE, AUTH_SIGNIN_INPUT_ERROR, 
    AUTH_SIGNUP_REQUEST, AUTH_SIGNUP_SUCCESS, 
    AUTH_SIGNUP_INPUT_CHANGE, AUTH_SIGNUP_INPUT_ERROR,
    AUTH_RESET_INPUT_CHANGE, AUTH_TOGGLE_PASSWORD_FIELD,
    AUTH_SIGNOUT, AUTH_EXPIRE, 
    AUTH_PROFILE_UPDATING, 
    AUTH_PROFILE_UPDATED, 
    AUTH_PROFILE_UPDATE_ERROR
} from "../constants";

const initialState = {
    signinErrors: null,
    signupErrors: null,
    signingIn: true,
    signingUp: false,
    showPassword: false,
    resetInputs: {
        email: '',
    },
    signinInputs: {
        email: '',
        password: ''
    },
    signupInputs: {
        name: '',
        title: '',
        email: '',
        password: '',
        confirm_password: '',
    },
    user: null,
    expired: false,

    profileUpdating: false,
    profileUpdateErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SIGNIN_REQUEST:
            return {
                ...state,
                signingIn: true,
                signinErrors: null,
            };

        case AUTH_EXPIRE:
            return {
                ...state,
                expired: true,
            };

        case AUTH_TOGGLE_PASSWORD_FIELD:
            return {
                ...state,
                showPassword: !state.showPassword,
            };

        case AUTH_SIGNIN_INPUT_CHANGE:
            return {
                ...state,
                signinInputs: { ...state.signinInputs, ...action.payload }
            };

        case AUTH_SIGNIN_INPUT_ERROR:
            return {
                ...state, 
                signingIn: false,
                signinErrors: action.payload,
            };

        case AUTH_SIGNIN_SUCCESS: 
            return {
                ...state,
                expired: false,
                signingIn: false,
                signinErrors: null,
                user: action.payload,
            };

        case AUTH_SIGNUP_REQUEST:
            return {
                ...state,
                signingUp: true,
                signupErrors: null,
            };

        case AUTH_SIGNUP_INPUT_CHANGE:
            return {
                ...state,
                signupInputs: { ...state.signupInputs, ...action.payload }
            };

        case AUTH_SIGNUP_INPUT_ERROR:
            return {
                ...state,
                signingUp: false,
                signupErrors: action.payload,
            };

        case AUTH_RESET_INPUT_CHANGE:
            return {
                ...state,
                resetInputs: { ...state.resetInputs, ...action.payload }
            };

        case AUTH_SIGNUP_SUCCESS: 
            return {
                ...state,
                signingUp: false,
                signupErrors: null,
                user: action.payload,
            };

        case AUTH_SIGNOUT:
            return {
                ...initialState,
                signingIn: false,
                signingUp: false,
            };

        case AUTH_PROFILE_UPDATING:
            return {
                ...state,
                profileUpdating: action.payload,
            };

        case AUTH_PROFILE_UPDATED:
            return {
                ...state,
                user: action.payload,
                profileUpdating: false,
            };

        case AUTH_PROFILE_UPDATE_ERROR:
            return {
                ...state,
                profileUpdating: false,
                profileUpdateErrors: action.payload,
            };

        default:
            return state;
    };
};