import { AUTH_SIGNIN_SUCCESS } from "../auth/constants";
import { 
    PRODUCT_MATCH_GRID_COLUMN_SAVED,
    PRODUCT_GRID_SELECT_PRODUCT, 
    PRODUCT_UNTANGLER_COMPLETE, 
    PRODUCT_QUEENIFIER_QUEUED,
    PRODUCT_EDITOR_SAVED,
    PRODUCT_GRID_COLUMN_SAVED,

    PRODUCT_UPC_VARIATIONS_DELETED,
    PRODUCT_UPC_DELETED,
    PRODUCT_UPC_CREATED,
} from "../product/constants";
import { COMPANY_EDITOR_SAVED } from "../company/constants";

export const ACTIVITY_PERFROMED = 'ACTIVITY_PERFROMED';
export const ACTIVITY_TOGGLE_HISTORY = 'ACTIVITY_TOGGLE_HISTORY';

export const ActivitiesToTrack = [
    AUTH_SIGNIN_SUCCESS,
    
    PRODUCT_EDITOR_SAVED,
    PRODUCT_QUEENIFIER_QUEUED,
    PRODUCT_UNTANGLER_COMPLETE,
    PRODUCT_GRID_COLUMN_SAVED,
    PRODUCT_GRID_SELECT_PRODUCT,
    PRODUCT_MATCH_GRID_COLUMN_SAVED,

    PRODUCT_UPC_CREATED,
    PRODUCT_UPC_DELETED,
    PRODUCT_UPC_VARIATIONS_DELETED,

    COMPANY_EDITOR_SAVED,
];