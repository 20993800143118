import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Dropdown } from 'semantic-ui-react';

import { selectCompanyListOption } from '../actions';

const CompanyContainerSelector = ({ 
    companyOptions, companiesLoading, 
    onSelect, selectCompanyListOption, ...otherProps 
}) => (
    <Form.Field
        fluid
        search
        selection
        label="Company"
        control={Dropdown}
        autoComplete="off"
        selectOnBlur={false}
        options={companyOptions}
        loading={companiesLoading}
        placeholder='Select Company'
        noResultsMessage='No Company found.'
        onChange={(e, {value}) => {
            onSelect(value); 
            selectCompanyListOption(value)
        }}
        {...otherProps}
    />
);

const mapStateToProps = ({ companyList }) => ({
    companyOptions: companyList.options.map(c => ({
        key: c.id, value: c.id, text: c.company,
        description: `${c.distributor} | ${c.service_level}`,
    })),
    companiesLoading: companyList.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    selectCompanyListOption
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainerSelector);