import {
    PRODUCT_CAT_PICKER_LOADING,
    PRODUCT_CAT_PICKER_ERROR,
    PRODUCT_CAT_PICKER_SELECT,
    PRODUCT_CAT_PICKER_DATA,
} from './constants';

const initialState = {
    isLoading: false,
    selected: null,
    subcategories: [],
    error: null,
};

const setLoading = (state) => ({ ...state, isLoading: true });
const setError = (state, error) => ({ ...state, error, isLoading: false });
const setData = (state, subcategories) => ({ ...state, isLoading: false, subcategories });
const selectData = (state, selected) => ({ ...state, selected });

const reducers = {
    [PRODUCT_CAT_PICKER_LOADING]: setLoading,
    [PRODUCT_CAT_PICKER_ERROR]: setError,
    [PRODUCT_CAT_PICKER_SELECT]: selectData,
    [PRODUCT_CAT_PICKER_DATA]: setData,
};

export default (state = initialState, action) => {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload);
    }

    return state;
};