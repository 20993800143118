import { difference } from 'lodash';

import { 
    PRODUCT_GRID_DATA, 
    PRODUCT_GRID_ERROR, 
    PRODUCT_GRID_LOADING, 
    PRODUCT_GRID_COLUMN_TOGGLE, 
    PRODUCT_GRID_PAGE_CHANGE, 
    PRODUCT_GRID_STATE_CHANGE, 
    PRODUCT_GRID_SELECT_PRODUCT,
    PRODUCT_GRID_CONTROL_TOGGLE,
    PRODUCT_GRID_COLUMN_CHANGE_STATE,
} from "../constants";
import { allGridColumns, hiddenGridColumns } from "../helpers";
import { generateReducer } from '../../common/helpers';

const initialState = {
    error: null,
    products: [],
    isLoading: false,
    selected: null,
    showControl: false,
    hiddenColumns: hiddenGridColumns,
    shownColumns: difference(Object.keys(allGridColumns), hiddenGridColumns),
    currentPage: 1,
    lastRow: null,
    pageSize: 15,
    sortModel: [],
    filterModel: {},
    columnState: null,
    columnDefs: Object.values(allGridColumns),
};

const setGridData = (state, {products, lastRow}) => ({
    ...state,
    error: null,
    isLoading: false,
    lastRow, products,
});

const toggleControl = (state) => ({ ...state, showControl: !state.showControl });
const selectGridProduct = (state, selected) => ({ ...state, selected });
const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const changeGridState = (state, payload) => ({ ...state, ...payload, });
const setGridLoading = (state) => ({ ...state, isLoading: true, });
const setGridError = (state, error) => ({ ...state, isLoading: false, error, });
const toggleGridColumn = (state, hiddenColumns) => {
    const shownColumns = difference(Object.keys(allGridColumns), hiddenColumns);

    return { ...state, shownColumns, hiddenColumns, };
};
const changeColumnState = (state, columnState) => ({...state, columnState});

const reducers = {
    [PRODUCT_GRID_DATA]: setGridData,
    [PRODUCT_GRID_ERROR]: setGridError,
    [PRODUCT_GRID_LOADING]: setGridLoading,

    [PRODUCT_GRID_CONTROL_TOGGLE]: toggleControl,

    [PRODUCT_GRID_PAGE_CHANGE]: changeGridPage,
    [PRODUCT_GRID_STATE_CHANGE]: changeGridState,
    [PRODUCT_GRID_SELECT_PRODUCT]: selectGridProduct,

    [PRODUCT_GRID_COLUMN_TOGGLE]: toggleGridColumn,
    [PRODUCT_GRID_COLUMN_CHANGE_STATE]: changeColumnState,
};

export default generateReducer(reducers, initialState);