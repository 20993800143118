import {
    STATE_SNAPSHOT_LOADING, STATE_SNAPSHOT_DATA, STATE_SNAPSHOT_ERROR,
} from "../constants";

const initialState = {
    error: null,
    snapshots: [],
    isLoading: false,
};

const setData = (state, {rows: snapshots}) => ({
    ...state, error: null,
    isLoading: false, snapshots,
});

const setError = (state, error) => ({ ...state, isLoading: false, error, });
const setLoading = (state) => ({ ...state, isLoading: true });

const reducers = {
    [STATE_SNAPSHOT_LOADING]: setLoading,
    [STATE_SNAPSHOT_ERROR]: setError,
    [STATE_SNAPSHOT_DATA]: setData,
};

export default (state = initialState, action) => {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload);
    }

    return state;
};