import { difference } from 'lodash';

import {
    PRODUCT_MATCH_GRID_DATA, PRODUCT_MATCH_GRID_ERROR, PRODUCT_MATCH_GRID_LOADING,
    PRODUCT_MATCH_GRID_COLUMN_TOGGLE, PRODUCT_MATCH_GRID_PAGE_CHANGE,
    PRODUCT_MATCH_GRID_STATE_CHANGE, PRODUCT_MATCH_GRID_SELECT_MATCH,
} from "../constants";
import { matchGridColumns } from "./columns";

const initialState = {
    error: null,
    selected: null,
    matches: [],
    isLoading: false,
    hiddenColumns: [],
    shownColumns: Object.keys(matchGridColumns),
    currentPage: 1,
    lastRow: null,
    pageSize: 15,
    sortModel: [],
    filterModel: {},
    columnDefs: Object.values(matchGridColumns),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_MATCH_GRID_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                matches: action.payload.matches,
                lastRow: action.payload.lastRow,
            };

        case PRODUCT_MATCH_GRID_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case PRODUCT_MATCH_GRID_COLUMN_TOGGLE:
            const hiddenColumns = action.payload;
            const shownColumns = difference(Object.keys(matchGridColumns), action.payload);

            return {
                ...state,
                shownColumns,
                hiddenColumns,
            };

        case PRODUCT_MATCH_GRID_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case PRODUCT_MATCH_GRID_STATE_CHANGE:
            return {
                ...state,
                ...action.payload,
            };

        case PRODUCT_MATCH_GRID_PAGE_CHANGE:
            return {
                ...state,
                currentPage: action.payload,
            };

        case PRODUCT_MATCH_GRID_SELECT_MATCH:
            return {
                ...state,
                selected: action.payload,
            };

        default:
            return state;
    }
};