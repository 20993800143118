export const REPORT_LIST_DATA = "REPORT_LIST_DATA";
export const REPORT_LIST_ERROR = "REPORT_LIST_ERROR";
export const REPORT_LIST_LOADING = "REPORT_LIST_LOADING";
export const REPORT_LIST_DETAIL_SHOW = "REPORT_LIST_DETAIL_SHOW";
export const REPORT_LIST_DETAIL_HIDE = "REPORT_LIST_DETAIL_HIDE";
export const REPORT_LIST_TOGGLE_SUB_COUNT_FILTER = "REPORT_LIST_TOGGLE_SUB_COUNT_FILTER";

export const REPORT_STATS_DATA = "REPORT_STATS_DATA";
export const REPORT_STATS_ERROR = "REPORT_STATS_ERROR";
export const REPORT_STATS_LOADING = "REPORT_STATS_LOADING";
export const REPORT_STATS_DATE_RANGE = "REPORT_STATS_DATE_RANGE";

export const ReportEmailEvents = {
    OPEN: 'open',
    CLICK: 'click',
    DELIVERED: 'delivered',
};

export const ReportFormats = {
    EXCEL: 'excel',
    CSV: 'csv',
};