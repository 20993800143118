import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header, Form, Icon, Button, Grid } from 'semantic-ui-react';

import { companyUserGridColumns } from './columns';
import {
    hideCompanyUserGridColumn,
    toggleCompanyUserGridControl
} from './user-grid-actions';

import SelectedCompanyBadge from '../containers/selected-badge';
import GridColumnPicker from '../../common/components/grid-column-picker';

const UsageUserGridControl = ({
    hiddenColumns, selectedCompany, showControl, isLoading,
    hideCompanyUserGridColumn, toggleCompanyUserGridControl, onDownload
}) => {
    if (!showControl) {
        return (
            <Segment vertical clearing>
                <Button 
                    size="mini"
                    icon="users" 
                    color="grey"
                    loading={isLoading}
                    content="Usage by users"
                />
                <SelectedCompanyBadge />

                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleCompanyUserGridControl}
                >
                    Configure Grid
                </Button>

                <Button
                    size="mini"
                    icon="download"
                    floated="right"
                    onClick={onDownload}
                />
            </Segment>
        );
    }

    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name="users"/>
                            <Header.Content>
                                { selectedCompany.company } Users
                                <Header.Subheader>
                                    Usage by user. Configure The Grid, select a company to see their details, settings etc.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleCompanyUserGridControl}
                        >
                            Hide
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form className="tm-15">
                <Form.Group>
                    <Form.Field width={10}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker
                            hiddenColumns={hiddenColumns}
                            columns={companyUserGridColumns}
                            onChange={hideCompanyUserGridColumn}
                        />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label>Users from</label>
                        <SelectedCompanyBadge />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ companyUsageGrid, companyUserGrid }) => ({
    isLoading: companyUserGrid.isLoading,
    showControl: companyUserGrid.showControl,
    hiddenColumns: companyUserGrid.hiddenColumns,

    selectedCompany: companyUsageGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hideCompanyUserGridColumn,
    toggleCompanyUserGridControl,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsageUserGridControl);