import Joi from 'joi-browser';
import { omit } from 'lodash';
import { UserSubscriptionStatuses } from "../constants";
import { CompanyServiceLevels } from "../../company/constants";

const schema = {
    id: Joi.number().required(),
    name: Joi.string().trim().required(),
    company_id: Joi.number().required(),
    power_user: Joi.boolean().required(),
    password: Joi.when('service_level', {
        is: Joi.string().valid('Report'),
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.string().trim().min(5).required()
    }),
    email: Joi.string().email().trim().required(),
    force_change_password: Joi.boolean().required(),
    service_premises: Joi.array().min(1).max(2).required(),
    rndc_division: Joi.string().trim().optional().allow(''),
    service_product_type: Joi.array().min(1).max(2).required(),
    service_level: Joi.string().valid(Object.values(CompanyServiceLevels)).required(),
    service_status: Joi.string().valid(Object.values(UserSubscriptionStatuses)).required(),
};

export const validateUserCreate = (client) => {
    return Joi.validate(client, omit(schema, ['id']), {
        convert: true,
        stripUnknown: true,
    });
};

export const validateUserUpdate = (client) => {
    const editSchema = omit(schema, ['password']);

    editSchema.password = Joi.string().optional().min(5).allow('');

    return Joi.validate(client, editSchema, {
        convert: true,
        stripUnknown: true,
    });
};