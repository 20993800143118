import React from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header, Form, Icon, Button, Grid } from 'semantic-ui-react';

import { selectProductUpc } from '../upc-picker/action';
import { allGridColumns, applyUpcFilter, applyTypeFilter } from '../helpers';
import { hideProductGridColumn, changeProductGridState, toggleProductGridControl, } from '../actions';

import ProductUpcPicker from '../upc-picker/container';
import ProductTypeFilters from '../components/type-filters';
import SelectedProductBadge from './selected-product-badge';
import GridColumnPicker from '../../common/components/grid-column-picker';
import GridPageSizePicker from '../../common/components/grid-page-size-picker';

const ProductContainerGridControl = ({
    pageSize, showControl, hiddenColumns, filterModel,
    toggleProductGridControl, changeProductGridState, hideProductGridColumn, resetFilters
}) => {
    if (!showControl) {
        return (
            <Segment vertical clearing>
                <SelectedProductBadge />

                <Button 
                    size="mini"
                    floated="right" 
                    onClick={toggleProductGridControl}
                >
                    Configure Grid
                </Button>

                {!isEmpty(filterModel) && (
                    <Button 
                        size="mini"
                        icon="filter"
                        content="Clear"
                        floated="right" 
                        onClick={resetFilters}
                    >
                    </Button>
                )}

                <GridPageSizePicker
                    button
                    pointing="top"
                    pageSize={pageSize} 
                    text={`${pageSize}/page`}
                    className="mini right floated" 
                    onChange={changeProductGridState}
                />

                <ProductTypeFilters
                    button
                    pointing="top"
                    className="mini right floated"
                    currentFilter={get(filterModel, 'product_type', {})}
                    onSelect={(type) => changeProductGridState(applyTypeFilter(filterModel, type))}
                />

                <ProductUpcPicker
                    dropdownProps={{
                        clearable: true, 
                        className: "mini fr", 
                        placeholder: "Upc Filter",
                    }}
                    onChange={(data) => changeProductGridState(applyUpcFilter(filterModel, data ? data.upc : null))}
                />
            </Segment>
        );
    }
    
    return (
        <Segment vertical>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header>
                            <Icon name="table" />
                            <Header.Content>
                                Products Grid
                                <Header.Subheader>
                                    Configure The Grid, select a product to see their search, settings etc.
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button 
                            size="mini"
                            floated="right" 
                            onClick={toggleProductGridControl}
                        >
                            Hide
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form className="tm-15">
                <Form.Group>
                    <Form.Field width={12}>
                        <label>Select Columns to Hide</label>
                        <GridColumnPicker
                            columns={allGridColumns}
                            hiddenColumns={hiddenColumns}
                            onChange={hideProductGridColumn}
                        />
                    </Form.Field>

                    <Form.Field width={4}>
                        <label>Products Per Page</label>
                        <GridPageSizePicker 
                            fluid
                            selection
                            pageSize={pageSize} 
                            onChange={changeProductGridState}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field width={6}>
                        <label>Selected Product</label>
                        <SelectedProductBadge />
                    </Form.Field>
                    
                    <Form.Field width={2}>
                        <label>Filters</label>
                        <Button
                            fluid
                            icon="filter"
                            content="Clear"
                            onClick={resetFilters}
                            disabled={isEmpty(filterModel)}
                        >
                        </Button>
                    </Form.Field>
                    
                    <Form.Field width={4}>
                        <label>Product Types</label>
                        <ProductTypeFilters
                            fluid
                            selection
                            onSelect={(type) => changeProductGridState(applyTypeFilter(filterModel, type))}
                            currentFilter={get(filterModel, 'product_type', {})}
                            />
                    </Form.Field>

                    <Form.Field width={4}>
                        <label>Product Upc</label>
                        <ProductUpcPicker
                            dropdownProps={{ 
                                width: 4, 
                                fluid: true,
                                clearable: true 
                            }}
                            onChange={(data) =>  changeProductGridState(applyUpcFilter(filterModel, data ? data.upc : null))}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

const mapStateToProps = ({ productGrid }) => ({
    pageSize: productGrid.pageSize,
    showControl: productGrid.showControl,
    filterModel: productGrid.filterModel,
    hiddenColumns: productGrid.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hideProductGridColumn, toggleProductGridControl, changeProductGridState,
    selectProductUpc
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerGridControl);