import { oneOf, shape, number, string, } from 'prop-types';

export const ReportType = shape({
    id: number.isRequired,
    active: number.isRequired,
    supplier: string.isRequired,
    distributor: string.isRequired,
    description: string.isRequired,
    frequency: oneOf(['weekly', 'monthly']).isRequired,
   
    totalFilesOpened: number,
});