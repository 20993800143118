import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header, Divider, Icon, } from 'semantic-ui-react';

import { getLogFilePath } from '../services/helpers';
import BotComponentLogViewer from '../components/log-viewer';
import { loadLogPage, toggleLogViewer, downloadFile } from '../actions';

const BotContainerLogViewer = ({ 
    bot, logContent, 
    loadLogPage, onClose, toggleLogViewer, downloadFile,
}) => {
    return (
        <>
            <Header
                as="h4"
                attached="top"
            >
                <Header.Content>
                    {bot.name}
                </Header.Content>

                <a
                    href="/"
                    className="fr"
                    onClick={(e) => {
                        e.preventDefault();
                        onClose();
                    }}
                >
                    <Icon
                        name="delete"
                        floated="right"
                    />
                </a>
                
                <Divider hidden clearing fitted />
            </Header>
            <Segment attached="bottom">
                {bot.hasNestedLogs ? (
                    <>
                        {bot.nestedLogs.map(nestedFilePath => {
                            const filePath = getLogFilePath(bot, nestedFilePath);

                            return (
                                <BotComponentLogViewer 
                                    onLoadNextPage={(page) => loadLogPage(bot, nestedFilePath, page)}
                                    onToggleViewer={() => toggleLogViewer(bot, nestedFilePath)}
                                    onDownloadFile={() => downloadFile(bot, nestedFilePath)}
                                    log={logContent[filePath]}
                                    filePath={nestedFilePath}
                                    key={filePath}
                                />
                            );
                        })}
                    </>
                ) : (
                        <BotComponentLogViewer
                            onLoadNextPage={(page) => loadLogPage(bot, null, page)}
                            log={logContent[`${bot.dir}${bot.filename}`]}
                            onToggleViewer={() => toggleLogViewer(bot)}
                            onDownloadFile={() => downloadFile(bot)}
                            key={`${bot.dir}${bot.filename}`}
                            filePath={bot.filename}
                        />
                    )}
            </Segment>
        </>
    );
};

const mapStateToProps = ({ botList }) => ({
    logContent: botList.logContent, 
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadLogPage, toggleLogViewer, downloadFile,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BotContainerLogViewer);