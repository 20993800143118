import React from 'react';
import { isEmpty } from 'lodash';
import AceEditor from 'react-ace';
import { Grid, Button } from 'semantic-ui-react';
import { Dropdown, Form, Input, TextArea } from 'formik-semantic-ui';

const CheckComponentForm = ({ handleChange, setFieldValue, values, errors, ...formProps }) => (
    <Grid>
        <Grid.Column mobile={16}>
            <Form.Field error={!!errors.check_query}>
                <label>Check Query</label>
                <AceEditor
                    tabSize={4}
                    mode="mysql"
                    width="100%"
                    fontSize={16}
                    height="200px"
                    theme="twilight"
                    name="check_query"
                    value={values.check_query}
                    enableLiveAutocompletion={true}
                    editorProps={{ $blockScrolling: Infinity }}
                    onChange={(text) => setFieldValue('check_query', text)}
                />
                <span>{errors.check_query}</span>
            </Form.Field>
        </Grid.Column>

        <Grid.Column mobile={16} computer={7}>
            <Dropdown
                label="Server"
                name="server"
                inputProps={{ defaultValue: "all" }}
                options={[
                    { text: 'Development', value: 'development' },
                    { text: 'Production', value: 'production' },
                    { text: 'All', value: 'all' },
                ]}
            />
            <Input
                inputProps={{ placeholder: "admin@ydrink.net" }}
                label="Author's Email"
                name="author_email"
            />
            <Input
                inputProps={{ placeholder: "A short description/title of this check" }}
                label="Description"
                name="description"
            />
        </Grid.Column>

        <Grid.Column mobile={16} computer={9}>
            <TextArea
                inputProps={{ placeholder: "Please reference any ticket number and explain what caused this check to be created" }}
                label="Note"
                name="note"
            />
            <TextArea
                inputProps={{ placeholder: "This is a query that can be run to fix this test. If no query is needed use: SELECT 1" }}
                label="Resolve Instructions"
                name="resolve_instructions"
            />
        </Grid.Column>

        <Grid.Column mobile={16}>
            <Button
                disabled={isEmpty(formProps.touched)}
                color="teal"
                type="submit"
            >
                Save Check
            </Button>
        </Grid.Column>
    </Grid>
);

export default CheckComponentForm;