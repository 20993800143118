import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'formik-semantic-ui';
import { debounce, isEqual, get } from 'lodash';

import { 
    loadProductSizes, selectProductSize,
} from './action';

const valueGetter = (size, sizeOnly=false) => {
    if (sizeOnly)
        return size.size;
    
    return `${size.size}${size.group ? ' - '+ size.group.product_size_group : ''}`;
};

const valueFromProduct = (product, sizeOnly=false) => {
    if (!product)
        return '';

    if (sizeOnly)
        return product.product_size;

    return `${product.product_size} - ${product.product_size_group}`;
};

class ProductSizePicker extends React.Component {
    componentDidUpdate(prevProps) {
        const prevSize = get(prevProps, 'product.product_size'),
            newSize = get(this.props, 'product.product_size');
            
        if (!isEqual(prevSize, newSize)) {
            this.loadInitialSuggestions();
        }
    };

    componentWillMount() {
        this.loadInitialSuggestions();
    };

    loadInitialSuggestions = () => {
        const { product, sizeOnly, loadProductSizes } = this.props;
        const keyword = valueFromProduct(product, sizeOnly).split(' - ')[0];
        loadProductSizes(keyword);
    };

    handleSearchInput = debounce((e, { searchQuery }) => {
        // console.log(e, data);
        this.props.loadProductSizes(searchQuery);
    }, 300);

    handleSelect = (e, { value }) => {
        const selected = this.props.sizes.find(g => valueGetter(g, this.props.sizeOnly) === value);

        this.props.selectProductSize(selected);

        if (this.props.onChange) {
            this.props.onChange(selected);
        }
    };

    render () {
        const {
            error, isLoading, dropdownProps,
            sizes, product, sizeOnly,
        } = this.props;

        const options = sizes.map(size => ({
            key: size.size_id + valueGetter(size, sizeOnly), text: valueGetter(size, sizeOnly),
            value: valueGetter(size, sizeOnly), description: `${size.per_9L_case}/9L${size.group ? ' | '+ size.group.product_size_tooltip : ''}`,
        }));

        return (
            <Form.Dropdown
                fluid
                search
                selection
                width={6}
                error={!!error}
                options={options}
                loading={isLoading}
                label="Product Size"
                onChange={this.handleSelect}
                onSearchChange={this.handleSearchInput}
                defaultSearchQuery={valueFromProduct(product, sizeOnly)}
                {...dropdownProps}
            />
        );
    };
};

const mapStateToProps = ({ productSizePicker }) => productSizePicker;

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductSizes,
    selectProductSize,
}, dispatch);

ProductSizePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    dropdownProps: PropTypes.object,
    product: PropTypes.object,
    sizeOnly: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSizePicker);

