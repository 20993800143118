import { isArray, isString, isEmpty, pick, cloneDeep } from "lodash";
import {
    CLIENT_EDITOR_INPUT, CLIENT_EDITOR_ERROR, 
    CLIENT_EDITOR_SAVING, CLIENT_EDITOR_SAVED, CLIENT_EDITOR_CONFIRMING, 
    CLIENT_EDITOR_CLIENT_ERROR, CLIENT_EDITOR_CLIENT_LOADING, CLIENT_EDITOR_CLIENT_DATA, CLIENT_EDITOR_CLIENT_RESET, 
    UserSettingAllowedValues,
    UserSubscriptionStatuses,
} from "../constants";

const defaultInputs = {
    name: '',
    email: '',
    password: '',
    company_id: '',
    power_user: false,
    force_change_password: true,
    service_status: UserSubscriptionStatuses.ACTIVE,
    service_premises: UserSettingAllowedValues.SERVICE_PREMISES,
    service_product_type: UserSettingAllowedValues.SERVICE_PRODUCT_TYPE,
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    isConfirming: false,
    isSaving: false,
    errors: [],

    client: null,
    clientError: null,
    isLoadingClient: false, 
};

const setError = (state, error) => {
    let {errors} = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors, };
};
const setConfirming = (state, client) => {
    // if client is not literally false, then set it to true
    const newState = { ...state, isConfirming: client !== false };

    if (client && !isEmpty(client)) {
        if (isArray(client.service_premises))
            client.service_premises = client.service_premises.join(',');

        if (isArray(client.service_product_type))
            client.service_product_type = client.service_product_type.join(',');

        newState.inputs = client;
    }

    return newState;
};
const setSaving = (state) => ({ ...state, isSaving: true });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    return {...state, inputs: {...state.inputs, ...inputs}};
};

const setClientLoading = (state) => ({...state, isLoadingClient: true, clientError: null});
const resetClient = (state) => ({
    ...state, client: null, clientError: null, 
    isLoadingClient: false, inputs: cloneDeep(defaultInputs)
});
const setClientData = (state, client) => {
    const inputs = pick(client, Object.keys(defaultInputs));
    inputs.password = '';
    inputs.id = client.id;
    inputs.power_user = inputs.power_user > 0;
    inputs.force_change_password = inputs.force_change_password > 0;

    // in our db, these values are saved as SET so multiple values are comma separated but on the client side, they need to be treated as arrays
    inputs.service_premises = inputs.service_premises.split(',');
    inputs.service_product_type = inputs.service_product_type.split(',');

    return {...state, isLoadingClient: false, client, inputs, clientError: null}
};
const setClientError = (state, err) => ({...state, isLoadingClient: false, client: null, clientError: err});

const reducers = {
    [CLIENT_EDITOR_CLIENT_LOADING]: setClientLoading,
    [CLIENT_EDITOR_CLIENT_ERROR]: setClientError,
    [CLIENT_EDITOR_CLIENT_DATA]: setClientData,
    [CLIENT_EDITOR_CLIENT_RESET]: resetClient,

    [CLIENT_EDITOR_CONFIRMING]: setConfirming,
    [CLIENT_EDITOR_SAVED]: resetState,
    [CLIENT_EDITOR_SAVING]: setSaving,
    [CLIENT_EDITOR_INPUT]: setInput,
    [CLIENT_EDITOR_ERROR]: setError,
};

export default (state = initialState, action) => {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload);
    }

    return state;
};