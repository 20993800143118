import Joi from 'joi-browser';

const schema = {
    product_id: Joi.number().required(),
    product: Joi.string().trim().uppercase().required(),
    brand: Joi.string().trim().uppercase().allow('').optional(),
    label: Joi.string().trim().uppercase().allow('').optional(),
    broker: Joi.string().trim().uppercase().allow('').optional(),
    supplier: Joi.string().trim().uppercase().allow('').optional(),
    distributor: Joi.string().trim().uppercase().allow('').optional(),

    product_size: Joi.string().trim().required(),
    product_size_group: Joi.string().trim().allow('').optional(),

    product_price_level: Joi.string().trim().allow('').optional(),
    product_type: Joi.string().trim().uppercase().required(),
    product_price: Joi.number().min(0).required(),

    product_category: Joi.string().trim().uppercase().allow('').optional(),
    product_sub_category: Joi.string().trim().uppercase().allow('').optional(),

    rndc_division: Joi.string().trim().optional().allow(''),
    rndc_id: Joi.number().optional(),
};

export const validateProductUpdate = (product) => {
    return Joi.validate(product, schema, {
        convert: true,
        stripUnknown: true,
    });
};