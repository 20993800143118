import {
    COMPANY_USER_GRID_DATA, COMPANY_USER_GRID_LOADING, COMPANY_USER_GRID_ERROR,
    COMPANY_USER_GRID_COLUMN_TOGGLE, COMPANY_USER_GRID_CONTROL_TOGGLE,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';

export const loadCompanyUserGridData = (params) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: COMPANY_USER_GRID_LOADING
    });

    params.env = moduleEnv.company;

    makePrivateApiCall({
        url: `/company/${params.id}/user/usage`,
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            const users = res.data;

            dispatch({
                type: COMPANY_USER_GRID_DATA,
                payload: { users }
            });
        }
    }).catch(err => {
        dispatch({
            type: COMPANY_USER_GRID_ERROR,
            payload: err.message
        });
    });
};

export const hideCompanyUserGridColumn = (columns) => dispatch => {
    dispatch({
        type: COMPANY_USER_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const toggleCompanyUserGridControl = () => dispatch => dispatch({
    type: COMPANY_USER_GRID_CONTROL_TOGGLE
});