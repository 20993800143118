import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Confirm, Modal, Table } from 'semantic-ui-react';

import { requestSaveCompany, saveCompany, } from '../actions';
import { getCompanyColumnText, getCompanyColumnValue } from '../helpers';

const CompanyContainerCreateConfirm = ({ 
    isConfirming, isSaving, inputs, company,
    saveCompany, requestSaveCompany, onClose,
}) => (
    <Confirm
        size="tiny"
        header="Save Company"
        open={isConfirming}
        confirmButton={{ content: "Save", loading: isSaving }}
        cancelButton="Nevermind"
        onConfirm={() => saveCompany(inputs, onClose)}
        onCancel={() => {requestSaveCompany(false); onClose(null)}}
        content={(
            <Modal.Content>
                Please verify that you want to <b>{inputs.id ? 'Update' : 'Create a new'}</b> company with the following data
                <br />

                <Table>
                    <Table.Body>
                        {Object.keys(inputs).map(key => (
                            <Table.Row key={key}>
                                <Table.Cell>
                                    {getCompanyColumnText(key)}
                                </Table.Cell>
                                <Table.Cell>
                                    {getCompanyColumnValue(key, inputs[key])}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>    
        )}
    />
);

const mapStateToProps = ({ companyEditor, companyList }) => ({
    inputs: companyEditor.inputs,
    isSaving: companyEditor.isSaving,
    isConfirming: companyEditor.isConfirming,

    company: companyList.selectedOption,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    requestSaveCompany, saveCompany,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainerCreateConfirm);