import React from 'react';
import { Form } from 'semantic-ui-react';

import ProductSizePicker from './container';

export default class ProductSizePickerAgGrid extends React.Component {
    state = {
        size: '',
    };

    componentWillMount () {
        this.setSize(this.props.value);
    };

    getValue() {
        return this.state.size;
    };

    isPopup() {
        return true;
    };

    setSize = (size) => {
        this.setState({ size });
    };

    handleChange = ({ size }) => {
        this.setState({ size }, () => this.props.api.stopEditing());
    };

    render() {
        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <ProductSizePicker 
                        sizeOnly={true}
                        onChange={this.handleChange}
                        dropdownProps={{ width: 16 }}
                        product={{ product_size: this.state.size }}
                    />
                </Form>
            </div>
        );
    }
}