import { difference } from 'lodash';

import {
    AGREEMENT_GRID_DATA, 
    AGREEMENT_GRID_ERROR, 
    AGREEMENT_GRID_LOADING,
} from "../constants";
import { generateReducer } from '../../common/helpers';
import { agreementGridColumns, hiddenAgreementGridColumns } from "./columns";

const initialState = {
    error: null,
    sortModel: [],
    agreements: [],
    filterModel: {}, 
    isLoading: false,
    hiddenColumns: hiddenAgreementGridColumns,
    columnDefs: Object.values(agreementGridColumns),
    shownColumns: difference(Object.keys(agreementGridColumns), hiddenAgreementGridColumns),
};

const loadData = (state, payload) => ({
    ...state, error: null, isLoading: false,
    agreements: payload.agreements, lastLoaded: new Date(),
});

const setError = (state, payload) => ({ ...state, isLoading: false, error: payload, });
const setLoading = (state) => ({ ...state, isLoading: true, });

const reducers = {
    [AGREEMENT_GRID_DATA]: loadData,
    [AGREEMENT_GRID_ERROR]: setError,
    [AGREEMENT_GRID_LOADING]: setLoading,
};

export default generateReducer(reducers, initialState);