import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Feed, Header, Divider, Icon, Message } from 'semantic-ui-react';

import { transformActivity } from '../helpers';
import { toggleActivityHistory } from '../actions';

const ActivityComponentHistory = ({ history, showHistory, toggleActivityHistory }) => {
    if (!showHistory)
        return null;

    return (
        <div className="overlay-container">
            <Segment clearing vertical>
                <Header floated='left'>
                    <Icon name="history" />
                    Your Activities
                </Header>
                <Header 
                    onClick={toggleActivityHistory}
                    className="clickable"
                    floated='right' 
                    icon="close" 
                    size="tiny" 
                />

                <Divider clearing />

                {history.length < 1 ? (
                    <Message
                        info
                        icon="history"
                        header="No activity logged"
                        content="Actions such as product untangle, edit, queenify or company create, edit etc. will be logged here."
                    />
                ) : (
                    <div className="activity-history-content">
                        <Feed>
                            {history.map(h => (
                                <Feed.Event 
                                    {...transformActivity(h)}
                                />    
                            ))}
                        </Feed>
                    </div>
                )}
            </Segment>
        </div>
    );
};

const mapStateToProps = ({ activity }) => ({
    history: activity.history,
    showHistory: activity.showHistory,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleActivityHistory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivityComponentHistory);