import React from 'react';
import { Form, Dropdown, Label } from 'semantic-ui-react';

import { UserSettingAllowedValues } from '../../user/constants';

export default class AgGridPremisePicker extends React.Component {
    state = {
        service_premises: '',
        error: null,
    };

    componentWillMount() {
        this.setPremises(this.props.value.split(','));
    };

    getValue() {
        return this.state.service_premises.join(',');
    };

    isPopup() {
        return true;
    };

    setPremises = (service_premises) => {
        if (!service_premises || service_premises.length < 1) {
            return this.setState({ error: 'Must select at least one value' });
        }

        this.setState({ service_premises, error: null });
    };

    handleChange = (service_premises) => {
        this.setState({ service_premises }, () => this.props.api.stopEditing());
    };

    render() {
        return (
            <div className="ag-grid-editor-segment">
                <Form>
                    <Form.Field>
                        <label>Premises</label>

                        <Dropdown
                            selection
                            fluid={true}
                            multiple={true}
                            value={this.state.service_premises}
                            onChange={(e, data) => this.setPremises(data.value)}
                            options={UserSettingAllowedValues.SERVICE_PREMISES.map(type => ({
                                key: type, value: type, text: type
                            }))}
                        />
                        
                        {!!this.state.error && (
                            <Label basic color='red' pointing>
                                { this.state.error }
                            </Label>
                        )}
                    </Form.Field>
                </Form>
            </div>
        );
    }
}