import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Label, Icon } from 'semantic-ui-react';

import { selectCompanyFromGrid } from '../usage/actions';

const SelectedCompanyBadge = ({ company, selectCompanyFromGrid, onUnselect }) => {
    if (!company)
        return (
            <Label disabled>
                No Company Selected
            </Label>
        );

    return (
        <Label color="violet">
            #{company.id} {company.company}
            <Icon
                name='delete'
                onClick={() => {
                    selectCompanyFromGrid(null);
                    if (onUnselect)
                        onUnselect(company);
                }}
            />
        </Label>
    );
};

const mapStateToProps = ({ companyUsageGrid }) => ({
    company: companyUsageGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    selectCompanyFromGrid,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCompanyBadge);