import {
    PRODUCT_SIZE_PICKER_LOADING,
    PRODUCT_SIZE_PICKER_SELECT,
    PRODUCT_SIZE_PICKER_ERROR,
    PRODUCT_SIZE_PICKER_DATA,
} from './constants';

import { makePrivateApiCall } from '../../common/api';
import { authExpire } from '../../auth/actions';

export const loadProductSizes= (keyword='') => dispatch => {
    dispatch({
        type: PRODUCT_SIZE_PICKER_LOADING,
        payload: true,
    });

    makePrivateApiCall({
        url: `/product/size/search`,
        method: 'get', params: {keyword}
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_SIZE_PICKER_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_SIZE_PICKER_ERROR,
            payload: err.message
        });
    });
};

export const selectProductSize = (size) => (dispatch) => {
    dispatch({
        type: PRODUCT_SIZE_PICKER_SELECT,
        payload: size
    });
};