import React from 'react';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {  Segment, Grid, Message, List, Header, Button, } from 'semantic-ui-react';

import { bytesToSize } from '../services/helpers';
import { loadBots, showBotDetails, hideBotDetails } from '../actions';

import BotContainerLog from './log';

const iconName = (bot, openedBots) => {
    let icon = bot.hasNestedLogs ? 'folder' : 'file alternate';
    
    if (openedBots.find(op => op.name === bot.name))
        icon += bot.hasNestedLogs ? ' open' : ' outline'

    return icon;
};

class BotContainerList extends React.Component {
    componentWillMount() {
        this.props.loadBots();
    };

    render() {
        const { bots, isLoading, openedBots } = this.props;
        
        if (isLoading) 
            return (
                <Segment loading style={{ minHeight: "200px" }} />
            );
            
        if (bots.length > 0)
            return (
                <Grid>
                    <Grid.Column mobile={16} computer={6}>
                        <Header 
                            as="h3"
                            attached="top"
                            content="Available Logs"
                        />
                        <Segment attached>
                            <List divided relaxed verticalAlign="middle">
                                { bots.map(bot => {
                                    if (bot.missing) {
                                        return (
                                            <List.Item key={bot.name}>
                                                <List.Icon
                                                    verticalAlign="middle"
                                                    name='warning sign'
                                                    size="large"
                                                />
                                                <List.Content>
                                                    <List.Header>
                                                        {bot.name}
                                                    </List.Header>
                                                    Not Found
                                                </List.Content>
                                            </List.Item>
                                        );
                                    }

                                    const detailsShown = openedBots.find(ob => ob.name === bot.name);

                                    return (
                                        <List.Item key={bot.name}>
                                            <List.Content floated='right'>
                                                <Button
                                                    size="mini"
                                                    content={detailsShown ? "Hide" : "Show"}
                                                    onClick={() => detailsShown ? this.props.hideBotDetails(bot) : this.props.showBotDetails(bot)}
                                                />
                                            </List.Content>

                                            <List.Icon 
                                                name={iconName(bot, openedBots)}
                                                verticalAlign="middle"
                                                size="large"
                                            />
                                            <List.Content>
                                                <List.Header>
                                                    {bot.name} | {bot.hasNestedLogs ? `${bot.nestedLogs.length} files` : bytesToSize(bot.size)}
                                                </List.Header>
                                                Last Update {format(bot.lastModified, 'DD MMM hh:mm')}
                                            </List.Content>
                                        </List.Item>
                                    );
                                }) }
                            </List>
                        </Segment>
                    </Grid.Column>
                    
                    {openedBots.length > 0 && (
                        <Grid.Column mobile={16} computer={10}>
                            {openedBots.map((bot, i) => (
                                <div className="bm-20" key={`open_${i}_${bot.name}`}>
                                    <BotContainerLog 
                                        onClose={() => this.props.hideBotDetails(bot)}
                                        bot={bot} 
                                    />
                                </div>
                            ))}
                        </Grid.Column>
                    )}
                </Grid>
            );
            
        return (
            <Message error content="Sorry no bots found"/>
        );
    };
};

const mapStateToProps = ({ botList }) => ({
    error: botList.error,
    bots: botList.bots,
    isLoading: botList.isLoading,
    openedBots: botList.openedBots,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadBots, showBotDetails, hideBotDetails,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BotContainerList));