import { format, subWeeks } from "date-fns";

import {
    REPORT_STATS_DATA,
    REPORT_STATS_ERROR,
    REPORT_STATS_LOADING,
    REPORT_STATS_DATE_RANGE,
} from "../constants";
import {
    generateReducer
} from "../../common/helpers";

const initialState = {
    data: {},
    error: null,
    isLoading: false,
    dateRange: `${format(subWeeks(new Date(), 1), 'YYYY-MM-DD')} - ${format(new Date(), 'YYYY-MM-DD')}`,
};

const loadData = (state, data) => ({
    ...state,
    data,
    error: null,
    isLoading: false,
});

const setDateRange = (state, dateRange) => ({
    ...state,
    dateRange,
});

const setError = (state, error) => ({
    ...state,
    error,
    isLoading: false,
});
const setLoading = state => ({
    ...state,
    isLoading: true
});

const reducers = {
    [REPORT_STATS_DATE_RANGE]: setDateRange,
    [REPORT_STATS_LOADING]: setLoading,
    [REPORT_STATS_ERROR]: setError,
    [REPORT_STATS_DATA]: loadData
};

const reducer = generateReducer(reducers, initialState);

export default reducer;
