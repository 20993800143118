import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { Modal, Segment, Dimmer, Loader } from 'semantic-ui-react';

import SideNav from './side-nav';
import ReAuth from '../../auth/containers/re-auth';
import ActivityComponentHistory from '../../activity/components/history';

import { authValidate } from '../../auth/actions';

const SigningInModal = () => (
    <Modal open={true} size="small">
        <Modal.Header>
            Checking your authentication
        </Modal.Header>

        <Modal.Content>
            <Segment>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                <div className="hp-15 sp-15"></div>
            </Segment>
        </Modal.Content>
    </Modal>
);

class PrivateRoute extends React.Component {
    componentWillMount() {
        this.props.authValidate();
    };

    redirectToLogin (props) {
        return (
            <Redirect
                to={{
                    pathname: "/auth/signin",
                    state: { from: props.location }
                }}
            />
        );
    };

    renderPage (Component, props) {
        return (
            <div className="page-wrapper">
                {props.showSideNav && (
                    <div className={`page-sidenav ${props.miniSideNav ? 'mini' : ''}`}>
                        <SideNav {...props} />
                    </div>
                )}

                <div className="page-container">
                    <Component {...props} />
                </div>

                <ReAuth />
                <ActivityComponentHistory />
            </div>
        );
    };
    
    render () {
        const { component: Component, ...propsWithoutComponent } = this.props;

        return (
            <Route {...propsWithoutComponent} render={() =>
                this.props.isSigningIn 
                    ? <SigningInModal />
                    : (this.props.user 
                        ? this.renderPage(Component, propsWithoutComponent) 
                        : this.redirectToLogin(propsWithoutComponent))
            }/>
        );
    };
};

const mapStateToProps = ({ auth, nav }) => ({
    user: auth.user,
    isSigningIn: auth.signingIn,

    // for side navbar
    miniSideNav: nav.isMini,
    showSideNav: nav.isShown,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authValidate,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));