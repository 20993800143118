import { PRODUCT_UPDATE_DATA, PRODUCT_UPDATE_LOADING, PRODUCT_UPDATE_ERROR, PRODUCT_UPDATE_DELETED, PRODUCT_UPDATE_MINIMIZE } from "../constants";

const initialState = {
    updates: [],
    error: null,
    minimized: true,
    isLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                updates: action.payload.rows,
            };

        case PRODUCT_UPDATE_DELETED:
            return {
                ...state,
                error: null,
                isLoading: false,
                updates: state.updates.filter(({update}) => action.payload !== update),
            };

        case PRODUCT_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case PRODUCT_UPDATE_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case PRODUCT_UPDATE_MINIMIZE:
            return {
                ...state,
                minimized: action.payload,
            };

        default:
            return state;
    }
};