import {
    PRODUCT_UPC_PICKER_LOADING,
    PRODUCT_UPC_PICKER_SELECT,
    PRODUCT_UPC_PICKER_ERROR,
    PRODUCT_UPC_PICKER_DATA,
} from './constants';

import { makePrivateApiCall } from '../../common/api';
import { authExpire } from '../../auth/actions';

export const loadProductUpcs = (upc='') => dispatch => {
    dispatch({
        type: PRODUCT_UPC_PICKER_LOADING,
        payload: true,
    });

    makePrivateApiCall({
        url: `/product/upc`,
        method: 'get', params: {upc}
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_UPC_PICKER_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_UPC_PICKER_ERROR,
            payload: err.message
        });
    });
};

export const selectProductUpc = (upc) => (dispatch, getState) => {
    const loadedUpcs = getState().productUpc.upcs;

    // if the upc being selected is not among the loaded upc list, add it to the list
    if (upc && !loadedUpcs.find(({u}) => u === upc.upc)) {
        dispatch(loadProductUpcs(upc.upc));
    }

    return dispatch({
        type: PRODUCT_UPC_PICKER_SELECT,
        payload: upc
    });
};