import { keyBy, clone } from 'lodash';
import { ProductPriceLevels } from './constants';

export const allGridColumns = keyBy([
    {
        headerName: 'ID',
        field: 'product_id',
        suppressMenu: true,
        type: 'numericColumn',
        sortable: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'distributor',
        headerName: 'Distributor',
        sortable: true,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'productColumnPicker',
    },
    {
        field: 'supplier',
        headerName: 'Supplier',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'productColumnPicker',
    },
    {
        field: 'broker',
        headerName: 'Broker',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        editable: true,
        sortable: true,
        cellEditor: 'productColumnPicker'
    },
    {
        field: 'brand',
        cellEditor: 'agTextCellEditor',
        editable: true,
        sortable: true,
        headerName: 'Brand',
        filter: 'agTextColumnFilter',
        suppressMenu: true,
    },
    {
        field: 'label',
        cellEditor: 'agTextCellEditor',
        editable: true,
        sortable: true,
        headerName: 'Label',
        filter: 'agTextColumnFilter',
        suppressMenu: true,
    },
    {
        field: 'product',
        cellEditor: 'agTextCellEditor',
        cellStyle: { "white-space": "normal" },
        editable: true,
        sortable: true,
        headerName: 'Product',
        filter: 'agTextColumnFilter',
        suppressMenu: true,
    },
    {
        field: 'product_size',
        headerName: 'Size',
        suppressMenu: true,
        cellEditor: 'productSizePicker',
        editable: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_type',
        headerName: 'Type',
        cellEditor: 'productTypePicker',
        suppressMenu: true,
        editable: true,
    },
    {
        field: 'product_category',
        headerName: 'Category',
        suppressMenu: true,
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        cellEditor: 'productCategoryPicker',
    },
    {
        field: 'product_sub_category',
        headerName: 'Subcategory',
        suppressMenu: true,
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        cellEditor: 'productCategoryPicker',
    },
    {
        field: 'product_price_level',
        headerName: 'Price Level',
        suppressMenu: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: ProductPriceLevels },
    },
    {
        field: 'product_price',
        headerName: 'Price',
        suppressMenu: true,
        sortable: true,
        editable: true,
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'upc',
        headerName: 'UPC',
        suppressMenu: true,
        editable: true,
        type: 'numericColumn',
        cellEditor: 'productUpcPicker',
    },
    {
        field: 'rndc_division',
        headerName: 'Rndc Division',
        suppressMenu: true,
        editable: true,
        filter: 'agTextColumnFilter',
        cellEditor: 'productColumnPicker',
    },
    {
        field: 'rndc_id',
        headerName: 'Rndc Id',
        suppressMenu: true,
        editable: true,
        filter: 'agNumberColumnFilter',
        cellEditor: 'productColumnPicker',
    },
], 'field');

export const hiddenGridColumns = [
    'broker',
    'upc',
    'rndc_division',
    'rndc_id',
];


export const applyTypeFilter = (filters, type) => {
    const filterModel = clone(filters);
    if (type.toLowerCase() === 'all') {
        delete filterModel['product_type'];
    } else {
        if (!filterModel['product_type']) {
            filterModel['product_type'] = {};
        }

        filterModel['product_type'] = { "type": "equals", "filter": type, "filterType": "text" };
    }

    return { filterModel };
};

export const applyIdFilter = (filters, product_id) => {
    const filterModel = clone(filters);
    if (!filterModel['product_id']) {
        filterModel['product_id'] = {};
    }

    filterModel['product_id'] = { "type": "equals", "filter": product_id, "filterType": "number" };

    return { filterModel };
};

export const applyUpcFilter = (filters, upc) => {
    const filterModel = clone(filters);
    if (!filterModel['upc']) {
        filterModel['upc'] = {};
    }

    filterModel['upc'] = { "type": "contains", "filter": upc, "filterType": "text" };

    return { filterModel };
};