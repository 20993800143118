import React, { Component } from "react";
import BrowserParser from 'ua-parser-js';
import { Icon } from "semantic-ui-react";

const BrowserIcon = ({ browser={} }) => {
    let { name } = browser,
        icon = name.toLowerCase(),
        title = `${name}, version: ${browser.version}`;

    if (['ie'].indexOf(icon) >= 0) {
        icon = 'internet explorer';
    }

    return <Icon title={title} name={icon} />;
};

const OSIcon = ({ os={} }) => {
    let { name } = os,
        icon = name.toLowerCase(),
        title = `${name} ${os.version}`;

    if (['mac os', 'ios'].indexOf(icon) >= 0) {
        icon = 'apple';
    }

    if (['ubuntu', 'centos', 'freebsd', 'mint', 'openbsd', 'Gentoo'].indexOf(icon) >= 0) {
        icon = 'linux';
    }

    if (['firefox os'].indexOf(icon) >= 0) {
        icon = 'firefox';
    }

    if (['chromium os'].indexOf(icon) >= 0) {
        icon = 'chrome';
    }

    return <Icon title={title} name={icon} />;
};

const DeviceIcon = ({ device={} }) => {
    let { type } = device;

    if (!type)
        return <Icon title="Unknown Device" name="computer" />;

    let icon = type.toLowerCase(),
        title = `${type}, ${device.vendor}, ${device.model}`;

    return <Icon title={title} name={icon} />;
};

export default class BrowserGridCell extends Component {
    state = {
        value: null
    };

    componentDidMount() {
        this.setState({ value: this.props.value });
    };

    refresh(params) {
        if (params.value !== this.state.value) {
            this.setState({
                value: params.value
            })
        }

        return true;
    };

    render() {
        if (!this.props.value) {
            return <span>Not Set</span>;
        }

        const info = new BrowserParser(this.props.value),
            browser = info.getBrowser(),
            device = info.getDevice(),
            os = info.getOS();

        // console.log(info.getResult());
        return (
            <span title={this.state.value}>
                <OSIcon os={os} /> 
                <DeviceIcon device={device} />
                <BrowserIcon browser={browser} /> 
            </span>
        );
    };
};
