import React from 'react';
import { get } from 'lodash';
import { format } from 'date-fns';
import { Label } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

export default class AgGridDatePicker extends React.Component {
    state = {
        date: new Date(),
        error: null,
    };

    componentWillMount() {
        this.setDate(this.props.value);
    };

    getValue() {
        return this.state.date;
    };

    isPopup() {
        return true;
    };

    setDate = (date) => {
        this.setState({ date: format(date, 'YYYY-MM-DD'), error: null });
    };

    handleChange = (date) => {
        this.setState({ date: format(date, 'YYYY-MM-DD') }, () => this.props.api.stopEditing());
    };

    render() {
        return (
            <div className="ag-grid-editor-segment">
                <label>{ get(this.props, 'colDef.headerName', '') }</label>

                <DateInput
                    inline
                    closable
                    name="date"
                    placeholder="Date"
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={this.state.date}
                    closeOnMouseLeave={false}
                    onChange={(e, data) => this.handleChange(data.value)}
                />
                
                {!!this.state.error && (
                    <Label basic color='red' pointing>
                        { this.state.error }
                    </Label>
                )}
            </div>
        );
    }
}