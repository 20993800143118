import React from 'react';
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { withRouter, Link } from 'react-router-dom';
import { List, Icon, Responsive } from 'semantic-ui-react';

import { minimizeSideNav, maximizeSideNav } from '../actions';
import { toggleActivityHistory } from '../../activity/actions';
import AuthContainerProfileColor from '../../auth/containers/profile-color';

const pages = [
    {
        title: 'Dashboard',
        route: '/dashboard',
        icon: 'tachometer alternate',
    },
    {
        title: 'Users',
        route: '/user',
        icon: 'users',
    },
    {
        title: 'Company',
        route: '/company/usage',
        icon: 'briefcase',
    },
    {
        title: 'Products',
        route: '/product',
        icon: 'glass martini',
    }, 
    {
        icon: 'file excel',
        route: '/report/review',
        title: 'Reports',
    }, 
    {
        title: 'Checks',
        route: '/check',
        icon: 'check circle outline',
    }, 
    {
        title: 'Bots',
        route: '/bot',
        icon: 'android',
    }, 
];

const menuClasses = (page, path) => {
    const cls = ['hp-15 hm-10 sidenav-item db'];

    if (path.indexOf(page.route) === 0) {
        cls.push('active');
    }

    return cls.join(' ');
};

const SideNav = ({ user, match, minimized, maximize, minimize, toggleActivities }) => (
    <>
        <div>
            <div className="tm-20 bm-40 sp-15">
                <List>
                    <List.Item as={Link} to="/setting">
                        <div className="fl">
                            <AuthContainerProfileColor/>
                        </div>

                        <Responsive
                            {...Responsive.onlyComputer}
                            className="hide-on-mini fl lp-5"
                            content={(
                                <>
                                    <List.Header>{user.name}</List.Header>
                                    <List.Description>{user.title}</List.Description>
                                </>
                            )}
                            as={List.Content}
                        />
                    </List.Item>
                </List>
            </div>

            {pages.map(page => (
                <Link 
                    to={page.route} 
                    key={page.icon} 
                    title={page.title}
                    className={ menuClasses(page, match.path) }>
                    <Icon name={page.icon}/>
                    
                    <Responsive
                        {...Responsive.onlyComputer}
                        className="hide-on-mini"
                        as='span'
                    >
                        {page.title}
                    </Responsive>
                </Link>
            ))}

            <a 
                href="/"
                onClick={(e) => { e.preventDefault(); toggleActivities();}}
                className={`${menuClasses({ route: null }, '')} clickable`}
            >
                <Icon name='history' />

                <Responsive
                    {...Responsive.onlyComputer}
                    className="hide-on-mini"
                    as='span'
                >
                    Activities
                </Responsive>
            </a>

            <a 
                target="_blank"
                rel="noopener noreferrer"
                href="http://tx.ydr.ink/back/index.php#chains"
                className={`${menuClasses({ route: null }, '')} clickable`}
            >
                <Icon name='chain' />

                <Responsive
                    {...Responsive.onlyComputer}
                    className="hide-on-mini"
                    as='span'
                >
                    Chain
                </Responsive>
            </a>
        </div>

        <Responsive
            className="hp-15 hm-10 sidenav-item sidenav-collapse" 
            {...Responsive.onlyComputer}
            as='div'
            onClick={(e) => {
                e.preventDefault();
                minimized ? maximize() : minimize();
            }}
        >
            <Icon name={ minimized ? 'arrow alternate circle right' : 'arrow left' } />
            {minimized ? '' : (<span>Minimize</span>)}
        </Responsive>
    </>
);

const mapStateToProps = ({ auth, nav }) => ({
    user: auth.user,
    minimized: nav.isMini,
});

const mapDispatchToProps = dispatch => ({
    openPage: (url) => dispatch(push(url)),
    minimize: () => dispatch(minimizeSideNav()), 
    maximize: () => dispatch(maximizeSideNav()),
    toggleActivities: () => dispatch(toggleActivityHistory()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav));