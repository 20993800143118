import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'formik-semantic-ui';

const CommonComponentBooleanToggle = ({ name, inputProps={}, ...others }) => {
    return (
        <Form.Field
            name={name}
            control={Checkbox}
            inputProps={{ toggle: true, ...inputProps }}
            {...others}
        />
    );
};

CommonComponentBooleanToggle.propTypes = {
    name: PropTypes.string.isRequired,
};

export default CommonComponentBooleanToggle;