import React from 'react';
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-semantic-toasts';

const CommonComponentCellWithCopyButton = ({ value, colDef }) => {
    return (
        <>
            {value}&nbsp;
            <Clipboard
                component="i"
                data-clipboard-text={value}
                button-className="copy icon clickable"
                onSuccess={data => {
                    toast({
                        icon: 'copy outline',
                        title: `Copied to clipboard`,
                        description: `${colDef.headerName} ${data.text} has been copied`
                    });
                }}
            />
        </>
    );
};

export default CommonComponentCellWithCopyButton;