import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Modal, Message } from 'semantic-ui-react';

import { authSigninInputChange, authSignin, authTogglePasswordField } from '../actions';
import { getAuthUser } from '../services/helpers';

class ReAuth extends React.Component {
    state = {
        showModal: true,
    };

    setModal = (showModal) => this.setState({ showModal});

    handleLogin = () => {
        const user = getAuthUser();
        this.props.authSigninInputChange('email', user.email);
        setTimeout(() => this.props.authSignin(this.props.inputs), 500);
    };

    render () {
        const { expired, inputs, isSigningIn, showPassword, signinErrors, authSigninInputChange, authTogglePasswordField } = this.props;
        const { showModal } = this.state;

        if (!expired)
            return null;

        return (
            <Modal
                size="small"
                open={showModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                onClose={() => this.setModal(false)}
            >
                <Modal.Header>Please log back in.</Modal.Header>

                <Modal.Content>
                    <p>
                        Due to security reasons, the dashboard expires your session after a while. 
                        <br/>
                        Please login again with your password.
                    </p>

                    <Form error={!!signinErrors} onSubmit={() => authSignin(inputs)}>
                        <Form.Input
                            icon='lock'
                            action={
                                <Button
                                    type="button"
                                    onClick={authTogglePasswordField}
                                    icon={showPassword ? "eye" : "eye slash"}
                                />
                            }
                            iconPosition='left'
                            value={inputs.password}
                            placeholder='Password'
                            label='Insert your password'
                            type={showPassword ? 'text' : 'password'}
                            error={signinErrors && !!signinErrors['password']}
                            onChange={(e) => authSigninInputChange('password', e.target.value)}
                        />
                    </Form>

                    {!!signinErrors && (
                        <Message
                            error
                            list={Object.values(signinErrors)}
                        />
                    )}
                </Modal.Content>

                <Modal.Actions>
                    <Button 
                        negative
                        content="Nevermind"
                        onClick={() => this.setModal(false)} 
                    />
                    <Button
                        positive
                        icon='lock'
                        content='Log Me In'
                        loading={isSigningIn}
                        labelPosition='right'
                        onClick={this.handleLogin}
                        disabled={isSigningIn || inputs.password.length < 1}
                    />
                </Modal.Actions>
            </Modal>
        );
    };
};

const mapStateToProps = ({ auth }) => ({
    expired: auth.expired,
    inputs: auth.signinInputs,
    isSigningIn: auth.signingIn,
    showPassword: auth.showPassword,
    signinErrors: auth.signinErrors,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authSigninInputChange, authSignin, authTogglePasswordField
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReAuth);