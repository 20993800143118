import {
    BOT_DATA, BOT_ERROR, BOT_LOADING, BOT_LOG_TOGGLE_VIEWER,
    BOT_DETAIL_SHOW, BOT_DETAIL_HIDE, BOT_LOG_CONTENT, BOT_LOG_LOADING, BOT_LOG_CONTENT_ERROR,
} from "../constants";

const initialState = {
    bots: [],
    error: null,
    openedBots: [],
    /*
    * content indexed by full file path. internal structure contains:
    {
        page: current page number, 
        content: file content, 
        isLoading: loading state,
        error: any error loading the content,
        ended: if the file has reached the end,
        isShown: if the viewer should be shown or not
    }
    */
    logContent: {},
    isLoading: false,
};

const setBots = (state, payload) => {
    return {
        ...state,
        error: null,
        bots: payload,
        isLoading: false,
    };
};

const setBotError = (state, payload) => {
    return {
        ...state,
        error: payload,
        isLoading: false,
    };
};

const setBotLoading = (state) => {
    return {
        ...state,
        isLoading: true,
    };
};

const showBot = (state, payload) => {
    let { openedBots } = state;

    // add bot to opened list only if it's not already shown 
    if (!openedBots.find(bot => bot.name === payload.name))
        openedBots = [...state.openedBots, payload];

    return {
        ...state, openedBots,
    }
};

const hideBot = (state, payload) => {
    const botFinder = (bot) => (bot.name !== payload.name);
    return {
        ...state,
        openedBots: state.openedBots.filter(botFinder)
    };
};

const setLogLoading = (state, fileKey) => {
    const { logContent } = state;
    let log = logContent[fileKey];

    if (!log) {
        log = {
            content: '', isLoading: true, count: 0,
            page: 0, error: null, ended: false, isShown: true,
        };
    } else {
        log.isLoading = true;
    }

    return { ...state, logContent: {...logContent, [fileKey]: log} };
};

const toggleLogViewer = (state, fileKey) => {
    const { logContent } = state;
    let log = logContent[fileKey];

    if (!log)
        return state;
        
    log.isShown = !log.isShown;
    return { ...state, logContent: {...logContent, [fileKey]: log} };
};

const appendLogContent = (state, payload) => {
    const { logContent } = state,
        { fileKey, rows, ended, count } = payload,
        log = logContent[fileKey];

    log.isLoading = false;

    if (!rows || rows.length < 1) {
        log.ended = true;
    } else {
        log.content += "\n" + rows.join("\n");
        log.ended = ended;
        log.count = count;
        log.error = null;
        log.page += 1;
    }    

    return { ...state, logContent: {...logContent, [fileKey]: log} };
};

const setLogError = (state, payload) => {
    const { logContent } = state;
    
    logContent.error = payload;

    return { ...state, logContent };
};

const reducerMap = {
    [BOT_DATA]: setBots,
    [BOT_ERROR]: setBotError,
    [BOT_LOADING]: setBotLoading,
    [BOT_DETAIL_SHOW]: showBot,
    [BOT_DETAIL_HIDE]: hideBot,
    [BOT_LOG_LOADING]: setLogLoading,
    [BOT_LOG_CONTENT]: appendLogContent,
    [BOT_LOG_CONTENT_ERROR]: setLogError,
    [BOT_LOG_TOGGLE_VIEWER]: toggleLogViewer,
};

export default (state = initialState, action) => {
    const reducerFunc = reducerMap[action.type];

    if (reducerFunc)
        return reducerFunc(state, action.payload);

    return state;
};