import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Segment, List, Label, Message, Confirm, Menu } from 'semantic-ui-react';

import ProductUpcListItem from './list-item';
import ProductContainerUpcCreator from './creator';

import { applyUpcFilter } from '../helpers';
import { selectProductUpc } from '../upc-picker/action';
import { changeProductGridState } from '../actions';
import { loadUpcsForProduct, deleteUpcForProduct, minimizeUpcList, sortUpcs } from './actions';

class ProductContainerUpcList extends React.Component {
    state = {
        showDeleteConfirm: false,
        deletingUpc: null,
    };

    componentWillMount () {
        const { product } = this.props;
        
        if (!product)
            return;
        
        this.props.loadUpcsForProduct(product.product_id);
    };

    onDeleteConfirm = () => {
        this.props.deleteUpcForProduct(this.props.product.product_id, this.state.deletingUpc)
        this.setState({ showDeleteConfirm: false, deletingNormal: null });
    };

    renderSortingMenu () {
        const {sortColumn, sortOrder, sortUpcs } = this.props;
        const nextOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        const orderIcon = sortOrder === 'ASC' ? 'long arrow alternate up' : 'long arrow alternate down';

        return (
            <Menu pointing secondary>
                <Menu.Item 
                    active={sortColumn === 'upc'} 
                    onClick={() => sortUpcs('upc', nextOrder)} 
                >
                    UPC                                
                    {sortColumn === 'upc' && (
                        <Icon name={orderIcon} />
                    )}
                </Menu.Item>
                <Menu.Item 
                    active={sortColumn === 'total_products'} 
                    onClick={() => sortUpcs('total_products', nextOrder)} 
                >
                    Products                                
                    {sortColumn === 'total_products' && (
                        <Icon name={orderIcon} />
                    )}
                </Menu.Item>
                <Menu.Item 
                    active={sortColumn === 'create_date'} 
                    onClick={() => sortUpcs('create_date', nextOrder)} 
                >
                    Create Date                                
                    {sortColumn === 'create_date' && (
                        <Icon name={orderIcon} />
                    )}
                </Menu.Item>
            </Menu>
        );
    };

    render () {
        const { 
            upcs, isLoading, error, product, minimized, filterModel, 
            changeProductGridState, selectProductUpc, 
        } = this.props;
        const { showDeleteConfirm, deletingUpc } = this.state;

        return (
            <Segment>
                <Label
                    ribbon
                    color="violet"
                    content={`${(!isLoading && upcs.length > 0) ? upcs.length +' ' : '' }UPCs of #${product.product_id}`}
                />

                <Confirm
                    size="tiny"
                    cancelButton="Nevermind"
                    confirmButton="Remove It"
                    open={showDeleteConfirm}
                    onConfirm={this.onDeleteConfirm}
                    content={`Sure you want to remove UPC ${deletingUpc} from ${product.product}?`}
                    onCancel={() => this.setState({ showDeleteConfirm: false, deletingUpc: null })}
                />

                <a
                    href="/"
                    className="fr"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.minimizeUpcList(!minimized);
                    }}
                >
                    {isLoading ? (
                        <Icon name="spinner" loading />
                    ) : (
                        <Icon
                            name={`window maximize${minimized ? ' outline' : ''}`}
                        />
                    )}
                </a>
                
                {!minimized && (
                    <div className="tp-10 tm-10">
                        {error && (<Message header="Something went wrong!" icon="warning sign" content={error} error={true}/>)}
                        
                        {this.renderSortingMenu()}
                        
                        <div
                            className="rp-10"
                            style={{ overflowY: 'scroll', maxHeight: '320px' }}>
                            <List 
                                relaxed 
                                verticalAlign='middle'>
                                {upcs.map(upc => <ProductUpcListItem 
                                    key={upc.upc}
                                    upc={upc}
                                    onSelect={() => {
                                        changeProductGridState(applyUpcFilter(filterModel, upc.upc));
                                        selectProductUpc(upc);
                                    }}
                                    onDelete={() => this.setState({
                                        showDeleteConfirm: true,
                                        deletingUpc: upc.upc
                                    })} 
                                />)}
                            </List>
                        </div>

                        <div className="tm-10">
                            <ProductContainerUpcCreator product={product} />
                        </div>
                    </div>
                )}
            </Segment>
        );
    };
};

ProductContainerUpcList.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productUpc, productGrid }) => ({
    sortColumn: productUpc.sortColumn,
    sortOrder: productUpc.sortOrder,
    minimized: productUpc.minimized,
    isLoading: productUpc.isLoading,
    error: productUpc.error,
    upcs: productUpc.upcs,

    filterModel: productGrid.filterModel,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeProductGridState, selectProductUpc,
    loadUpcsForProduct, deleteUpcForProduct, minimizeUpcList, sortUpcs,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerUpcList);