import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-semantic-toasts';
import { Icon, List } from 'semantic-ui-react';

const copySuccess = data => {
    toast({
        icon: 'copy outline',
        title: `Copied to clipboard`,
        description: `${data.text} has been copied`
    });
};

const ProductNormalListItem = ({ normal, onDelete }) => (
    <List.Item>
        <List.Content floated='right'>
            <a 
                href="/" 
                onClick={(e) => e.preventDefault()}
            >
                <Clipboard
                    component="span" 
                    onSuccess={copySuccess}
                    data-clipboard-text={normal.normalized}
                >
                    <Icon 
                        size="small" 
                        name="copy" 
                        color="teal"
                    />
                </Clipboard>
            </a>
            <a 
                href="/" 
                onClick={(e) => {
                    e.preventDefault();
                    onDelete(normal);
                }}
            >
                <Icon 
                    size="small"
                    name="delete" 
                    color="orange" 
                />
            </a>
        </List.Content>
        <List.Content>
            <List.Header>
                {normal.normalized}
            </List.Header>
            <List.Description>
                <b>{ normal.product_size }</b> | {format(normal.create_date, 'DD MMM, YY') }
            </List.Description>
        </List.Content>
    </List.Item>
);

ProductNormalListItem.propTypes = {
    normal: PropTypes.shape({
        normalized: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        create_date: PropTypes.any.isRequired,
        active: PropTypes.oneOf([-1, 0, 1]),
        product_size: PropTypes.string,
    }),
    onDelete: PropTypes.func.isRequired,
};

export default ProductNormalListItem;