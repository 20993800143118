import { difference } from 'lodash';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
    CLIENT_GRID_CONTROL_TOGGLE,
    CLIENT_GRID_CONFIRMING_CLIENT_CANCEL, 
    CLIENT_GRID_CANCELLING_CLIENT, 
    CLIENT_GRID_CANCELLED_CLIENT,
    CLIENT_GRID_DATA, 
    CLIENT_GRID_ERROR, 
    CLIENT_GRID_LOADING, 
    CLIENT_GRID_SELECT_CLIENT,
    CLIENT_GRID_COLUMN_TOGGLE, 
    CLIENT_GRID_PAGE_CHANGE, 
    CLIENT_GRID_STATE_CHANGE,
    CLIENT_GRID_SEARCHED_WITHIN_FILTER,
    CLIENT_GRID_COLUMN_CHANGE_STATE,
} from "../constants";
import clientGridColumns from "./columns";
import { generateReducer } from '../../common/helpers';

const hiddenColumns = ['service_premises', 'service_product_type'];
const shownColumns = difference(Object.keys(clientGridColumns), hiddenColumns);
const initialState = {
    confirmingClientCancel: false,
    cancellingClient: false,

    searchedWithinFilter: ' - ',
    columnState: null,
    showControl: false,

    error: null,
    clients: [],
    selected: null,
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 15,
    sortModel: [],
    filterModel: {},
    shownColumns: shownColumns,
    hiddenColumns: hiddenColumns,
    columnDefs: Object.values(clientGridColumns),
};

const setGridData = (state, { clients, lastRow }) => ({
    ...state, clients, lastRow,
    error: null, isLoading: false, 
});

const setGridError = (state, error) => ({
    ...state, isLoading: false, error,
});

const toggleGridColumn = (state, hiddenColumns) => {
    const shownColumns = difference(Object.keys(clientGridColumns), hiddenColumns);
    return { ...state, shownColumns, hiddenColumns };
};

const setGridLoading = (state) => ({ ...state, isLoading: true });
const changeGridState = (state, newGridState) => ({ ...state, ...newGridState });
const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const selectClient = (state, selected) => ({ ...state, selected });
const clientCancelling = (state, cancellingClient) => ({ ...state, cancellingClient });
const clientCancelConfirming = (state, confirmingClientCancel) => ({ ...state, confirmingClientCancel });
const clientCancelled = (state, client) => {
    const clients = state.clients.map(c => {
        if (c.id === client.id)
            return client;
        
        return c;
    });

    let { selected } = state;
    if (selected && selected.id === client.id) {
        selected = client;
    }
    
    return { ...state, clients, selected, cancellingClient: false, };
};

const toggleControl = (state) => ({ ...state, showControl: !state.showControl });
const setSearchedWithinFilter = (state, searchedWithinFilter) => ({ ...state, searchedWithinFilter });
const changeColumnState = (state, columnState) => ({ ...state, columnState });

const reducers = {
    [CLIENT_GRID_DATA]: setGridData,
    [CLIENT_GRID_ERROR]: setGridError,
    [CLIENT_GRID_LOADING]: setGridLoading,
    [CLIENT_GRID_SELECT_CLIENT]: selectClient,
    [CLIENT_GRID_PAGE_CHANGE]: changeGridPage,
    [CLIENT_GRID_CONTROL_TOGGLE]: toggleControl,
    [CLIENT_GRID_STATE_CHANGE]: changeGridState,
    [CLIENT_GRID_COLUMN_TOGGLE]: toggleGridColumn,
    [CLIENT_GRID_CANCELLED_CLIENT]: clientCancelled,
    [CLIENT_GRID_CANCELLING_CLIENT]: clientCancelling,
    [CLIENT_GRID_COLUMN_CHANGE_STATE]: changeColumnState,
    [CLIENT_GRID_SEARCHED_WITHIN_FILTER]: setSearchedWithinFilter,
    [CLIENT_GRID_CONFIRMING_CLIENT_CANCEL]: clientCancelConfirming,
};


const reducer = generateReducer(reducers, initialState);

const persistedReducer = persistReducer({
    key: 'clientGrid', storage, 
    whitelist: ['shownColumns', 'hiddenColumns', 'pageSize', 'filterModel', 'sortModel', 'selected', 'showControl', 'searchedWithinFilter']
}, reducer);

export default persistedReducer;