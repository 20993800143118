import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Segment, List, Label, Message, Confirm, Menu } from 'semantic-ui-react';

import { loadNormalsForProduct, deleteNormalForProduct, minimizeNormalList, sortNormals } from './actions';

import ProductNormalListItem from './list-item';
import ProductContainerNormalCreator from './creator';

class ProductContainerNormalList extends React.Component {
    state = {
        showDeleteConfirm: false,
        deletingNormal: null,
    };

    componentWillMount () {
        const { product } = this.props;
        
        if (!product)
            return;
        
        this.props.loadNormalsForProduct(product.product_id);
    };

    onDeleteConfirm = () => {
        this.props.deleteNormalForProduct(this.props.product.product_id, this.state.deletingNormal);
        this.setState({ showDeleteConfirm: false, deletingNormal: null});
    };


    renderSortingMenu() {
        const { sortColumn, sortOrder, sortNormals } = this.props;
        const nextOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        const orderIcon = sortOrder === 'ASC' ? 'long arrow alternate up' : 'long arrow alternate down';
        const columns = [
            {name: 'normalized', text: 'Normalized'}, 
            {name: 'product_size', text: 'Size'}, 
            {name: 'create_date', text: 'Date'}
        ];

        return (
            <Menu pointing secondary>
                {columns.map(col => (
                    <Menu.Item
                        key={col.name}
                        active={sortColumn === col.name}
                        onClick={() => sortNormals(col.name, nextOrder)}
                    >
                        { col.text }
                        {sortColumn === col.name && (
                            <Icon name={orderIcon} />
                        )}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };
    
    render () {
        const { normals, isLoading, error, product, minimized } = this.props;
        const { showDeleteConfirm, deletingNormal } = this.state;

        return (
            <Segment>
                <Confirm
                    size="tiny"
                    cancelButton="Nevermind"
                    confirmButton="Remove It"
                    open={showDeleteConfirm}
                    onConfirm={this.onDeleteConfirm}
                    content={`Sure you want to remove ${deletingNormal} from ${product.product}?`}
                    onCancel={() => this.setState({showDeleteConfirm: false, deletingNormal: null})}
                />
                
                <Label
                    ribbon
                    color="violet"
                    content={`${(!isLoading && normals.length > 0) ? normals.length +' ' : '' }Normals of #${product.product_id}`}
                />

                <a
                    href="/"
                    className="fr"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.minimizeNormalList(!minimized);
                    }}
                >
                    {isLoading ? (
                        <Icon name="spinner" loading />
                    ) : (
                        <Icon
                            name={`window maximize${minimized ? ' outline' : ''}`}
                        />
                    )}
                </a>
                
                {!minimized && (
                    <div className="tp-10 tm-10">
                        {error && (<Message 
                            header="Something went wrong!" 
                            icon="warning sign" 
                            content={error} 
                            error
                        />)}

                        {this.renderSortingMenu()}
                        
                        <div
                            className="rp-10"
                            style={{ overflowY: 'scroll', maxHeight: '320px' }}>
                            <List 
                                relaxed 
                                verticalAlign='middle'>
                                {normals.map(normal => <ProductNormalListItem 
                                    normal={normal}
                                    key={`${normal.normalized}_${normal.product_size}`}
                                    onDelete={() => this.setState({
                                        showDeleteConfirm: true,
                                        deletingNormal: normal.normalized
                                    })}
                                />)}
                            </List>
                        </div>
                        
                        <div className="tm-10">
                            <ProductContainerNormalCreator product={product} />
                        </div>
                    </div>
                )}
            </Segment>
        );
    };
};

ProductContainerNormalList.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productNormal }) => ({
    error: productNormal.error,
    normals: productNormal.normals,
    isLoading: productNormal.isLoading,
    minimized: productNormal.minimized,
    sortColumn: productNormal.sortColumn,
    sortOrder: productNormal.sortOrder,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadNormalsForProduct, deleteNormalForProduct, minimizeNormalList, sortNormals,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerNormalList);