import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import CompanyContainerEditor from '../containers/editor';
import ModuleEnvPicker from '../../common/containers/module-env-picker';

const ListCompanyButton = withRouter(({ history }) => {
    return (
        <React.Fragment>
            <Menu.Item
                icon="briefcase"
                name="All Companies"
                onClick={() => history.goBack()}
            />
            <ModuleEnvPicker moduleName="company"/>
        </React.Fragment>
    );
});

const CompanyCreatePage = () => {
    return (
        <Grid>
            <TopNav
                headerIcon='briefcase'
                subheader='Add new company'
                headerContent='Create Company'
                extraButtons={() => <ListCompanyButton />}
            />

            <Grid.Column mobile={16}>
                <CompanyContainerEditor />
            </Grid.Column>
        </Grid>
    );
};

export default CompanyCreatePage;