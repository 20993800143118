import { keyBy } from 'lodash';

export const matchGridColumns = keyBy([
    {
        suppressMenu: true,
        field: 'vol',
        headerName: 'Vol',
        type: 'numericColumn',
        sortable: true,
        filter: 'agNumberColumnFilter'
    },
    {
        suppressMenu: true,
        field: 'upc',
        headerName: 'UPC',
        sortable: true,
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellRenderer: 'cellWithCopyButton',
        cellStyle: { "white-space": "normal" },
    },
    {
        suppressMenu: true,
        field: 'source',
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        cellStyle: { "white-space": "normal" },
        cellEditor: 'agTextCellEditor',
        headerName: 'Source'
    },
    {
        suppressMenu: true,
        field: 'source_normalized',
        editable: true,
        sortable: true,
        filter: 'agNumberColumnFilter',
        cellStyle: { "white-space": "normal" },
        cellEditor: 'agTextCellEditor',
        headerName: 'Source Norm.'
    },
    {
        suppressMenu: true,
        field: 'itemUOM',
        filter: 'agNumberColumnFilter',
        sortable: true,
        headerName: 'UOM'
    },
    {
        suppressMenu: true,
        field: 'product_size',
        headerName: 'Size',
        editable: true,
        sortable: true,
        filter: 'agNumberColumnFilter',
        cellEditor: 'productSizePicker',
    },
    {
        suppressMenu: true,
        field: 'good',
        headerName: 'Good',
        editable: true,
        sortable: true,
        minWidth: 30,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: [-1, 0, 1, 2] },
    }, 
    {
        suppressMenu: true,
        field: 'product_id',
        headerName: 'ID',
        editable: true,
        cellEditor: 'agTextCellEditor',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter'
    },
    {
        filter: 'agTextColumnFilter',
        field: 'match_source',
        sortable: true,
        headerName: 'Match Src',
    },
], 'field');