import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Form, Input, Dropdown } from 'formik-semantic-ui';
import { isEmpty, without, values as objValues } from 'lodash';

import ProductColumnPicker from '../../product/containers/column-picker';
import CommonComponentBooleanToggle from '../../common/components/boolean-toggle';

import { CompanyTypes, CompanyServiceLevels, CompanyBillingCycles } from '../constants';
import { UserSubscriptionStatuses, UserSettingAllowedValues } from '../../user/constants';

const CompanyComponentEditorForm = ({
    handleChange, setFieldValue, setFieldTouched, values, errors, ...formProps
}) => {
    return (
        <Grid divided>
            <Grid.Row>
                <Grid.Column mobile={16} computer={11}>
                    <Form.Group widths="equal">
                        <Input
                            name="company"
                            label="Name"
                            inputProps={{
                                placeholder: 'Company Name'
                            }}
                        />

                        <Input
                            name="address"
                            label="Address"
                            inputProps={{
                                placeholder: '123 Main st'
                            }}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Input
                            name="city"
                            label="City"
                            inputProps={{
                                placeholder: 'Austin'
                            }}
                        />
                        <Input
                            name="state"
                            label="State"
                            inputProps={{
                                placeholder: 'TX'
                            }}
                        />
                        <Input
                            name="zip"
                            label="Zip"
                            inputProps={{
                                placeholder: '78078'
                            }}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Dropdown
                            fieldProps={{ width: 8 }}
                            inputProps={{
                                fluid: true, multiple: true,
                            }}
                            name="service_product_type"
                            label="Services"
                            options={UserSettingAllowedValues.SERVICE_PRODUCT_TYPE.map(type => ({
                                key: type, value: type, text: type
                            }))}
                        />

                        <Dropdown
                            fieldProps={{ width: 8 }}
                            inputProps={{
                                fluid: true, multiple: true,
                            }}
                            name="service_premises"
                            label="Premises"
                            options={UserSettingAllowedValues.SERVICE_PREMISES.map(type => ({
                                key: type, value: type, text: type
                            }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Dropdown
                            fieldProps={{ width: 3 }}
                            inputProps={{ fluid: true }}
                            name="type"
                            label="Type"
                            options={without(objValues(CompanyTypes), CompanyTypes.ALL).map(type => ({
                                key: type, value: type, text: type, description: type === CompanyTypes.OTHER ? 'Unlimited/Lite' : ''
                            }))}
                        />

                        {values.type && (values.type === CompanyTypes.SUPPLIER || values.type === CompanyTypes.BROKER) && (
                            <ProductColumnPicker
                                column="distributor"
                                initialValue={values.distributor}
                                valueGetter={(entry) => entry.distributor}
                                dropdownProps={{ label: 'Distributor', name: 'distributor', width: 5 }}
                                onChange={({ distributor }) => { setFieldTouched('distributor'); setFieldValue('distributor', distributor) }}
                            />
                        )}

                        <ProductColumnPicker
                            column="supplier"
                            initialValue={values.supplier}
                            valueGetter={(entry) => entry.supplier}
                            dropdownProps={{ label: 'Supplier', name: 'supplier', width: 5 }}
                            onChange={({ supplier }) => { setFieldTouched('supplier'); setFieldValue('supplier', supplier) }}
                        />

                        <Dropdown
                            label="Status"
                            name="service_status"
                            fieldProps={{ width: 3 }}
                            inputProps={{ fluid: true }}
                            options={objValues(UserSubscriptionStatuses).map(type => ({
                                key: type, value: type, text: type
                            }))}
                        />

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Input
                            name="brand"
                            label="Brand List"
                            inputProps={{
                                placeholder: 'Comma Separated List of Brands'
                            }}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Input
                            name="ronin_id"
                            label="Ronin Client ID"
                            inputProps={{
                                placeholder: '1543'
                            }}
                        />

                        <Input
                            type="number"
                            name="max_users"
                            label="Maximum users allowed"
                        />

                        <CommonComponentBooleanToggle
                            name="need_po"
                            label="Needs PO?"
                        />

                        <CommonComponentBooleanToggle
                            name="need_sign"
                            label="Needs Agreement Signature?"
                        />
                    </Form.Group>
                </Grid.Column>
                <Grid.Column mobile={16} computer={5}>
                    <Dropdown
                        label="Subscription"
                        name="service_level"
                        options={without(objValues(CompanyServiceLevels), CompanyServiceLevels.MARKETSLICE, CompanyServiceLevels.INTELLIGENCE).map(type => ({
                            key: type, value: type, text: type
                        }))}
                    />

                    <DateInput
                        label="Start Date"
                        name="service_start"
                        dateFormat="YYYY-MM-DD"
                        closeOnMouseLeave={false}
                        value={values.service_start}
                        onChange={(e, { value }) => {
                            setFieldTouched('service_start');
                            setFieldValue('service_start', value);
                        }}
                    />

                    <DateInput
                        label="Next Renewal"
                        name="service_renewal"
                        dateFormat="YYYY-MM-DD"
                        closeOnMouseLeave={false}
                        value={values.service_renewal}
                        onChange={(e, { value }) => {
                            setFieldTouched('service_renewal');
                            setFieldValue('service_renewal', value);
                        }}
                    />

                    <Form.Field
                        control={Dropdown}
                        label="Billing Cycle"
                        name="service_billing_cycle"
                        options={objValues(CompanyBillingCycles).map(type => ({
                            key: type, value: type, text: type
                        }))}
                    />

                    <Form.Field
                        control={Input}
                        name="service_price"
                        label="Annual subscription price (exclusive of tax $)"
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <br />
                    <Button
                        disabled={isEmpty(formProps.touched)}
                        color="teal"
                        type="submit"
                    >
                        {values.id ? 'Update' : 'Create'} Company
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default CompanyComponentEditorForm;