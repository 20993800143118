import { difference } from 'lodash';

import {
    USAGE_GRID_DATA, USAGE_GRID_ERROR, USAGE_GRID_LOADING,
    USAGE_GRID_COLUMN_TOGGLE, USAGE_GRID_SELECT_COMPANY, USAGE_GRID_SERVICE_LEVEL, USAGE_GRID_CONTROL_TOGGLE, USAGE_GRID_STATE_CHANGE,
} from "../constants";
import { companyUsageGridColumns, hiddenCompanyUsageGridColumns } from "./columns";
import { generateReducer } from '../../common/helpers';

const initialState = {
    error: null,
    companies: [],
    selected: null,
    sortModel: [],
    filterModel: { service_status: { type: "equals", filter: 'active', filterType: 'text' }},   
    lastLoaded: null,
    isLoading: false,
    showControl: false,
    serviceLevel: 'complete',
    hiddenColumns: hiddenCompanyUsageGridColumns,
    columnDefs: Object.values(companyUsageGridColumns),
    shownColumns: difference(Object.keys(companyUsageGridColumns), hiddenCompanyUsageGridColumns),
};

const changeGridState = (state, payload) => ({ ...state, ...payload, });
const setData = (state, { companies }) => ({ ...state, error: null, isLoading: false, companies, lastLoaded: new Date() });
const setError = (state, error) => ({ ...state, error, isLoading: false });
const setLoading = (state, error) => ({ ...state, isLoading: true });

const setServiceLevel = (state, serviceLevel) => ({ ...state, serviceLevel });
const selectCompany = (state, selected) => ({ ...state, selected });
const toggleColumn = (state, hiddenColumns) => {
    const shownColumns = difference(Object.keys(companyUsageGridColumns), hiddenColumns);
    return { ...state, shownColumns, hiddenColumns };
};

const toggleControl = (state) => ({ ...state, showControl: !state.showControl });

const reducers = {
    [USAGE_GRID_DATA]: setData,
    [USAGE_GRID_ERROR]: setError,
    [USAGE_GRID_LOADING]: setLoading,
    [USAGE_GRID_STATE_CHANGE]: changeGridState,

    [USAGE_GRID_COLUMN_TOGGLE]: toggleColumn,
    [USAGE_GRID_SELECT_COMPANY]: selectCompany,
    [USAGE_GRID_SERVICE_LEVEL]: setServiceLevel,

    [USAGE_GRID_CONTROL_TOGGLE]: toggleControl,
};

export default generateReducer(reducers, initialState);