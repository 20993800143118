import { format, addYears } from "date-fns";
import { isArray, isString, isEmpty, pick, cloneDeep } from "lodash";

import {
    COMPANY_EDITOR_INPUT,
    COMPANY_EDITOR_ERROR,
    COMPANY_EDITOR_SAVING,
    COMPANY_EDITOR_SAVED,
    COMPANY_EDITOR_CONFIRMING,
    COMPANY_EDITOR_COMPANY_ERROR,
    COMPANY_EDITOR_COMPANY_LOADING,
    COMPANY_EDITOR_COMPANY_DATA,
    COMPANY_EDITOR_COMPANY_RESET,
    CompanyTypes,
    CompanyServiceLevels,
    CompanyBillingCycles,
} from "../constants";
import { UserSubscriptionStatuses, UserSettingAllowedValues } from "../../user/constants";
import { generateReducer } from "../../common/helpers";

const defaultInputs = {
    company: '',
    city: '',
    zip: '',
    address: '',
    state: 'TX',
    ronin_id: 0,
    max_users: 3,
    supplier: '',
    need_po: false,
    need_sign: true,
    distributor: '',
    brand: '',
    service_price: '',
    type: CompanyTypes.SUPPLIER,
    service_level: CompanyServiceLevels.COMPLETE,
    service_status: UserSubscriptionStatuses.ACTIVE,
    service_product_type: [UserSettingAllowedValues.SERVICE_PRODUCT_TYPE[0]],
    service_premises: [UserSettingAllowedValues.SERVICE_PREMISES[0]],
    service_start: format(new Date(), 'YYYY-MM-DD'),
    service_billing_cycle: CompanyBillingCycles.ANNUAL,
    service_renewal: format(addYears(new Date(), 1), 'YYYY-MM-DD'),
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    isConfirming: false,
    isSaving: false,
    errors: [],

    company: null,
    companyError: null,
    isLoadingCompany: false,
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors, };
};
const setConfirming = (state, company) => {
    // if company is not literally false, then set it to true
    const newState = { ...state, isConfirming: company !== false };

    if (company && !isEmpty(company)) {
        if (isArray(company.service_premises))
            company.service_premises = company.service_premises.join(',');

        if (isArray(company.service_product_type))
            company.service_product_type = company.service_product_type.join(',');

        newState.inputs = company;
    }

    return newState;
};
const setSaving = (state) => ({ ...state, isSaving: true });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    return { ...state, inputs: { ...state.inputs, ...inputs } };
};

const setCompanyLoading = (state) => ({ ...state, isLoadingCompany: true, companyError: null });
const resetCompany = (state) => ({
    ...state, company: null, companyError: null,
    isLoadingCompany: false, inputs: cloneDeep(defaultInputs)
});
const setCompanyData = (state, company) => {
    const inputs = pick(company, Object.keys(defaultInputs));
    inputs.id = company.id;
    inputs.service_start = format(company.service_start, 'YYYY-MM-DD');
    inputs.service_renewal = format(company.service_renewal, 'YYYY-MM-DD');

    // in our db, these values are saved as SET so multiple values are comma separated but on the client side, they need to be treated as arrays
    inputs.service_premises = company.service_premises.split(',');
    inputs.service_product_type = company.service_product_type.split(',');

    return { ...state, isLoadingCompany: false, company, inputs, companyError: null }
};
const setCompanyError = (state, err) => ({ ...state, isLoadingCompany: false, company: null, companyError: err });

const reducers = {
    [COMPANY_EDITOR_COMPANY_LOADING]: setCompanyLoading,
    [COMPANY_EDITOR_COMPANY_ERROR]: setCompanyError,
    [COMPANY_EDITOR_COMPANY_DATA]: setCompanyData,
    [COMPANY_EDITOR_COMPANY_RESET]: resetCompany,

    [COMPANY_EDITOR_CONFIRMING]: setConfirming,
    [COMPANY_EDITOR_SAVED]: resetState,
    [COMPANY_EDITOR_SAVING]: setSaving,
    [COMPANY_EDITOR_INPUT]: setInput,
    [COMPANY_EDITOR_ERROR]: setError,
};

export default generateReducer(reducers, initialState);