import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Segment, Grid, Message, Button } from "semantic-ui-react";

import ReportComponentSingle from "../components/single";
import {
    loadReportList,
    showReportDetails,
    hideReportDetails,
    toggleSubscriberCountFilter,
} from "../actions";

class ReportContainerList extends React.Component {
    state = {
        showDeleteConfirm: false,
        deletingReport: null
    };

    componentWillMount() {
        this.props.loadReportList();
    }

    render() {
        const {
            reports,
            isLoading,
            subCountFilter,
            detailsExposedReports,

            toggleSubscriberCountFilter,
        } = this.props;

        let countFilterText = 'With Subscribers';
        if (subCountFilter === false) {
            countFilterText = 'Without Subscribers';
        }
        if (subCountFilter === null) {
            countFilterText = 'With Or Without Subscribers';
        }

        return (
            <React.Fragment>
                <Segment basic>
                    <Grid>
                        <Grid.Row>
                            <Button
                                size="tiny"
                                content={countFilterText}
                                onClick={toggleSubscriberCountFilter}
                            />
                        </Grid.Row>
                    </Grid>
                </Segment>
                {isLoading ? (
                    <Segment loading style={{ minHeight: "200px" }} />
                ) : reports.length > 0 ? (
                    <Grid>
                        {reports.map(report => {
                            const showDetails =
                                detailsExposedReports.indexOf(report.id) >= 0;
                            return (
                                <Grid.Row key={report.id}>
                                    <Grid.Column mobile={16} computer={8}>
                                        <ReportComponentSingle
                                            report={report}
                                            showDetails={showDetails}
                                            onHideReportDetails={
                                                this.props.hideReportDetails
                                            }
                                            onShowReportDetails={
                                                this.props.showReportDetails
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                ) : (
                    <Message error content="Sorry no reports found" />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ reportList }) => ({
    error: reportList.error,
    reports: reportList.reports,
    isLoading: reportList.isLoading,
    subCountFilter: reportList.subCountFilter,
    detailsExposedReports: reportList.detailsExposedReports,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadReportList,
            showReportDetails,
            hideReportDetails,
            toggleSubscriberCountFilter,
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportContainerList)
);