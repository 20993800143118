import { toast } from 'react-semantic-toasts';
import { get } from 'lodash';

import {
    CLIENT_GRID_CONTROL_TOGGLE,
    CLIENT_GRID_DATA, 
    CLIENT_GRID_LOADING, 
    CLIENT_GRID_ERROR,
    CLIENT_GRID_CONFIRMING_CLIENT_CANCEL, 
    CLIENT_GRID_CANCELLING_CLIENT, 
    CLIENT_GRID_CANCELLED_CLIENT,
    CLIENT_GRID_COLUMN_TOGGLE, 
    CLIENT_GRID_PAGE_CHANGE, 
    CLIENT_GRID_STATE_CHANGE, 
    CLIENT_GRID_SELECT_CLIENT, 
    CLIENT_GRID_COLUMN_ERROR, 
    CLIENT_GRID_COLUMN_SAVED, 
    CLIENT_GRID_COLUMN_SAVING,
    CLIENT_GRID_SEARCHED_WITHIN_FILTER,
    CLIENT_GRID_COLUMN_CHANGE_STATE,
} from '../constants';
import { authExpire } from '../../auth/actions';
import { makePrivateApiCall } from '../../common/api';

export const loadClientGridPage = (state, grid) => (dispatch, getState) => {
    let { lastRow, sortModel, filterModel, searchedWithinFilter } = state;
    const { moduleEnv } = getState();

    dispatch({
        type: CLIENT_GRID_LOADING
    });

    const params = {
        env: moduleEnv.user,
        endRow: grid.endRow,
        startRow: grid.startRow,
        filterModel, sortModel, searchedWithinFilter,
    };

    makePrivateApiCall({
        url: '/user/client/grid',
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            const clients = res.data.rows;
            lastRow = res.data.count;

            grid.successCallback(clients, lastRow);

            dispatch({
                type: CLIENT_GRID_DATA,
                payload: { clients, lastRow }
            });
        }
    }).catch(err => {
        grid.failCallback(err);
        dispatch({
            type: CLIENT_GRID_ERROR,
            payload: err.message
        });
    });
};

export const changeClientGridPage = (page) => {
    return {
        type: CLIENT_GRID_PAGE_CHANGE,
        payload: page
    };
};

export const setSearchedWithinFilter = (date) => dispatch => {
    return dispatch({
        type: CLIENT_GRID_SEARCHED_WITHIN_FILTER,
        payload: date
    });
};

export const changeClientGridState = (gridState) => dispatch => dispatch({
    type: CLIENT_GRID_STATE_CHANGE,
    payload: gridState
});

export const selectClientFromGrid = (client) => {
    return {
        type: CLIENT_GRID_SELECT_CLIENT,
        payload: client
    };
};

export const hideClientGridColumn = (columns) => dispatch => {
    dispatch({
        type: CLIENT_GRID_COLUMN_TOGGLE,
        payload: columns
    });
};

export const toggleClientGridControl = () => dispatch => dispatch({
    type: CLIENT_GRID_CONTROL_TOGGLE
});

export const requestClientCancel = (confirming=null) => dispatch => {
    dispatch({
        type: CLIENT_GRID_CONFIRMING_CLIENT_CANCEL,
        payload: !!confirming,
    });
};

export const changeClientGridColumnState = (columnState) => dispatch => dispatch({
    type: CLIENT_GRID_COLUMN_CHANGE_STATE,
    payload: columnState,
});

export const cancelClient = (clientId=null, onCancel) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    dispatch({
        type: CLIENT_GRID_CANCELLING_CLIENT,
        payload: true
    });

    return makePrivateApiCall({
        url: `/user/client/${clientId}/cancel`,
        method: 'put', data: {}, params: {env: moduleEnv.user}
    }).then((res) => {
        dispatch({
            type: CLIENT_GRID_CONFIRMING_CLIENT_CANCEL,
            payload: false,
        });

        if (res.data) {
            if (!!onCancel)
                onCancel();

            dispatch({
                type: CLIENT_GRID_CANCELLED_CLIENT,
                payload: res.data
            });
            
            toast({
                time: 5000,
                icon: 'user',
                type: 'success',
                title: 'Client Account Cancelled',
                description: `Successfully cancelled client's account`,
            });
        }
    }).catch(err => {
        toast({
            time: 6000,
            type: 'error',
            icon: 'warning',
            title: 'Error Cancelling Client',
            description: err.message,
        });
        dispatch({
            type: CLIENT_GRID_CANCELLING_CLIENT,
            payload: false
        });
        dispatch({
            type: CLIENT_GRID_ERROR,
            payload: err.message
        });
    });
};

export const saveClientGridColumn = (userId, user = {}) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    dispatch({
        type: CLIENT_GRID_COLUMN_SAVING,
        payload: true,
    });

    return makePrivateApiCall({
        url: `/user/client/${userId}`,
        method: 'put', data: user, 
        params: {env: moduleEnv.user}
    }).then(res => {
        if (res.data) {
            dispatch({
                type: CLIENT_GRID_COLUMN_SAVED,
                payload: res.data
            });

            const toastParams = {
                type: 'success',
                icon: 'checkmark',
                title: 'Upated User',
                description: 'User column has been updated',
            };

            if (!res.data.updated) {
                toastParams.icon = 'info';
                toastParams.type = 'info';
                toastParams.title = 'Update not needed';
                toastParams.description = 'Nothing was changed in the database';
            }

            toast(toastParams);
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: CLIENT_GRID_COLUMN_ERROR,
            payload: err.message
        });

        toast({
            type: 'error',
            icon: 'close',
            title: 'Update Error',
            description: get(err, 'response.data.message', null) || err.message,
        });
    });
};