import { AUTH_STORAGE_TOKEN, AUTH_STORAGE_USER } from '../constants';

export const setAuthToken = (token='') => {
    if (token && token.length > 5)
        return localStorage.setItem(AUTH_STORAGE_TOKEN, token);
    
    return localStorage.removeItem(AUTH_STORAGE_TOKEN);
};

export const getAuthToken = () => {
    return localStorage.getItem(AUTH_STORAGE_TOKEN);
};

export const setAuthUser = (user) => {
    if (!!user)
        return localStorage.setItem(AUTH_STORAGE_USER, JSON.stringify(user));

    return localStorage.removeItem(AUTH_STORAGE_TOKEN);
};

export const getAuthUser = () => {
    const user = localStorage.getItem(AUTH_STORAGE_USER);

    console.log({user});
    if (user)
        return JSON.parse(user);

    return null;
};