import { makePrivateApiCall } from "../../common/api";

export const getLocaleColumns = (user={}) => {
    let locale = "state,city,county,zip,";

    const localeMap = {
        'rndc': 'rndc_region, rndc_house',
        'glazers': 'glazers_branch',
        'tabc': 'tabc_region',
        'goody goody': 'goody_area',
        'any': 'rndc_region',
    };

    if (!user)
        return locale;
    
    if (user.distributor && localeMap[user.distributor.toLowerCase()]) {
        locale += localeMap[user.distributor.toLowerCase()];
    } else if (user.company && localeMap[user.company.toLowerCase()]) {
        locale += localeMap[user.company.toLowerCase()];
    } else {
        locale += localeMap['any'];
    }

    return locale.split(',');
};

export const getGeoOptions = async (userId, type, keyword, env) => {
    const res = await makePrivateApiCall({
        url: '/user/setting/geo/options',
        method: 'get', params: {userId, type, keyword, env},
    });

    return res.data;
};