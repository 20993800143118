import { keyBy } from 'lodash';
import format from 'date-fns/format';

import { dateFilterComparator } from '../../common/helpers';
import { UserSubscriptionStatuses } from '../../user/constants';
import { CompanyBillingCycles, CompanyServiceLevels } from '../constants';

export const renewalGridColumns = keyBy([
    {
        headerName: 'Company',
        field: 'company',
        width: 250,
        filter: 'agTextColumnFilter',
        sortable: true,
        suppressMenu: true,
        cellEditor: 'agTextCellEditor',
        editable: true,
    },
    {
        headerName: 'Services',
        field: 'service_product_type',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        width: 200,
        cellRenderer: 'companyServicesGridCell',
        cellEditor: 'agGridServiceTypePicker',
        editable: true,
    },
    {
        headerName: 'Premises',
        field: 'service_premises',
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        width: 160,
        cellRenderer: 'companyServicesGridCell',
        cellEditor: 'agGridPremisePicker',
        editable: true,
    },
    {
        field: 'id',
        headerName: 'ID',
        filter: 'agNumberColumnFilter',
        sortable: true,
        suppressMenu: true,
    },
    {
        field: 'ronin_id',
        headerName: 'Ronin ID',
        filter: 'agNumberColumnFilter',
        sortable: true,
        suppressMenu: true,
    },
    {
        headerName: 'Start',
        field: 'service_start',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueGetter: ({ data }) => {
            return data && data.service_start ? format(data.service_start, "YYYY/MM/DD") : null;
        },
        filterParams: { comparator: dateFilterComparator },
    },
    {
        headerName: 'Renewal Date',
        field: 'service_renewal',
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: { comparator: dateFilterComparator },
        editable: true,
        cellEditor: 'agGridDatePicker',
    },
    {
        filter: false,
        headerName: 'Annual Price',
        field: 'service_price',
        sortable: true,
        type: 'numericColumn',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueFormatter: ({ data }) => {
            return data && data.service_price ? data.service_price.toLocaleString() : null;
        },
    },
    {
        sortable: true,
        suppressMenu: true,
        headerName: 'Billing Cycle',
        field: 'service_billing_cycle',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: Object.values(CompanyBillingCycles) },
        filter: 'agTextColumnFilter',
    },
    {
        filter: false,
        suppressMenu: true,
        headerName: 'Level',
        field: 'service_level',
        type: 'numericColumn',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: Object.values(CompanyServiceLevels) },
    },
    {
        suppressMenu: true,
        sortable: true,
        headerName: 'Status',
        field: 'service_status',
        filter: 'agTextColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: Object.values(UserSubscriptionStatuses) },
    },
    {
        suppressMenu: true,
        headerName: 'Need Sign',
        field: 'need_sign',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: [true, false] },
    },
    {
        suppressMenu: true,
        headerName: 'Max Users',
        field: 'max_users',
        editable: true,
        type: 'numericColumn',
    },
    {
        suppressMenu: true,
        headerName: 'Manager',
        field: 'manager',
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agTextCellEditor',
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        filter: false,
        cellRenderer: "companyActionGridCell",
    }
], 'field');

export const hiddenRenewalGridColumns = [
    'id', 'ronin_id', 'service_start', 'need_sign', 'max_users',
];