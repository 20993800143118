import {
    STATE_SNAPSHOT_DATA, STATE_SNAPSHOT_ERROR, STATE_SNAPSHOT_LOADING,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';

export const loadStateSnapshots = (searchId) => (dispatch, getState) => {
    const { moduleEnv } = getState();
    dispatch({
        type: STATE_SNAPSHOT_LOADING
    });

    makePrivateApiCall({
        url: `/user/search/${searchId}/snapshots`,
        method: 'get', params: { env: moduleEnv.user },
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: STATE_SNAPSHOT_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        let payload = err.message;

        if (err.response && err.response.data && err.response.data.message) {
            payload = err.response.data.message;
        }

        dispatch({
            type: STATE_SNAPSHOT_ERROR, payload
        });
    });
};