import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Icon, Header, Form, Confirm, Divider, Message, List, Modal } from 'semantic-ui-react';

import { 
    setProductUntanglerInput, setProductUntanglerError, 
    setProductUntanglerConfirming, confirmProductUntangle,
} from '../actions';
import ProductSizePicker from '../size-picker/container';

const ConfirmContent = ({inputs={}}) => (
    <Modal.Content>
        Please verify that you want to run untangler with the following parameters
        <br/>
        
        <List>
            <List.Item>
                <List.Header>Tangled Product ID</List.Header>
                { inputs.tangledProductId }
            </List.Item>
            <List.Item>
                <List.Header>Tangled UPC</List.Header>
                { inputs.tangledUpc || 'Not Set' }
            </List.Item>
            <List.Item>
                <List.Header>True Product ID</List.Header>
                {inputs.productId || 'Not Set'}
            </List.Item>
            <List.Item>
                <List.Header>Product Size</List.Header>
                {inputs.productSize || 'Not Set'}
            </List.Item>
            <List.Item>
                <List.Header>Matching Phrase</List.Header>
                {inputs.matchingPhrase || 'Not Set'}
            </List.Item>
        </List>
    </Modal.Content>
);

export class ProductContainerUntangler extends React.Component {
    getInputs() {
        const { inputs, matchProduct } = this.props;
        const data = {...inputs};

        if (!data.productId) {
            data.productId = 0;
        }

        if (matchProduct) {
            if (!data.tangledUpc) {
                data.tangledUpc = matchProduct.upc;
            }

            if (!data.tangledProductId) {
                data.tangledProductId = matchProduct.product_id;
            }

            if (!data.productSize) {
                data.productSize = matchProduct.product_size;
            }

            if (!data.matchingPhrase) {
                data.matchingPhrase = matchProduct.source;
            }
        }

        return data;
    };

    handlePhraseInput = (e, { value }) => {
        if (value.length >= 5)
            value = value.replace(/ /g, "%");

        this.props.setProductUntanglerInput('matchingPhrase', value);
    };

    render () {
        const { inputs, error, isUntangling, isConfirming } = this.props;

        return (
            <Segment>
                <Confirm
                    size="tiny"
                    open={isConfirming}
                    header="Confirm Untangle"
                    confirmButton="Untangle"
                    cancelButton="Nevermind"
                    onCancel={() => this.props.setProductUntanglerConfirming(false)}
                    content={(<ConfirmContent inputs={this.getInputs()} />)}
                    onConfirm={() => this.props.confirmProductUntangle(this.getInputs())}
                />
                
                <Header>
                    <Icon name="random" />
                    <Header.Content>
                        Product Untangler
                    </Header.Content>
                </Header>

                <Divider />
                <Form 
                    onSubmit={() => this.props.setProductUntanglerConfirming(true)} 
                    loading={isUntangling}
                >
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            label='Tangled Product ID'
                            value={inputs.tangledProductId}
                            onChange={(e, { value }) => this.props.setProductUntanglerInput('tangledProductId', value)}
                        />
                        <Form.Input
                            fluid
                            label='Tangled UPC'
                            value={inputs.tangledUpc}
                            onChange={(e, { value }) => this.props.setProductUntanglerInput('tangledUpc', value)}
                        />
                    </Form.Group>

                    <ProductSizePicker 
                        sizeOnly={true}
                        dropdownProps={{width: 16}}
                        product={{ product_size: inputs.productSize }}
                        onChange={({ size }) => this.props.setProductUntanglerInput('productSize', size)}
                    />

                    <Form.Input
                        fluid
                        type="number"
                        label='True Product ID'
                        value={inputs.productId}
                        onChange={(e, {value}) => this.props.setProductUntanglerInput('productId', value)}
                    />
                    <Form.Input
                        fluid
                        label='Matching Phrase'
                        value={inputs.matchingPhrase}
                        onChange={this.handlePhraseInput}
                        placeholder="i.e: herradura anejo"
                    />

                    <Form.Field>
                        <Form.Button content='Untangle' />
                    </Form.Field>
                </Form>

                {error && (<Message error content={error} />)}
            </Segment>
        );
    };
};

const mapStateToProps = ({ productUntangler, productMatchGrid }) => ({
    isConfirming: productUntangler.isConfirming,
    isUntangling: productUntangler.isUntangling,
    matchProduct: productMatchGrid.selected,
    inputs: productUntangler.inputs,
    error: productUntangler.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductUntanglerInput, setProductUntanglerError, 
    setProductUntanglerConfirming, confirmProductUntangle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerUntangler);