import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Header, List, Label, Icon } from 'semantic-ui-react';

import { ReportType } from '../prop-types';
import { ReportEmailEvents } from '../constants';

const eventIconMap = {
    [ReportEmailEvents.CLICK]: 'mouse pointer',
    [ReportEmailEvents.OPEN]: 'envelope open',
    [ReportEmailEvents.DELIVERED]: 'send',
};

const EventIcon = ({ event }) => {
    return (
        <Icon
            size="small"
            name={eventIconMap[event.event]}
            title={`${event.event} at ${format(new Date(event.timestamp), 'DD MMM, YY HH:mm')}`}
        />
    );
};

const ReportComponentPerformanceList = ({
    reports,
    ranking,
}) => {
    const header = ranking === "top" ? "Highest Downloaded Reports" : "Lowest Downloaded Reports";

    return (
        <>
            <Header size="small" content={header} dividing />
            <List divided relaxed>
                {reports.map(r => (
                    <List.Item key={`report_performance_list_item_${r.id}`}>
                        <List.Content floated="right">
                            <Label 
                                size="small"
                            >
                                <Icon name="download" />
                                {r.totalFilesOpened}
                            </Label>
                        </List.Content>
                        <List.Header>
                            {r.supplier || r.distributor}
                        </List.Header>
                        <List.Description>
                            #{r.id} {r.description}
                        </List.Description>
                        <br/>

                        {r.emails.map(e => (
                            <List.Description key={`report_download_email_${e}`}>
                                <i>{e}</i>
                            </List.Description>
                        ))}
                    </List.Item>
                ))}
            </List>
        </>
    );
};

ReportComponentPerformanceList.propTypes = {
    reports: PropTypes.arrayOf(ReportType),
    ranking: PropTypes.oneOf(["top", "bottom"]),
};

export default ReportComponentPerformanceList;