import {
    PRODUCT_EDITOR_INPUT,
    PRODUCT_EDITOR_ERROR,
    PRODUCT_EDITOR_SAVING,
    PRODUCT_EDITOR_CONFIRMING,
    PRODUCT_EDITOR_SAVED,
    PRODUCT_EDITOR_PRODUCT_LOADING,
    PRODUCT_EDITOR_PRODUCT_DATA,
    PRODUCT_EDITOR_PRODUCT_ERROR,
    PRODUCT_EDITOR_PRODUCT_RESET,
} from '../constants';
import { selectProductFromGrid } from './grid';
import { makePrivateApiCall } from '../../common/api';

export const requestSaveProduct = (product = {}) => dispatch => {
    return dispatch({
        type: PRODUCT_EDITOR_CONFIRMING,
        payload: product,
    });
};

export const saveProduct = (product = {}, onComplete) => dispatch => {
    console.log({product});
    dispatch({
        type: PRODUCT_EDITOR_SAVING,
        payload: true,
    });

    const request = {
        url: `/product/${product.product_id}`,
        method: 'put',
        data: product,
    };

    makePrivateApiCall(request).then((res) => {
        if (res.data) {
            dispatch({
                type: PRODUCT_EDITOR_SAVED,
                payload: res.data,
            });

            if (product.id) {
                dispatch(selectProductFromGrid(res.data));
            }

            onComplete(res.data);
        }
    }).catch(err => {
        onComplete(null);
        dispatch(setProductEditorConfirming());
        dispatch(setProductEditorError([err.message]));
    });
};

export const setProductEditorInput = (inputs = {}) => dispatch => {
    return dispatch({
        type: PRODUCT_EDITOR_INPUT,
        payload: inputs
    });
};

export const setProductEditorConfirming = (confirming = false) => dispatch => {
    return dispatch({
        type: PRODUCT_EDITOR_CONFIRMING,
        payload: !!confirming
    });
};

export const setProductEditorError = (error) => dispatch => {
    return dispatch({
        type: PRODUCT_EDITOR_ERROR,
        payload: error
    });
};

export const loadProductForEdit = (productId) => dispatch => {
    dispatch({
        type: PRODUCT_EDITOR_PRODUCT_LOADING,
        payload: true,
    });

    makePrivateApiCall({
        method: 'get',
        url: `/product/${productId}`,
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: PRODUCT_EDITOR_PRODUCT_DATA,
                payload: res.data,
            });
        }
    }).catch(err => {
        dispatch({
            type: PRODUCT_EDITOR_PRODUCT_ERROR,
            payload: err.message,
        });
    });
};

export const resetEditingProduct = () => dispatch => dispatch({
    type: PRODUCT_EDITOR_PRODUCT_RESET
});