import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'formik-semantic-ui';
import { bindActionCreators } from 'redux';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router-dom';
import { Segment, Message } from 'semantic-ui-react';

import { validateProductUpdate } from '../services/validators';
import { setProductEditorInput, setProductEditorError, saveProduct, loadProductForEdit } from '../actions';

import ProductComponentEditorForm from '../components/editor-form';

class ProductContainerEditor extends React.Component {
    formikApi = null;

    handleSubmit = async (productData, formikApi) => {
        this.formikApi = formikApi;
        const { saveProduct, setProductEditorError, history } = this.props;
        
        try {
            let product = await validateProductUpdate(productData);
            saveProduct(product, (data) => {
                this.formikApi && this.formikApi.setSubmitting(false);

                if (product && data !== null) {
                    toast({
                        time: 5000,
                        type: 'success',
                        icon: 'glass martini',
                        title: data.updated ? 'Product updated' : 'Nothing updated',
                        description: data.updated ? `Successfully updated normal from product` : `Your change did not require database update`,
                    });

                    history.push('/product');
                }
            });
        } catch (err) {
            console.error(err);
            err.details.forEach(errDet => {
                formikApi.setFieldError(errDet.context.key, errDet.message);
            });

            formikApi.setSubmitting(false);
            setProductEditorError(err);
        }
    };

    render () {
        const { 
            errors, inputs, isSaving, isLoadingProduct, productError, history
        } = this.props;
        
        return (
            <Segment loading={isLoadingProduct} disabled={!!productError}>
                {!isLoadingProduct ? (
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={(props) => (<ProductComponentEditorForm {...props} onCancel={() => history.goBack()} />)}
                    />
                ) : (<div className="my-20"></div>)}

                {errors.length > 0 && (
                    <Message
                        error
                        content={errors}
                        icon="warning sign"
                        header="Error saving product!" 
                    />
                )}
            </Segment>
        );
    };
}

const mapStateToProps = ({ productEditor, }) => ({
    errors: productEditor.errors,
    inputs: productEditor.inputs,
    product: productEditor.product,
    isSaving: productEditor.isSaving,
    productError: productEditor.productError,
    isLoadingProduct: productEditor.isLoadingProduct,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductEditorInput, saveProduct, loadProductForEdit, setProductEditorError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductContainerEditor));

