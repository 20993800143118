import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'formik-semantic-ui';
import { bindActionCreators } from 'redux';
import { Segment, Header, Message } from 'semantic-ui-react';

import 'brace/mode/mysql';
import 'brace/theme/twilight';
import "brace/ext/language_tools";

import CheckComponentForm from '../components/form';
import { validateCheckCreate } from '../services/validators';
import { setCheckEditorData, saveCheckEditorData } from '../actions';

class CheckContainerEdit extends React.Component {
    handleSubmit = (checkData, formikApi) => {
        validateCheckCreate(checkData).then((check) => {
            this.props.saveCheckEditorData(check, formikApi.setSubmitting);
        }, (err) => {
            err.details.forEach(errDet => {
                formikApi.setFieldError(errDet.context.key, errDet.message);
            });

            formikApi.setSubmitting(false);
            return null;
        });
    };

    render() {
        const { saveError, editorCheck } = this.props;

        let headerContent = '';
        if (editorCheck.check_id) {
            headerContent = `Edit #${editorCheck.check_id} ${editorCheck.description}`;
        } else {
            headerContent = 'Create New Check';
        }

        return (
            <React.Fragment>
                <Header attached="top" content={headerContent} />
                <Segment attached>
                    <Form
                        initialValues={editorCheck}
                        onSubmit={this.handleSubmit}
                        render={CheckComponentForm}
                    />

                    {!!saveError && (
                        <Message
                            error
                            icon="warning sign"
                            content={saveError}
                            header="Something went wrong 😔"
                        />
                    )}
                </Segment>
            </React.Fragment>
        );
    };
};

const mapStateToProps = ({ checkEditor }) => ({
    saveError: checkEditor.error,
    editorCheck: checkEditor.check,
    savingCheck: checkEditor.isSaving,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCheckEditorData, saveCheckEditorData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckContainerEdit);