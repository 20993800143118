import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Confirm, Modal, Table } from 'semantic-ui-react';

import {
    requestSaveClient, saveClient,
} from '../actions';

const UserContainerCreateConfirm = ({ 
    isConfirming, isSaving, inputs, company,
    saveClient, requestSaveClient, onClose,
}) => (
    <Confirm
        size="tiny"
        header="Save user"
        open={isConfirming}
        cancelButton="Nevermind"
        onConfirm={() => saveClient(inputs, onClose)}
        confirmButton={{ content: "Save", loading: isSaving }}
        onCancel={() => {requestSaveClient(false); onClose(null)}}
        content={(
            <Modal.Content>
                Please verify that you want to <b>{inputs.id ? 'Update' : 'Create'}</b> a new client with the following data
                <br />

                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Company
                            </Table.Cell>
                            <Table.Cell>{company && company.company}</Table.Cell>
                        </Table.Row>
                        {['name', 'email', 'password', 'service_premises', 'service_product_type', 'service_status', 'service_level'].map(field=> (
                            <Table.Row key={field}>
                                <Table.Cell>
                                    { field.split('_').join(' ') }
                                </Table.Cell>
                                <Table.Cell>{inputs[field]}</Table.Cell>
                            </Table.Row>
                        ))}

                        {inputs.force_change_password &&
                            <Table.Row>
                                <Table.Cell>
                                    Force Password Change
                                </Table.Cell>
                                <Table.Cell>{inputs.force_change_password > 0 ? 'Yes' : 'Nope'}</Table.Cell>
                            </Table.Row>
                        }

                        <Table.Row>
                            <Table.Cell>
                                Power User
                            </Table.Cell>
                            <Table.Cell>{inputs.power_user > 0 ? 'Yes' : 'Nope'}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>    
        )}
    />
);

const mapStateToProps = ({ clientEditor, companyList }) => ({
    inputs: clientEditor.inputs,
    isSaving: clientEditor.isSaving,
    isConfirming: clientEditor.isConfirming,

    company: companyList.selectedOption,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    requestSaveClient, saveClient,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserContainerCreateConfirm);