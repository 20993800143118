import { get } from 'lodash';
import { toast } from 'react-semantic-toasts';

import { 
    PRODUCT_GRID_DATA, 
    PRODUCT_GRID_LOADING, 
    PRODUCT_GRID_ERROR, 
    PRODUCT_GRID_COLUMN_SAVING, 
    PRODUCT_GRID_COLUMN_SAVED, 
    PRODUCT_GRID_COLUMN_ERROR,
    PRODUCT_GRID_COLUMN_TOGGLE, 
    PRODUCT_GRID_PAGE_CHANGE, 
    PRODUCT_GRID_STATE_CHANGE, 
    PRODUCT_GRID_SELECT_PRODUCT, 
    PRODUCT_GRID_CONTROL_TOGGLE, 
    PRODUCT_GRID_COLUMN_CHANGE_STATE
} from '../constants';
import { authExpire } from '../../auth/actions';
import { loadUpdatesForProduct } from './update';
import { loadUpcsForProduct } from '../upc/actions';
import { makePrivateApiCall } from '../../common/api';
import { loadNormalsForProduct } from '../normal/actions';
import { selectProductMatchFromGrid } from '../match/actions';
import { setProductQueenifierQueen } from '../queenifier/actions';

export const loadProductGridPage = (state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;
    
    dispatch({
        type: PRODUCT_GRID_LOADING
    });

    const params = {
        endRow: grid.endRow,
        filterModel, sortModel,
        startRow: grid.startRow,
    };

    return makePrivateApiCall({
        url: '/product/grid',
        method: 'get', params,
    }).then(res => {
        if (res.data) {
            const products = res.data.rows;
            lastRow = res.data.count;

            grid.successCallback(products, lastRow);

            dispatch({
                type: PRODUCT_GRID_DATA,
                payload: { products, lastRow }
            });
        }
    }).catch(err => {
        grid.failCallback(err);

        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_GRID_ERROR,
            payload: err.message
        });
    });
};

export const selectProductFromGrid = (product) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_GRID_SELECT_PRODUCT,
        payload: product
    });

    if (product) {
        dispatch(setProductQueenifierQueen(product.product_id));

        if (getState().productMatchGrid.selected) {
            dispatch(loadUpdatesForProduct(product.product_id));
            dispatch(loadNormalsForProduct(product.product_id));
            dispatch(loadUpcsForProduct(product.product_id));
        }
    } else {
        dispatch(selectProductMatchFromGrid(null));
    }

    return;
};

export const changeProductGridPage = (page) => {
    return {
        type: PRODUCT_GRID_PAGE_CHANGE,
        payload: page
    };
};

export const changeProductGridState = (gridState) => dispatch => dispatch({
    type: PRODUCT_GRID_STATE_CHANGE,
    payload: gridState
});

export const hideProductGridColumn = (columns) => dispatch => dispatch({
    type: PRODUCT_GRID_COLUMN_TOGGLE,
    payload: columns
});

export const toggleProductGridControl = () => dispatch => dispatch({
    type: PRODUCT_GRID_CONTROL_TOGGLE
});

export const changeProductColumnState = (columnState) => dispatch => dispatch({
    type: PRODUCT_GRID_COLUMN_CHANGE_STATE,
    payload: columnState,
});

export const saveProductGridColumn = (productId, product = {}) => dispatch => {
    dispatch({
        type: PRODUCT_GRID_COLUMN_SAVING,
        payload: true,
    });

    makePrivateApiCall({
        url: `/product/${productId}`,
        method: 'put',
        data: product,
    }).then(res => {
        if (res.data) {
            dispatch({
                type: PRODUCT_GRID_COLUMN_SAVED,
                payload: res.data
            });

            const toastParams = {
                type: 'success',
                icon: 'checkmark',
                title: 'Upated Product',
                description: 'Product column has been updated',
            };

            if (!res.data.updated) {
                toastParams.icon = 'info';
                toastParams.type = 'info';
                toastParams.title = 'Update not needed';
                toastParams.description = 'Nothing was changed in the database';
            } else {
                dispatch(loadUpdatesForProduct(productId));
            }

            toast(toastParams);
        }
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            return dispatch(authExpire());
        }

        dispatch({
            type: PRODUCT_GRID_COLUMN_ERROR,
            payload: err.message
        });

        toast({
            type: 'error',
            icon: 'close',
            title: 'Update Error',
            description: get(err, 'response.data.message', null) || err.message,
        });
    });
};