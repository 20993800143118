import { get } from 'lodash';

import {
    COMPANY_EDITOR_INPUT, COMPANY_EDITOR_ERROR,
    COMPANY_EDITOR_SAVING, COMPANY_EDITOR_CONFIRMING, COMPANY_EDITOR_SAVED,
    COMPANY_EDITOR_COMPANY_LOADING, COMPANY_EDITOR_COMPANY_DATA, COMPANY_EDITOR_COMPANY_ERROR, COMPANY_EDITOR_COMPANY_RESET,
} from '../constants';
import { makePrivateApiCall } from '../../common/api';
// import { selectCompanyFromGrid } from './company-grid';
import { COMPANY_LIST_SELECT_OPTION } from '../../company/constants';

export const requestSaveCompany = (company={}) => dispatch => {
    return dispatch({
        type: COMPANY_EDITOR_CONFIRMING,
        payload: company,
    });
};

export const saveCompany = (company={}, onComplete) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: COMPANY_EDITOR_SAVING,
        payload: true,
    });

    const request = {
        url: `/company`, params: {env: moduleEnv.company},
        method: 'post', data: company,
    };

    if (company.id) {
        request.url = `/company/${company.id}`;
        request.method = 'put';
    }

    makePrivateApiCall(request).then((res) => {
        if (res.data) {
            dispatch({ type: COMPANY_EDITOR_SAVED, payload: res.data });

            onComplete(res.data);
        }
    }).catch(err => {
        onComplete(null);
        dispatch(setCompanyEditorConfirming());

        const message = get(err, 'response.data.message', null) || err.message;
        dispatch(setCompanyEditorError([message]));
    });
};

export const setCompanyEditorInput = (inputs={}) => dispatch => {
    return dispatch({
        type: COMPANY_EDITOR_INPUT,
        payload: inputs
    });
};

export const setCompanyEditorConfirming = (confirming=false) => dispatch => {
    return dispatch({
        type: COMPANY_EDITOR_CONFIRMING,
        payload: !!confirming
    });
};

export const setCompanyEditorError = (error) => dispatch => {
    return dispatch({
        type: COMPANY_EDITOR_ERROR,
        payload: error
    });
};

export const loadCompanyForEdit = (companyId) => (dispatch, getState) => {
    const { moduleEnv } = getState();

    dispatch({
        type: COMPANY_EDITOR_COMPANY_LOADING,
        payload: true,
    });

    makePrivateApiCall({
        method: 'get', params: { env: moduleEnv.company },
        url: `/company/${companyId}`,
    }).then((res) => {
        if (res.data) {
            dispatch({ 
                type: COMPANY_EDITOR_COMPANY_DATA,
                payload: res.data,
            });
            dispatch({
                type: COMPANY_LIST_SELECT_OPTION,
                payload: res.data.company_id,
            });
        }
    }).catch(err => {
        dispatch({
            type: COMPANY_EDITOR_COMPANY_ERROR,
            payload: err.message,
        });
    });
};

export const resetEditingCompany = () => dispatch => dispatch({
    type: COMPANY_EDITOR_COMPANY_RESET
});