import { format } from 'date-fns';

import { AUTH_SIGNIN_SUCCESS } from "../auth/constants";
import { 
    PRODUCT_GRID_SELECT_PRODUCT,
    PRODUCT_UNTANGLER_COMPLETE,
    PRODUCT_QUEENIFIER_QUEUED, 
    PRODUCT_EDITOR_SAVED,
    PRODUCT_MATCH_GRID_COLUMN_SAVED,
    PRODUCT_GRID_COLUMN_SAVED,

    PRODUCT_UPC_VARIATIONS_DELETED,
    PRODUCT_UPC_DELETED,
    PRODUCT_UPC_CREATED,
} from "../product/constants";
import { 
    COMPANY_EDITOR_SAVED,
    RENEWAL_GRID_COLUMN_SAVED,
} from '../company/constants';

export const transformActivity = ({ payload, timestamp, type }) => {
    let data = { 
        date: format(timestamp, 'DD MMM [at] HH:mm'), 
        key: timestamp.toString() 
    };
    
    switch(type) {
        case PRODUCT_GRID_SELECT_PRODUCT:
            data.icon = 'glass martini';
            if (!payload)
                data.summary = `Cleared Product Selection`;
            else
                data.summary = `Selected Product ${payload.product_id}`;
            break;

        case AUTH_SIGNIN_SUCCESS:
            data.icon = 'lock';
            data.summary = 'Logged In';
            break;

        case PRODUCT_EDITOR_SAVED:
            data.icon = 'glass martini';
            data.summary = `Edited Product ${payload.product_id} From Editor`;
            break;

        case PRODUCT_GRID_COLUMN_SAVED:
            data.icon = 'glass martini';
            data.summary = `Edited Product ${payload.product.product_id} From Grid`;
            break;

        case PRODUCT_UNTANGLER_COMPLETE:
            data.icon = 'random';
            data.summary = `Untangled Product ${payload.data.tangled_product_id} to ${payload.data.true_product_id}`;
            break;

        case PRODUCT_QUEENIFIER_QUEUED:
            data.icon = 'chess queen';
            data.summary = `Product queenified ${payload.data.queen} -> ${payload.data.pawn}`;
            break;

        case PRODUCT_MATCH_GRID_COLUMN_SAVED:
            data.icon = 'glass martini';
            data.summary = `Product match updated ${payload.match.vol}`;
            break;

        case PRODUCT_UPC_CREATED:
            data.icon = 'barcode';
            data.summary = `Added UPC ${payload.upc} to product ${payload.product_id}`;
            break;

        case PRODUCT_UPC_DELETED:
            data.icon = 'barcode';
            data.summary = `Deleted Product UPC ${payload}`;
            break;

        case PRODUCT_UPC_VARIATIONS_DELETED:
            data.icon = 'barcode';
            data.summary = `Deleted UPC variations ${payload.join(",")}`;
            break;


        case COMPANY_EDITOR_SAVED:
        case RENEWAL_GRID_COLUMN_SAVED:
            data.icon = 'briefcase';
            data.summary = `Saved company ${payload.company.company}`;
            break;

        default:
            break;
    };

    return data;
};