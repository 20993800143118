import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';
import { debounce, isEqual, get } from 'lodash';

import { 
    loadClientGridPage, 
    hideClientGridColumn, 
    selectClientFromGrid, 
    changeClientGridPage,
    saveClientGridColumn,
    changeClientGridState,
    changeClientGridColumnState,
} from './actions';
import { selectSearchFromGrid } from '../search/actions';

import UserContainerClientGridControl from './control';
import AgGridDatePicker from '../../common/ag-grid/date-picker';
import AgGridPremisePicker from '../../common/ag-grid/premise-picker';
import AgGridServiceTypePicker from '../../common/ag-grid/service-type-picker';
import CompanyComponentServicesGridCell from '../../company/components/services-grid-cell';
import CommonComponentCellWithCopyButton from '../../common/components/cell-with-copy-button';

class ClientGrid extends React.Component {
    grid = null;
    gridDataSource = {
        getRows: (params) => {
            this.props.loadClientGridPage(this.props, params);
        },
    };

    componentWillMount() {
        // load the correct page on first load in case the grid was not on first page when user left the page
        const { currentPage, pageSize } = this.props;
        setTimeout(() => {
            this.grid && this.grid.api.ensureIndexVisible((currentPage - 1) * pageSize);
        }, 900);
    };
    
    componentWillUnmount() {
        this.grid = null;
    };

    saveColumnState = debounce(() => {
        this.props.changeClientGridColumnState(this.grid.columnApi.getColumnState());
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (!this.props.columnState) {
            this.grid.api.sizeColumnsToFit();
        } else {
            this.grid.columnApi.setColumnState(this.props.columnState);
        }

        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener('sortChanged', () => {
            this.props.changeClientGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeClientGridState({ filterModel: this.grid.api.getFilterModel() });
        });

        this.grid.api.addEventListener('columnResized', this.saveColumnState);
        this.grid.api.addEventListener('columnMoved', this.saveColumnState);

        this.grid.api.addEventListener('cellValueChanged', (e) => {
            if (e.newValue !== e.oldValue)
                this.props.saveClientGridColumn(e.data.id, {
                    [e.column.colId]: e.newValue
                }).then(() => {
                    this.grid.api.redrawRows();
                });
        });

        this.grid.api.addEventListener('cellDoubleClicked', (e) => {
            const col = get(e, 'colDef.field', null);

            if (['company'].indexOf(col) >= 0) {
                this.props.push(`/user/${e.data.id}/edit`);
            }
        });
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage)
                return;

            this.props.changeClientGridPage(page);
        }
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
    };

    selectClient = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectClientFromGrid(selectedRows[0]);
        } else {
            this.props.selectClientFromGrid(null);
        }
        // selected client should unselect currently selected search from the grid
        this.props.selectSearchFromGrid(null);
    };

    componentDidUpdate (prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.grid.api.sizeColumnsToFit();

            const { selected } = this.props;
            if (selected) {
                this.grid.api.forEachNode(row => {
                    const shouldBeSelectedRow = (row.data && row.data.id === selected.id);
                    if (shouldBeSelectedRow && !row.selected) {
                        row.setSelected(true, true);
                    }
                });
            }
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid && this.grid.api.showLoadingOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid && this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        const { searchedWithinFilter } = this.props;
        const rangeChanged = prevProps.searchedWithinFilter !== searchedWithinFilter,
            rangeApplied = (searchedWithinFilter.length > 20 || !searchedWithinFilter);
        // the date filter is set in the format of MM-DD-YYYY - MM-DD-YYYY so if the length is longer than 12 chars, we know both start and end date has been set
        if (rangeChanged && rangeApplied) {
            this.grid && this.grid.api.refreshInfiniteCache();
        }
    };

    render () {
        return (
            <div>
                <UserContainerClientGridControl />

                <div className="ag-theme-balham">
                    <AgGridReact
                        pagination
                        floatingFilter={true}
                        rowSelection="single"
                        rowModelType="infinite"
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        defaultColDef={{ resizable: true }}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        onSelectionChanged={this.selectClient}
                        frameworkComponents={{
                            agGridDatePicker: AgGridDatePicker,
                            agGridPremisePicker: AgGridPremisePicker,
                            agGridServiceTypePicker: AgGridServiceTypePicker,
                            cellWithCopyButton: CommonComponentCellWithCopyButton,
                            companyServicesGridCell: CompanyComponentServicesGridCell,
                        }}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ clientGrid }) => ({
    clients: clientGrid.clients,
    lastRow: clientGrid.lastRow,
    pageSize: clientGrid.pageSize,
    selected: clientGrid.selected,
    isLoading: clientGrid.isLoading,
    sortModel: clientGrid.sortModel,
    columnDefs: clientGrid.columnDefs,
    filterModel: clientGrid.filterModel,
    currentPage: clientGrid.currentPage,
    shownColumns: clientGrid.shownColumns,
    hiddenColumns: clientGrid.hiddenColumns,
    searchedWithinFilter: clientGrid.searchedWithinFilter,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    push,
    hideClientGridColumn, 
    loadClientGridPage, 
    changeClientGridState,
    saveClientGridColumn,
    changeClientGridColumnState,
    changeClientGridPage: (page) => dispatch(changeClientGridPage(page)),
    selectClientFromGrid: (client) => dispatch(selectClientFromGrid(client)),
    selectSearchFromGrid: (search) => dispatch(selectSearchFromGrid(search)),
}, dispatch);

export default connect(
    mapStateToProps, 
    mapDispatchToProps, 
    null, 
    // this allows the grid api to be called from it's parent containers
    {withRef: true}
)(ClientGrid);