import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Menu, Message, Segment } from 'semantic-ui-react';

import TopNav from '../../common/containers/top-nav';
import CheckContainerEdit from '../containers/editor';
import { setCheckEditorData, loadCheckForEditor } from '../actions';

class CheckEditPage extends React.Component {
    componentDidMount () {
        const { editorCheck, computedMatch } = this.props;
        
        // if editorCheck data is already there and matches the check user is editing just abort here
        if (parseInt(computedMatch.params.checkId) === editorCheck.check_id) 
            return;

        this.props.loadCheckForEditor(computedMatch.params.checkId);
    };

    componentWillUnmount () {
        this.props.setCheckEditorData(null);
    };

    render () {
        const { history, computedMatch, editorCheck, isSaving, loadingCheck } = this.props;

        return (
            <Grid>
                <TopNav
                    headerContent='Update Check'
                    subheader={`Update existing check`}
                    headerIcon={ isSaving ? 'spinner' : 'check circle outline' }
                    extraButtons={() => (
                        <React.Fragment>
                            <Menu.Item
                                icon="delete"
                                name="Cancel"
                                onClick={() => history.push('/check')}
                            />
                            <Menu.Item
                                name="Reset"
                                icon="sync alternate"
                                onClick={() => history.push('/check')}
                            />
                        </React.Fragment>
                    )}
                />

                <Grid.Row>
                    <Grid.Column mobile={16} >
                        { loadingCheck ? (
                            <Segment 
                                loading 
                                style={{ minHeight: 200 }}
                            />
                        ) : (editorCheck && editorCheck.check_id) ? <CheckContainerEdit /> : (
                            <Message
                                error
                                icon="bug"
                                header="Tear in the space time continuum?"
                                content={`Check #${computedMatch.params.checkId} does not exist. Please make sure that the check you're trying to edit is not in an imaginary dimension.`}
                            />
                        ) }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };
};

const mapStateToProps = ({ checkEditor }) => ({
    editorCheck: checkEditor.check,
    isSaving: checkEditor.isSaving,
    loadingCheck: checkEditor.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCheckEditorData, loadCheckForEditor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckEditPage);