import {
    REPORT_STATS_DATE_RANGE,
    REPORT_STATS_LOADING,
    REPORT_STATS_ERROR,
    REPORT_STATS_DATA,
} from "../constants";
import {
    makePrivateApiCall
} from "../../common/api";

export const loadReportStats = () => (dispatch, getState) => {
    const {
        moduleEnv, reportStats,
    } = getState();

    dispatch({
        type: REPORT_STATS_LOADING
    });

    const params = {
        env: moduleEnv.report,
    };

    const dateFragments = reportStats.dateRange.split(' - ');
    if (dateFragments.length > 1 && dateFragments[1].length > 1) {
        params.from = dateFragments[0];
        params.to = dateFragments[1];
    }

    return makePrivateApiCall({
            url: "/report/stats",
            method: "get",
            params,
        })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: REPORT_STATS_DATA,
                    payload: res.data
                });
            }
        })
        .catch(err => {
            dispatch({
                type: REPORT_STATS_ERROR,
                payload: err.message
            });
        });
};

export const changeReportStatsDateRange = (dateRange) => (dispatch) => {
    dispatch({
        type: REPORT_STATS_DATE_RANGE,
        payload: dateRange,
    });

    const dateFragments = dateRange.split(' - ');
    if (dateFragments.length > 1 && dateFragments[1].length > 1) {
        return dispatch(loadReportStats());
    }
};