import React from "react";
import pluralize from 'pluralize';
import { Segment, Header, Button, Label, List, Grid } from "semantic-ui-react";

const ReportComponentSingle = ({
    report,
    showDetails,
    onHideReportDetails,
    onShowReportDetails
}) => (
    <React.Fragment>
        <Segment clearing attached="top">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <Header size="small">
                            {report.type.type_name}
                            {report.distributor && (
                                <span>
                                    {" "}
                                    | <b>{report.distributor}</b>
                                </span>
                            )}
                            {report.supplier && (
                                <span>
                                    {" "}
                                    | <b>{report.supplier}</b>
                                </span>
                            )}
                            {report.description && (
                                <Header.Subheader>
                                    <span className="text-bold">
                                        {report.description}
                                    </span>
                                </Header.Subheader>
                            )}
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Header textAlign="right">
                            <Label.Group size="tiny">
                                <Label
                                    basic
                                    icon="calendar"
                                    content={report.frequency}
                                />
                                <Label
                                    basic
                                    icon="users"
                                    content={`${
                                        report.subscribers.length
                                    } ${pluralize(
                                        "Sub",
                                        report.subscribers.length
                                    )}`}
                                />
                            </Label.Group>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                {showDetails && (
                    <Grid.Row divided>
                        {report.subscribers.length > 0 && (
                            <Grid.Column width={8}>
                                <Header
                                    size="small"
                                    icon="users"
                                    content="Users"
                                />
                                <List
                                    items={report.subscribers.map(s => ({
                                        key: s.id,
                                        content: s.email,
                                        header: `#${s.id} ${s.name}`
                                    }))}
                                />
                            </Grid.Column>
                        )}
                        {report.settings.length > 0 && (
                            <Grid.Column width={8}>
                                <Header
                                    icon="cog"
                                    size="small"
                                    content="Settings"
                                />
                                <List
                                    items={report.settings.map(
                                        s => `${s.name}: ${s.value}`
                                    )}
                                />
                            </Grid.Column>
                        )}
                    </Grid.Row>
                )}
            </Grid>
        </Segment>

        <Button.Group attached="bottom">
            <Button
                icon="eye"
                content={showDetails ? "Hide Details" : "Show Details"}
                onClick={() =>
                    showDetails
                        ? onHideReportDetails(report)
                        : onShowReportDetails(report)
                }
            />
        </Button.Group>
    </React.Fragment>
);

export default ReportComponentSingle;
