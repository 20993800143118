import React from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';

import { loadCompanyUserGridData } from './user-grid-actions';
import { selectClientFromGrid } from '../../user/client-grid/actions';

import UserGridControl from './user-grid-control';
import CommonContainerColoredNumber from '../../common/components/colored-number';
import CommonComponentCellWithCopyButton from "../../common/components/cell-with-copy-button";

class CompanyUserGrid extends React.Component {
    grid = null;

    componentDidMount() {
        this.props.loadCompanyUserGridData({id: this.props.selectedCompany.id});
    };

    componentWillMount() {
        window.addEventListener('resize', this.resizeGrid);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGrid);
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid && this.grid.api.hideOverlay();
            this.grid.api.sizeColumnsToFit();
        }
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();
        this.grid.api.showLoadingOverlay();
    };

    selectUser = () => {
        const selectedRows = this.grid.api.getSelectedRows();

        if (selectedRows.length > 0) {
            this.props.selectClient(selectedRows[0]);
        } else {
            this.props.selectClient(null);
        }
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render () {
        const { users, selectedCompany } = this.props;

        return (
            <div>
                <UserGridControl
                    onDownload={() => this.grid.api.exportDataAsCsv({
                        fileName: `${selectedCompany.company.replace(/ /ig, '_')}_user_usage_${Date.now()}`
                    })} 
                />
                <div
                    className="ag-theme-balham"
                    style={{
                        height: '500px',
                    }}
                >
                    <AgGridReact
                        animateRows
                        rowData={users}
                        floatingFilter={true}
                        rowSelection="single"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        defaultColDef={{ resizable: true }}
                        onSelectionChanged={this.selectUser}
                        frameworkComponents={{
                            coloredNumberRenderer: CommonContainerColoredNumber,
                            cellWithCopyButton: CommonComponentCellWithCopyButton,
                        }}
                    />
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ companyUserGrid, companyUsageGrid }) => ({
    error: companyUserGrid.error,
    users: companyUserGrid.users,
    isLoading: companyUserGrid.isLoading,
    columnDefs: companyUserGrid.columnDefs,
    shownColumns: companyUserGrid.shownColumns,
    hiddenColumns: companyUserGrid.hiddenColumns,

    selectedCompany: companyUsageGrid.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadCompanyUserGridData,
    selectClient: (client) => dispatch(selectClientFromGrid(client))
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserGrid);