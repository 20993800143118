import { keyBy } from 'lodash';

import {
    CLIENT_SETTING_LOADING, CLIENT_SETTING_DATA, CLIENT_SETTING_ERROR, CLIENT_SETTING_UPDATED,
} from "../constants";

const initialState = {
    error: null,
    settings: [],
    isLoading: false,
    settingsByName: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_SETTING_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                settings: action.payload.settings.rows,
                settingsByName: keyBy(action.payload.settings.rows, 'name'),
            };

        case CLIENT_SETTING_UPDATED:
            let newSetting;

            const settings = state.settings.map((setting) => {
                if (setting.id === action.payload.id) {
                    setting.value = action.payload.value;
                    newSetting = setting;
                }

                return setting;
            });

            return {
                ...state,
                settings,
                error: null,
                isLoading: false,
                settingsByName: {...state.settingsByName, ...newSetting},
            };

        case CLIENT_SETTING_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case CLIENT_SETTING_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
};