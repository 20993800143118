import {
    PRODUCT_UNTANGLER_CONFIRMING, PRODUCT_UNTANGLER_UNTANGLING,
    PRODUCT_UNTANGLER_SET_INPUT, PRODUCT_UNTANGLER_COMPLETE,
    PRODUCT_UNTANGLER_ERROR,
} from '../constants';

export const initialState = {
    inputs: {
        productId: '',
        tangledUpc: '',
        productSize: '',
        matchingPhrase: '',
        tangledProductId: '',
    }, 
    error: null,
    isUntangling: false, 
    isConfirming: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_UNTANGLER_SET_INPUT:
            return { ...state, inputs: {...state.inputs, ...action.payload} };

        case PRODUCT_UNTANGLER_UNTANGLING:
            return { ...state, isUntangling: !!action.payload };

        case PRODUCT_UNTANGLER_CONFIRMING:
            return { ...state, isConfirming: !!action.payload };

        case PRODUCT_UNTANGLER_COMPLETE:
            return initialState;

        case PRODUCT_UNTANGLER_ERROR:
            return { ...state, isUntangling: false, isConfirming: false, error: action.payload };

        default:
            return state;
    }
};